import { Component, OnInit, Input, Inject, Renderer2 } from "@angular/core";
import { ProductService } from "../../services/product.service";
import { CommonService } from "../../services/common.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
  @Input() title: string;
  @Input() searchBreadcrumb: string = "";
  @Input() breadcrumb: string;
  @Input() formShapesMode: boolean;
  @Input() query: string;
  @Input() border: boolean = true;
  @Input() currentUrl: string = "sameh";
  hostName: string = "";
  FormShapesLabels: any;
  breadCrumbSchema: any;

  constructor(
    private productService: ProductService,
    private commonService: CommonService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}
  ngOnInit(): void {
    if (this.formShapesMode) {
      this.productService
        .getBreadcrumbFormShape(this.query)
        .subscribe((data) => {
          this.FormShapesLabels = data.data;
          console.log("FormShapesLabels", this.FormShapesLabels);
        });
    }
  }

  ngOnChanges(): void {
    this.generateBreadcrumbSchema();
  }

  generateBreadcrumbSchema() {
    this.hostName = this.commonService.getDomainFromUrl();

    console.log("title", this.title);
    const breadcrumbList = [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: `https://${this.hostName}/`,
      },
    ];

    if (this.title === "الدفع" || this.title === "checkout") {
      breadcrumbList.push({
        "@type": "ListItem",
        position: 2,
        name: "السلة",
        item: `https://${this.hostName}/shop/cart`,
      });
    }

    breadcrumbList.push({
      "@type": "ListItem",
      position: breadcrumbList.length + 1,
      name: this.title,
      item: this.currentUrl,
    });

    this.breadCrumbSchema = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: breadcrumbList,
    };

    console.log("schema", this.breadCrumbSchema);
    this.insertSchema();
  }

  insertSchema() {
    const oldSchema = this.document.querySelector(
      'script[type="application/ld+json"]'
    );
    if (oldSchema) {
      this.renderer.removeChild(this.document.head, oldSchema);
    }
    const schemaScript = this.renderer.createElement("script");
    schemaScript.type = "application/ld+json";
    schemaScript.text = JSON.stringify(this.breadCrumbSchema);
    this.renderer.appendChild(this.document.head, schemaScript);
  }
}
