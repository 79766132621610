import { Inject, Injectable, Injector, PLATFORM_ID } from "@angular/core";
import { HttpInterceptor } from "@angular/common/http";
import { UserService } from "./app/shared/services/user.service";
import { MasterApiService } from "./app/shared/services/master-api.service";
import { SsrCookieService } from "ngx-cookie-service-ssr";
import { isPlatformServer } from "@angular/common";
import * as bcrypt from "bcryptjs";
@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private injector: Injector,
    public masterApiService: MasterApiService,
    private cookies: SsrCookieService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  intercept(req: any, next: any) {
    var authService = this.injector.get(UserService);
    let lang = "";
    if (isPlatformServer(this.platformId)) {
      lang = this.cookies.get("front-lang")
        ? this.cookies.get("front-lang")
        : "ar";
    } else {
      lang = this.cookies.get("front-lang")
        ? this.cookies.get("front-lang")
        : "ar";
    }
    // const secretKey = 'it-Plus-Secrit-Key-API-matjer';
    const secretKey = 'it-Plus-Secrit-Key-API';
    const passwordHashed = bcrypt.hashSync(secretKey);
    if (authService.getUserToken()) {
      var tokenizedReq = req.clone({
        setHeaders: {
          "front-lang": lang,
          "user-token": authService.getUserToken(),
          "affiliator-token": this.masterApiService.masterData.shop_token,
          "dealer-token":
            this.masterApiService.masterData.dealer_token + "-" + Date.now(),
          // "Device-Id": authService.getDeviceId()
          "Device-Id": localStorage.getItem("Device-Id")
            ? localStorage.getItem("Device-Id")
            : "",
          "App-Version": this.masterApiService.AppVersion,
          'Secret-Api' : passwordHashed
        },
      });
    } else {
      var tokenizedReq = req.clone({
        setHeaders: {
          "front-lang": lang,
          // "affiliator-token": environment.affiliator_token,
          "dealer-token":
            this.masterApiService.masterData.dealer_token + "-" + Date.now(),
          "affiliator-token": this.masterApiService.masterData.shop_token,
          // "Device-Id": authService.getDeviceId()
          "Device-Id": localStorage.getItem("Device-Id")
            ? localStorage.getItem("Device-Id")
            : "",
          "App-Version": this.masterApiService.AppVersion,
          'Secret-Api' : passwordHashed

        },
      });
    }

    return next.handle(tokenizedReq);
  }
}
