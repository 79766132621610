import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatRadioButton } from "@angular/material/radio";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "src/app/shared/services/common.service";
import { CouponesService } from "src/app/shared/services/coupones.service";
import { MasterApiService } from "src/app/shared/services/master-api.service";
import { ProductService } from "src/app/shared/services/product.service";
import { ActivatedRoute, Router } from "@angular/router";
import { OrderService } from "src/app/shared/services/order.service";
import Swal from "sweetalert2";
import { isPlatformBrowser } from "@angular/common";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FatoorahPaymentService } from "src/app/shared/services/fatoorah-payment.service";
import { HttpErrorResponse } from "@angular/common/http";
import { UserService } from "src/app/shared/services/user.service";
import { interval, Observable, Subscription } from "rxjs";
import { distinctUntilChanged, tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { ExtractedData } from "src/assets/interfaces/category-support";

@Component({
  selector: "app-direct-request",
  templateUrl: "./direct-request.component.html",
  styleUrls: ["./direct-request.component.scss"],
})
export class DirectRequestComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @ViewChildren("radioContainer") radioContainer: QueryList<ElementRef<any>>;
  @ViewChildren("PaymentContainer") paymentContainer: QueryList<
    ElementRef<any>
  >;
  @ViewChildren("btnpayment") btnPayments: QueryList<MatRadioButton>;
  @ViewChildren("btnCompany") btnCompanies!: QueryList<MatRadioButton>;

  @ViewChild("fullnameInput") fullnameInput!: ElementRef;
  @ViewChild("paymentlet", { static: false }) paymentModal: TemplateRef<any>;
  @ViewChild("addDirectRequest", { static: false })
  tempDirectRequest: TemplateRef<any>;
  @ViewChild("successOrder") successOrder: any;

  config: any;
  directRequests = [];
  @Input() product!: any;
  @Input() product_id: any;
  @Input() store_amount = 0;
  @Input() product_type: any;
  @Input() currency: any = this.productService.Currency; // Default Currency
  public country_id = "";
  country_code: any;
  public city_id = "";
  public district_id = "";
  public cities: any[] = [];
  public countries: any[] = [];
  public country_name: any;
  public districts: any[] = [];
  showCouponsList: boolean = false;
  activeCouponesList: any[] = [];
  couponesAccordionList: any[] = [];
  codeValueCoupon: any;
  isCouponAdded: boolean = false;
  couponId: any;
  shipping_methods: any = [];
  myColor: string = "";
  contactInfoData: any;
  paymentInfo: any[] = [];
  public new_counter: number = 1;
  public form_direct_request: FormGroup;
  urlimage: string = "";
  private subscriptionTimer: Subscription | null = null;
  private timers: {
    [key: number]: { hours: string; minutes: string; seconds: string };
  } = {};

  extractedData: ExtractedData[] = [
    {
      product_items: [] as { image: string; quantity: number; price: string }[],
      date: "",
      invoice_id: "",
      total_price: 0,
      message: "add",
    },
  ];

  paymentData: any = {
    paymentKey: "",
    paymentName: "",
    paymentPrice: 0,
  };
  ShippingData: any = {};
  mobileKeys: string = "";
  mobileLength: string = "";
  total_price: number = 0;
  total_shipping_price: number = 0;
  total_payment_price: number = 0;
  private deleteCouponTimeout: any;
  private showMessageTimeout: any;

  paymentLoading: boolean = true;
  paymentMethods: any[] = [];
  user_info: any = {};
  params: any;
  paymentId: string = "";
  directRequestLoading: boolean = false;
  isArabicLang: boolean;
  direct_labels: any;
  city_name: string = "";
  district_name: string = "";
  isBrowser: boolean;
  requestOrderLoading: boolean = false;
  @Output() directRequestAdded = new EventEmitter<void>();

  constructor(
    private MasterApiService: MasterApiService,
    public Common: CommonService,
    private couponesService: CouponesService,
    private toastrService: ToastrService,
    public productService: ProductService,
    private fb: FormBuilder,
    private actRoute: ActivatedRoute,
    private _OrderService: OrderService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private FatoorahPaymentService: FatoorahPaymentService,
    private router: Router,
    public user: UserService,
    private cdr: ChangeDetectorRef,
    private elRef: ElementRef,
    private translate: TranslateService
  ) {
    this.urlimage = this.Common._ImageUrl + "products/thumbs/";

    this.isBrowser = isPlatformBrowser(this.platformId);
    this.form_direct_request = this.fb.group({});
    this.isArabicLang = localStorage.getItem("front-lang") === "ar";
    this.direct_labels = {
      user_name: this.isArabicLang ? "الاسم" : "Name",
      mobile: this.isArabicLang ? "رقم الهاتف" : "Mobile Number",
      mobile_1: this.isArabicLang
        ? "يمكنك اضافة رقم اضافي هنا (اختياري)"
        : "Additional Mobile Number",
      city_id: this.isArabicLang ? "المحافظة" : "City",
      district_id: this.isArabicLang ? "الحي" : "District",
      address: this.isArabicLang ? "العنوان" : "Address",
      note: this.isArabicLang ? "ملاحظات" : "Notes",
    };

    this.config = {
      displayKey: "description",
      search: true,
      placeholder: this.isArabicLang ? "اختر" : "Select",
      customComparator: () => {},
      limitTo: 0,
      moreText: this.isArabicLang ? "عرض اكثر" : "Show more",
      noResultsFound: this.isArabicLang ? "لا يوجد نتائج" : "No results found",
      searchPlaceholder: " ",
      searchOnKey: "name",
      inputDirection: this.isArabicLang ? "rtl" : "ltr",
    };
  }

  focusFirstInput() {
    if (this.fullnameInput) {
      this.fullnameInput.nativeElement.focus();
    }
  }

  ngOnChanges(): void {
    this.total_price = this.product
      ? parseFloat(this.product.price) * this.new_counter
      : 0;
    this.updateMinDeliveryPrice();
  }

  updateMinDeliveryPrice() {
    if (this.product && this.product.product_group_data) {
      if (this.total_price > this.product.product_group_data.order_price) {
        if (
          this.product.minDeliveryPrice == undefined ||
          this.product.product_group_data.delivery_price <
            this.product.minDeliveryPrice
        ) {
          this.product.minDeliveryPrice = parseFloat(
            this.product.product_group_data.delivery_price
          );
        }
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setInitialCheck();
      this.cdr.detectChanges();
    }, 1200);

    this.actRoute.queryParams.subscribe((params) => {
      this.paymentId = params["paymentId"];
      if (this.paymentId) {
        this.openDirectRequestModal();
        this.callAddDirectRequest().subscribe({
          next: () => {
            this.directRequestLoading = false;
          },
          error: (err) => {
            console.error(err);
            this.directRequestLoading = false;
          },
        });
      }
    });

    this.Common.directRequest$
      .pipe(
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        )
      )
      .subscribe((data) => {
        if (Object.keys(data).length > 0) {
          this.city_id = data.city_id;
          this.form_direct_request.patchValue({
            city_id: data.city_id,
          });
          this.onChangeCity(data.city_id, data.shipment_id);
        }
      });
  }

  setInitialCheck() {
    const paymentContainersArray = this.paymentContainer.toArray();
    const btnPaymentsArray = this.btnPayments.toArray();
    const startPaymentIndex = 0;
    const endPaymentIndex = this.paymentInfo.length;

    const PaymentContainers = paymentContainersArray.slice(
      startPaymentIndex,
      endPaymentIndex
    );

    const BtnPayments = btnPaymentsArray.slice(
      startPaymentIndex,
      endPaymentIndex
    );

    if (PaymentContainers.length > 0 && BtnPayments.length > 0) {
      PaymentContainers[
        startPaymentIndex
      ].nativeElement.style.border = `2px solid ${this.myColor}`;
      BtnPayments[startPaymentIndex].checked = true;
    }
  }

  ngOnInit(): void {
    this.getAllContanctInfo();
    this.getCouponsListAll();

    const pendingCouponDelete = localStorage.getItem("pendingCouponDelete");
    console.log("pendingCouponDelete", pendingCouponDelete);
    if (pendingCouponDelete) {
      this.couponId = pendingCouponDelete;
      this.deleteCoupon(this.product_id, "", false).then(() => {
        localStorage.removeItem("pendingCouponDelete");
        this.couponId = "";
      });
    }

    this.actRoute.params.subscribe((param) => {
      this.params = param;
      if (this.isCouponAdded) {
        this.deleteCoupon(this.product_id, "", false);
        this.clearCouponTimers();
      }

      this.resetComponentState();
    });
  }

  resetComponentState() {
    const cityValue = this.form_direct_request.get("city_id")?.value;
    this.form_direct_request.reset();
    this.form_direct_request.get("city_id")?.setValue(cityValue);
    this.new_counter = 1;
    this.isCouponAdded = false;
    this.showCouponsList = false;
    this.codeValueCoupon = "";
    this.country_name = "";
    this.district_id = "";
    Object.keys(this.form_direct_request.controls).forEach((key) => {
      this.form_direct_request.controls[key].markAsUntouched();
    });
    setTimeout(() => {
      this.resetPayment(0);
    }, 100);
  }

  setupDynamicForm() {
    const mobilePattern = this.generateMobilePattern(
      this.mobileKeys,
      parseInt(this.mobileLength)
    );
    this.directRequests.forEach((request) => {
      if (request.type == "note") {
        this.form_direct_request.addControl(request.type, this.fb.control(""));
      } else if (request.type == "mobile") {
        this.form_direct_request.addControl(
          request.type,
          this.fb.control("", [
            Validators.required,
            Validators.pattern(mobilePattern),
          ])
        );
      } else if (request.type == "mobile_1") {
        this.form_direct_request.addControl(
          request.type,
          this.fb.control("", [Validators.pattern(mobilePattern)])
        );
      } else {
        this.form_direct_request.addControl(
          request.type,
          this.fb.control(null, Validators.required)
        );
      }
    });
    this.cdr.detectChanges(); // Trigger change detection
  }

  generateMobilePattern(mobileKeys: string, mobileLength: number): string {
    const prefixes = mobileKeys.split(",");
    const patterns = prefixes.map((prefix) => {
      if (prefix.startsWith("0")) {
        return `(${prefix}|${prefix.slice(1)})\\d{${
          mobileLength - prefix.length
        }}`;
      }
      return `${prefix}\\d{${mobileLength - prefix.length}}`;
    });
    return `^(${patterns.join("|")})$`;
  }

  @HostListener("window:beforeunload", ["$event"])
  unloadNotification($event: any): void {
    if (this.isCouponAdded) {
      localStorage.setItem("pendingCouponDelete", this.couponId);
      $event.returnValue = true;
    }
  }

  ngOnDestroy(): void {
    this.clearCouponTimers();
    if (this.isCouponAdded) {
      this.deleteCoupon(this.product_id,"", false);
    }
    this.subscriptionTimer?.unsubscribe();
  }
  getAllContanctInfo() {
    this.MasterApiService.getCurrentDataContact().subscribe(
      (dataContact) => {
        if (!dataContact) {
          console.error("Data contact information is undefined or null");
          return;
        }
        this.contactInfoData = dataContact;
        this.country_id = dataContact.country_info.id;
        this.onChangeCountry(this.country_id);
        this.country_code = dataContact.country_info.country_code;
        this.myColor = dataContact.front_color;
        localStorage.setItem(
          "paymentInfo",
          JSON.stringify(this.Common.getAllPaymentMethods(this.contactInfoData))
        );
        this.paymentInfo = this.Common.getAllPaymentMethods(
          this.contactInfoData
        )
          ? this.Common.getAllPaymentMethods(this.contactInfoData)
          : JSON.parse(localStorage.getItem("paymentInfo"));
        console.log("paymentInfo", this.paymentInfo);
        this.paymentData = {
          paymentKey: this.paymentInfo[0].key,
          paymentName: this.paymentInfo[0].name,
          paymentPrice: this.paymentInfo[0].price,
        };
        this.total_payment_price = parseFloat(this.paymentData.paymentPrice);
        this.mobileLength = dataContact.country_info.mobile_length;
        this.mobileKeys = dataContact.country_info.mobile_keys;
      },
      (error) => {
        console.error("Error fetching contact info", error);
      }
    );
  }

  handleDirectRequests() {
    if (this.user.getToken() == null) {
      this.MasterApiService.GetDirectRequest().subscribe((data) => {
        if (data) {
          this.directRequests = data.data;
          this.setupDynamicForm();
          let idOfCity = localStorage.getItem("city_id") || "";
          let idOfShipment = localStorage.getItem("shipment_id") || "";
          this.onChangeCity(idOfCity, parseInt(idOfShipment));
          if (idOfCity) {
            this.city_id = idOfCity;
            this.form_direct_request.patchValue({ city_id: idOfCity });
          }
          let body = localStorage.getItem(`direct_request-${this.product_id}`);
          let parsedBody = JSON.parse(body);
          if (parsedBody) {
            this.new_counter = parsedBody.quantity;
            this.form_direct_request.patchValue(parsedBody.client_info);
            this.city_id = parsedBody.client_info.city_id;
            this.district_id = parsedBody.client_info.district_id;
            this.onChangeCity(this.city_id);
            const shippingIndex = this.shipping_methods.findIndex(
              (method) => method.shipment_id == parsedBody.shipment_id
            );
            setTimeout(() => {
              this.resetShipping(shippingIndex);
              this.resetPayment(0);
            }, 1000);
          }
        }
      });
    }
  }

  clearLocalStorage() {
    const directRequestKey = `direct_request-${this.product_id}`;
    if (localStorage.getItem(directRequestKey)) {
      localStorage.removeItem(directRequestKey);
    }
  }
  onChangeCountry(value) {
    console.log("value", value);

    if (value != "") {
      this.Common.getPlaces(value, this.city_id, "shipping_methods").subscribe(
        (data) => {
          if (data.data) {
            this.cities = data.data.filter((city) => city.pla_name !== null);
            this.handleDirectRequests();
          }
        }
      );

      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].id == value) {
          this.country_name = this.countries[i].pla_name;
        }
      }
    }
  }

  onChangeCity(value: string, id?: number): Promise<void> {
    this.getShippingMethods(value, id);

    return new Promise((resolve, reject) => {
      if (value != "") {
        this.Common.getPlaces(this.country_id, value).subscribe((data) => {
          if (data.data) {
            this.districts = data.data.filter(
              (district) => district.pla_name !== null
            );
            resolve();
          } else {
            reject("No data in countries");
          }
        });

        for (let i = 0; i < this.countries.length; i++) {
          if (this.countries[i].id == value) {
            this.country_name = this.countries[i].pla_name;
          }
        }
      } else {
        resolve();
      }
    });
  }

  getShippingMethods(value: string, id = 0) {
    const foundItem = this.cities.find((item) => item.id === value);
    console.log("foundItem", foundItem, value, this.cities);
    this.ShippingData = foundItem ? foundItem.shipping_methods[0] : {};
    this.total_shipping_price =
      foundItem && this.ShippingData
        ? parseFloat(this.ShippingData.delivery_price)
        : 0;
    this.shipping_methods = foundItem ? foundItem.shipping_methods : [];
    this.shipping_methods.forEach((method) => {
      const isInvalidFrom = this.Common.isInvalidDeliveryTime(
        method.delivery_time_from
      );
      const isInvalidTo = this.Common.isInvalidDeliveryTime(
        method.delivery_time_to
      );

      if (isInvalidFrom && isInvalidTo) {
        this.translate
          .get("DELIVERY_TIME_NOT_SET")
          .subscribe((translatedMessage) => {
            method.message = translatedMessage;
          });
      } else if (!isInvalidFrom && !isInvalidTo) {
        const { start, end } = this.Common.calculateDeliveryDates(method);

        const options: Intl.DateTimeFormatOptions = {
          // year: "numeric",
          month: "long",
          day: "numeric",
        };

        const formattedStartDate = start.toLocaleDateString(
          this.Common.isRtl ? "ar-EG" : "en-US",
          options
        );
        const formattedEndDate = end.toLocaleDateString(
          this.Common.isRtl ? "ar-EG" : "en-US",
          options
        );

        this.translate
          .get("DELIVERY_TIME_MESSAGE", {
            start: formattedStartDate,
            end: formattedEndDate,
          })
          .subscribe((translatedMessage) => {
            method.message = translatedMessage;
          });
      } else if (!isInvalidFrom || !isInvalidTo) {
        const now = new Date();
        let deliveryDate: Date;

        if (!isInvalidFrom) {
          deliveryDate = new Date(
            now.getTime() + method.delivery_time_from * 60 * 60 * 1000
          );
        } else {
          deliveryDate = new Date(
            now.getTime() + method.delivery_time_to * 60 * 60 * 1000
          );
        }
        const hourShippedOrders = method.hour_shipped_orders % 24;
        if (now.getHours() >= hourShippedOrders) {
          deliveryDate.setDate(deliveryDate.getDate() + 1);
        }

        const options: Intl.DateTimeFormatOptions = {
          // year: "numeric",
          month: "long",
          day: "numeric",
        };

        const formattedDate = deliveryDate.toLocaleDateString(
          this.Common.isRtl ? "ar-EG" : "en-US",
          options
        );

        this.translate
          .get("DELIVERY_TIME_SINGLE", { time: formattedDate })
          .subscribe((translatedMessage) => {
            method.message = translatedMessage;
          });
      }
    });
    if (id != 0) {
      const index = this.shipping_methods.findIndex(
        (method) => method.shipment_id == id
      );
      console.log("index", index);
      if (index !== -1) {
        setTimeout(() => {
          this.resetShipping(index);
        }, 100);
      } else {
        setTimeout(() => {
          this.resetShipping();
        }, 100);
      }
    } else {
      setTimeout(() => {
        this.resetShipping();
      }, 100);
    }
  }
  resetShipping(index = 0) {
    const shippingContainersArray = this.radioContainer.toArray();
    const btnCompaniesArray = this.btnCompanies.toArray();
    const startShippingIndex = 0;
    const endShippingIndex = this.shipping_methods.length;

    const CompanyContainers = shippingContainersArray.slice(
      startShippingIndex,
      endShippingIndex
    );
    const BtnCompanies = btnCompaniesArray.slice(
      startShippingIndex,
      endShippingIndex
    );
    if (CompanyContainers.length > 0 && BtnCompanies.length > 0) {
      BtnCompanies.forEach((btnCompany) => (btnCompany.checked = false));
      CompanyContainers.forEach(
        (cartItemCompanyContainer) =>
          (cartItemCompanyContainer.nativeElement.style.border = `1px solid #dddddd`)
      );

      CompanyContainers[
        index
      ].nativeElement.style.border = `2px solid ${this.myColor}`;
      BtnCompanies[index].checked = true;
      this.ShippingData = this.shipping_methods[index];
      this.total_shipping_price = this.ShippingData
        ? parseFloat(this.ShippingData.delivery_price)
        : 0;

      this.product.default_shipping =
        this.ShippingData.default_shipping_method == "1";
      this.setDeliveryDate(this.ShippingData);
    }
  }

  setDeliveryDate(method: any) {
    const isInvalidFrom = this.Common.isInvalidDeliveryTime(
      method.delivery_time_from
    );
    const isInvalidTo = this.Common.isInvalidDeliveryTime(
      method.delivery_time_to
    );

    const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
    };

    if (isInvalidFrom && isInvalidTo) {
      this.extractedData[0].date = "";
    } else if (!isInvalidFrom && !isInvalidTo) {
      const { start, end } = this.Common.calculateDeliveryDates(method);
      const formattedStartDate = start.toLocaleDateString(
        this.Common.isRtl ? "ar-EG-u-nu-latn" : "en-US",
        options
      );
      const formattedEndDate = end.toLocaleDateString(
        this.Common.isRtl ? "ar-EG-u-nu-latn" : "en-US",
        options
      );
      this.extractedData[0].date = `${formattedStartDate} - ${formattedEndDate}`;
    } else {
      this.extractedData[0].date = this.calculateSingleDeliveryDate(
        method,
        isInvalidFrom,
        options
      );
    }
  }

  calculateSingleDeliveryDate(
    method: any,
    isInvalidFrom: boolean,
    options
  ): string {
    const now = new Date();
    let deliveryDate: Date;

    if (!isInvalidFrom) {
      deliveryDate = new Date(
        now.getTime() + method.delivery_time_from * 60 * 60 * 1000
      );
    } else {
      deliveryDate = new Date(
        now.getTime() + method.delivery_time_to * 60 * 60 * 1000
      );
    }
    const hourShippedOrders = method.hour_shipped_orders % 24;

    if (now.getHours() >= hourShippedOrders) {
      deliveryDate.setDate(deliveryDate.getDate() + 1);
    }

    const formatedDate = deliveryDate.toLocaleDateString(
      this.Common.isRtl ? "ar-EG-u-nu-latn" : "en-US",
      options
    );
    return formatedDate;
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  selectComapny(radioContainer, btnCompany, company) {
    this.onChangeCompany(btnCompany.value, company, radioContainer, btnCompany);
  }
  onChangeCompany(value, company, radioContainer, btnCompany) {
    this.radioContainer.forEach((ele) => {
      ele.nativeElement.style.border = `1px solid #dddddd`;
    });
    radioContainer.style.border = `2px solid ${this.myColor}`;
    btnCompany.checked = true;
    this.ShippingData = company;
    this.total_shipping_price = parseFloat(company.delivery_price);
    this.Common.directRequest$.next({
      city_id: this.city_id,
      shipment_id: company.shipment_id,
    });

    this.product.default_shipping = company.default_shipping_method == "1";
    this.setDeliveryDate(this.ShippingData);
  }

  selectPayment(
    paymentContainer,
    btnPayment,
    paymentKey,
    paymentName,
    paymentPrice
  ) {
    this.onChangePayment(
      paymentKey,
      paymentName,
      paymentPrice,
      paymentContainer,
      btnPayment
    );
  }
  onChangePayment(
    paymentKey,
    paymentName,
    paymentPrice,
    paymentContainer,
    btnPayment
  ) {
    this.paymentContainer.forEach((ele) => {
      ele.nativeElement.style.border = `1px solid #dddddd`;
    });
    paymentContainer.style.border = `2px solid ${this.myColor}`;
    btnPayment.checked = true;
    this.paymentData = {
      paymentKey: paymentKey,
      paymentName: paymentName,
      paymentPrice: paymentPrice,
    };
    this.total_payment_price = parseFloat(this.paymentData.paymentPrice);
  }
  getCouponsListAll() {
    this.couponesService.getCouponsList("all").subscribe((data) => {
      if (data.success) {
        this.activeCouponesList = data.data;

        for (let index = 0; index < this.activeCouponesList.length; index++) {
          const element = this.activeCouponesList[index];
          let accordionStatus: boolean = false;
          this.couponesAccordionList.push(accordionStatus);
        }
      }
    });
  }
  getCouponeAccordionStatus(index: any) {
    if (this.couponesAccordionList[index]) {
      return true;
    } else {
      return false;
    }
  }
  openCouponeDescription(index: any) {
    this.couponesAccordionList[index] = !this.couponesAccordionList[index];
  }

  addCoupon(codeCoupon = this.codeValueCoupon, productId) {
    this.couponesService
      .addUseCoupon(codeCoupon, "product", productId)
      .subscribe((data) => {
        if (data.success) {
          let msg1 =
            localStorage.getItem("front-lang") == "ar"
              ? "تم إضافة الكوبون بنجاح"
              : "Coupon Added Successfully";
          this.toastrService.success(msg1);
          this.isCouponAdded = true;
          this.couponId = data.data.id;
          this.Common.UpdatedSetting.next("decrease-cost");
          this.startCouponTimers();
        } else {
          this.toastrService.error(data.message);
        }
      });
  }

  startCouponTimers() {
    this.showMessageTimeout = setTimeout(() => {
      Swal.fire({
        html:
          localStorage.getItem("front-lang") === "ar"
            ? "سيتم إزالة كوبون الخصم بعد 5 دقائق. يُرجى إتمام الطلب قبل انتهاء المدة."
            : "The discount coupon will be removed after 5 minutes. Please complete your order before the time runs out.",
        icon: "warning",
        confirmButtonColor: getComputedStyle(
          document.querySelector("app-root")
        ).getPropertyValue("--theme-default"),
      });
    }, 45 * 60 * 1000);
    this.deleteCouponTimeout = setTimeout(() => {
      this.deleteCoupon(this.product_id);
    }, 50 * 60 * 1000);
  }

  clearCouponTimers() {
    if (this.showMessageTimeout) {
      clearTimeout(this.showMessageTimeout);
    }
    if (this.deleteCouponTimeout) {
      clearTimeout(this.deleteCouponTimeout);
    }
  }

  deleteCoupon(productId, deleteCart = "", message = true): Promise<void> {
    return new Promise((resolve, reject) => {
      this.couponesService.deleteUseCoupon(this.couponId, productId).subscribe(
        (data) => {
          if (data.success) {
            if (deleteCart != "delete-cart") {
              if (message) {
                this.toastrService.warning(data.message);
              }
            }
            this.isCouponAdded = false;
            this.codeValueCoupon = "";
            this.Common.UpdatedSetting.next("increase-cost");
            this.couponId = "";
            this.clearCouponTimers();
            resolve();
          } else {
            this.toastrService.error(data.message);
            reject(data.message);
          }
        },
        (error) => {
          this.toastrService.error("Error deleting coupon");
          reject(error);
        }
      );
    });
  }

  confirmCouponToInput(couponCode: any, productId: any) {
    if (!this.isCouponAdded) {
      console.log("id", productId);
      this.couponesService
        .addUseCoupon(couponCode, "product", productId)
        .subscribe((data) => {
          if (data.success) {
            this.codeValueCoupon = couponCode;
            let msg1 =
              localStorage.getItem("front-lang") == "ar"
                ? "تم إضافة الكوبون بنجاح"
                : "Coupon Added Successfully";
            this.toastrService.success(msg1);
            this.isCouponAdded = true;
            this.couponId = data.data.id;
            this.Common.UpdatedSetting.next("decrease-cost");
            this.showCouponsList = false;
            this.startCouponTimers();
            this.getCouponUsedInfo(data);
          } else {
            this.toastrService.error(data.message);
          }
        });
    } else {
      let msg2 =
        localStorage.getItem("front-lang") == "ar"
          ? "يرجى حذف الكوبون المفعل من السلة."
          : "Please Delete The Activated Coupon From Cart";
      this.toastrService.error(msg2);
    }
  }
  getCouponUsedInfo(data) {
    this.couponesService
      .getCouponUsedInfo(data.data.mode_id, data.data.mode)
      .subscribe((data) => {
        console.log("data", data);
      });
  }
  calculateTextColor(variable: string): string {
    const rootElement = this.elRef.nativeElement;
    const styles = getComputedStyle(rootElement);
    const colorValue = styles.getPropertyValue(variable).trim();
    return this.Common.tinyColor(colorValue);
  }

  increment() {
    if (this.new_counter < this.store_amount) {
      this.new_counter++;
      this.total_price = this.product
        ? parseFloat(this.product.price) * this.new_counter
        : 0;

      this.updateMinDeliveryPrice();
    }
  }

  decrement() {
    if (this.new_counter > 1) {
      this.new_counter--;
      this.total_price = this.product
        ? parseFloat(this.product.price) * this.new_counter
        : 0;
      this.updateMinDeliveryPrice();
    }
  }

  onSubmit() {
    console.log(this.form_direct_request);
    const productItem = [
      {
        product_id: this.product.id,
        quantity: this.new_counter,
        product_type_name: this.product_type ? this.product_type.name : "",
        offer_id: "",
        coupon_value: "0",
        is_gift: "",
        api_product_id: this.product.api_product_id,
      },
    ];
    if (this.form_direct_request.valid) {
      this.requestOrderLoading = true;
      let body = {
        quantity: this.new_counter,
        client_info: {
          ...this.form_direct_request.value,
          mobile: this.formatMobileNumber(
            this.form_direct_request.value.mobile
          ),
          mobile_1: this.formatMobileNumber(
            this.form_direct_request.value.mobile_1
          ),
          country_id: this.country_id,
          country_code: this.country_code,
        },
        payment_method_type: this.paymentData.paymentKey,
        payment_method_name: this.paymentData.paymentName,
        payment_method_price: this.paymentData.paymentPrice,
        invoice_edit_id: 0,
        api_invoice_edit_id: 0,
        address_full_name: "",
        note: this.form_direct_request.value.note
          ? this.form_direct_request.value.note
          : "",
        discount:
          (parseFloat(this.product?.last_price) -
            parseFloat(this.product?.price)) *
          this.new_counter,
        delivery_price:
          this.product.minDeliveryPrice !== undefined &&
          this.product.default_shipping
            ? this.product.minDeliveryPrice
            : this.ShippingData
            ? parseFloat(this.ShippingData.delivery_price)
            : 0,

        invoice_items: productItem,

        profit: 0,
        shipment_value: this.ShippingData
          ? parseFloat(this.ShippingData.shipment_value)
          : 0,
        shipment_company: this.ShippingData
          ? this.ShippingData.shipment_company
          : "",
        shipment_id: this.ShippingData
          ? parseInt(this.ShippingData.shipment_id)
          : 0,
        status: "pending",
        total_price: this.total_price,
        total_shipping_price:
          this.product.minDeliveryPrice !== undefined &&
          this.product.default_shipping
            ? this.product.minDeliveryPrice
            : this.total_shipping_price,
        total_payment_price: this.total_payment_price,
      };
      console.log("Form Submitted", body);
      this.addDirectRequest(body).subscribe({
        next: (res) => {
          console.log("Request Successful", res);
        },
        error: (error) => {
          console.error("Request Failed", error);
        },
      });
    } else {
      this.form_direct_request.markAllAsTouched();
    }
  }

  callAddDirectRequest(): Observable<any> {
    let body = localStorage.getItem(`direct_request-${this.product_id}`);
    let parsedBody = JSON.parse(body);
    return this.addDirectRequest(parsedBody, true);
  }

  addDirectRequest(body, pay = false): Observable<any> {
    return this._OrderService.addDirectRequest(body).pipe(
      tap((data) => {
        if (data.success) {
          this.requestOrderLoading = false;
          this.modalService.dismissAll();
          this.setDataSuccessOrder(
            this.product,
            body.quantity,
            data.invoice_id
          );
          this.extractedData[0].date = body.date;
          this.extractedData[0].total_price =
            parseFloat(body.total_price) +
            (this.product.minDeliveryPrice !== undefined &&
            this.product.default_shipping
              ? this.product.minDeliveryPrice
              : parseFloat(body.total_shipping_price)) +
            parseFloat(body.total_payment_price);

          if (isPlatformBrowser(this.platformId)) {
            // For SSR
            this.modalService.open(this.successOrder, {
              size: "lg",
              ariaLabelledBy: "successOrder-Modal",
              centered: true,
              windowClass: "success-order-modal",
            });
          }
          this.resetComponentState();
          if (this.paymentId) {
            localStorage.removeItem(`direct_request-${this.product_id}`);
            this.router.navigate([], {
              queryParams: {},
            });
          }
          this.directRequestAdded.emit();
          this.doneSign(body.client_info.mobile, body.client_info.mobile);
        } else {
          this.requestOrderLoading = false;
          this.directRequestLoading = false;
          this.modalService.dismissAll();
          Swal.fire({
            title:
              localStorage.getItem("front-lang") == "ar"
                ? `<h4 class="text-center">تم الدفع</h4>`
                : `<h4 class="text-center">Payment Completed</h4>`,
            html:
              localStorage.getItem("front-lang") == "ar"
                ? `<p class="text-center">حدث خطأ أثناء حفظ الطلب، يرجى الرجوع للدعم</p> <p class="text-center">رقم العملية: ${this.paymentId}</p>`
                : `<p class="text-center">An error occurred while saving the request, please contact support</p> <p class="text-center">Transaction ID: ${this.paymentId}</p>`,
            icon: "warning",
            confirmButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default"),
          });
        }
      })
    );
  }

  setDataSuccessOrder(product, quantity, invoice_id) {
    this.extractedData[0].product_items.push({
      image: product.images[0].original_name
        ? this.urlimage + product.images[0].original_name
        : "",
      quantity: quantity,
      price: product.price,
    });
    this.extractedData[0].invoice_id = invoice_id;
  }

  openDirectRequestModal() {
    this.directRequestLoading = true;
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.tempDirectRequest, {
          size: "md",
          ariaLabelledBy: "direct-Modal",
          centered: true,
        })
        .result.then(
          (result) => {},
          (reason) => {
            `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  openPaymentModal() {
    if (!this.form_direct_request.valid) {
      Swal.fire({
        text:
          localStorage.getItem("front-lang") == "ar"
            ? "يرجى ملء جميع الحقول المطلوبة"
            : "Please fill out all required fields.",
        confirmButtonColor: getComputedStyle(
          document.querySelector("app-root")
        ).getPropertyValue("--theme-default"),
        icon: "error",
      });
      setTimeout(() => {
        this.resetPayment();
      }, 100);
      return;
    }
    this.getPaymentMethodsAfterCalculation();
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.paymentModal, {
          size: "md",
          ariaLabelledBy: "payment-Modal",
          centered: true,
        })
        .result.then(
          (result) => {
            `Result ${result}`;
          },
          (reason) => {
            `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  resetPayment(index = 0) {
    const paymentContainersArray = this.paymentContainer.toArray();
    const btnPaymentsArray = this.btnPayments.toArray();

    const startPaymentIndex = 0;
    const endPaymentIndex = this.paymentInfo.length;

    const PaymentContainers = paymentContainersArray.slice(
      startPaymentIndex,
      endPaymentIndex
    );

    const BtnPayments = btnPaymentsArray.slice(
      startPaymentIndex,
      endPaymentIndex
    );

    if (PaymentContainers.length > 0 && BtnPayments.length > 0) {
      PaymentContainers.forEach((paymentContainer) => {
        paymentContainer.nativeElement.style.border = `1px solid #dddddd`;
      });

      BtnPayments.forEach((btnPayment) => {
        btnPayment.checked = false;
      });

      PaymentContainers[
        index
      ].nativeElement.style.border = `2px solid ${this.myColor}`;
      BtnPayments[index].checked = true;
      this.paymentData.paymentKey = this.paymentInfo[index].key;
      this.paymentData.paymentName = this.paymentInfo[index].name;
      this.paymentData.paymentPrice = this.paymentInfo[index].price;
      this.total_payment_price = parseFloat(this.paymentData.paymentPrice);
    }
  }
  async getPaymentMethodsAfterCalculation() {
    this.paymentLoading = true;
    // await this.calculateTotalPaymentPrice();
    this.getPaymentMethods(
      this.total_price +
        (this.product.minDeliveryPrice !== undefined &&
        this.product.default_shipping
          ? this.product.minDeliveryPrice
          : this.total_shipping_price) +
        this.total_payment_price
    );
  }

  getPaymentMethods(totalPrice: any): void {
    const body = {
      InvoiceAmount: totalPrice,
      CurrencyIso: this.contactInfoData.payment_gateway_currency_iso,
    };
    const productItem = [
      {
        product_id: this.product.id,
        quantity: this.new_counter,
        product_type_name: this.product_type ? this.product_type.name : "",
        offer_id: "",
        coupon_value: "0",
        is_gift: "",
        api_product_id: this.product.api_product_id,
      },
    ];
    let direct_request_data = {
      quantity: this.new_counter,
      client_info: {
        ...this.form_direct_request.value,
        mobile: this.formatMobileNumber(this.form_direct_request.value.mobile),
        mobile_1: this.formatMobileNumber(
          this.form_direct_request.value.mobile_1
        ),
        country_id: this.country_id,
        country_code: this.country_code,
      },
      payment_method_type: this.paymentData.paymentKey,
      payment_method_name: this.paymentData.paymentName,
      payment_method_price: this.paymentData.paymentPrice,
      invoice_edit_id: 0,
      api_invoice_edit_id: 0,
      address_full_name: "",

      note: this.form_direct_request.value.note
        ? this.form_direct_request.value.note
        : "",
      discount:
        (parseFloat(this.product?.last_price) -
          parseFloat(this.product?.price)) *
        this.new_counter,
      delivery_price:
        this.product.minDeliveryPrice !== undefined &&
        this.product.default_shipping
          ? this.product.minDeliveryPrice
          : this.ShippingData.delivery_price
          ? parseFloat(this.ShippingData.delivery_price)
          : 0,

      invoice_items: productItem,

      profit: 0,
      shipment_value: this.ShippingData
        ? parseFloat(this.ShippingData.shipment_value)
        : 0,
      shipment_company: this.ShippingData
        ? this.ShippingData.shipment_company
        : "",
      shipment_id: this.ShippingData
        ? parseInt(this.ShippingData.shipment_id)
        : 0,
      status: "pending",
      date: this.extractedData[0].date,
      total_price: this.total_price,
      total_shipping_price:
        this.product.minDeliveryPrice !== undefined &&
        this.product.default_shipping
          ? this.product.minDeliveryPrice
          : this.total_shipping_price,
      total_payment_price: this.total_payment_price,
    };
    console.log("direct_request_data", direct_request_data);
    localStorage.setItem(
      `direct_request-${this.product_id}`,
      JSON.stringify(direct_request_data)
    );

    this.FatoorahPaymentService.getPaymentMethods(
      body,
      this.contactInfoData.payment_gateway_api_key
    ).subscribe(
      (response) => {
        if (response.IsSuccess) {
          this.paymentMethods = response.Data.PaymentMethods;
          this.paymentLoading = false;
        } else {
          console.error("Failed to fetch payment methods", response);
        }
      },
      (error) => {
        console.error("API error", error);
      }
    );
  }

  ExecutePayment(method: any): void {
    const url = document.location.origin;
    console.log("url", url);
    this.user_info = this.form_direct_request.value;
    const paymentData = {
      InvoiceValue: method.TotalAmount,
      Language: this.Common.isRtl ? "ar" : "en",
      PaymentMethodId: method.PaymentMethodId,
      DisplayCurrencyIso: this.contactInfoData.payment_gateway_currency_iso,
      CustomerName: `${this.user_info.user_name}`,
      // MobileCountryCode: this.country_code,
      // UserDefinedField: "CK-" + this.user_info.id,
      CustomerMobile: this.user_info.mobile,
      // CustomerEmail: this.user_info.email,
      // CustomerCivilId: this.user_info.id,
      CustomerReference: this.product_id,
      CallBackUrl: `${url}/shop/product/${this.params.slug}/${this.params.name}`,
    };
    this.FatoorahPaymentService.ExecutePayment(
      paymentData,
      this.contactInfoData.payment_gateway_api_key
    ).subscribe({
      next: (response) => {
        console.log(response);
        if (response.IsSuccess) {
          location.href = response.Data.PaymentURL;
        } else {
          console.error("Payment failed", response);
        }
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      },
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  onChangecity_id(event: any) {
    if (event) {
      this.district_id = "";
      this.form_direct_request.get("district_id").setValue(null);
      this.city_id = event.id;
      console.log("form", this.form_direct_request.value);
      this.onChangeCity(this.city_id);
      this.Common.directRequest$.next({
        city_id: this.city_id,
        shipment_id: this.shipping_methods[0].shipment_id,
      });
    }
  }

  onChangeDistrict_id(event: any) {
    if (event) {
      this.district_id = event.id;
    }
  }
  onClearCity() {
    this.city_id = "";
    this.form_direct_request.get("city_id").setValue(null);
  }
  onClearDistrict() {
    this.district_id = "";
    this.form_direct_request.get("district_id").setValue(null);
  }
  formatMobileNumber(mobileNumber) {
    if (mobileNumber && mobileNumber.charAt(0) !== "0") {
      mobileNumber = "0" + mobileNumber;
    }
    return mobileNumber;
  }

  doneSign(mobile, password): void {
    let body = {
      mobile: mobile,
      password: password,
    };

    this.user.userLogin(body).subscribe((data) => {
      if (data.data) {
        localStorage.setItem("clientToken", data.data.authentication_code);
        localStorage.setItem("affiliatorToken", environment.affiliator_token);
        localStorage.setItem("currentUserFront", JSON.stringify(data.data));
        this.user.shareCartList().subscribe((data) => {
          console.log(JSON.stringify(data));
        });

        this.user.userLoggedIn(data.data.user_name);
      } else {
        return;
      }
    });
  }

  calculateTimer(
    targetHour: number
  ): { hours: string; minutes: string; seconds: string } | null {
    const now = new Date();
    const targetDate = new Date();
    if (this.timers[targetHour]) {
      return this.timers[targetHour];
    }
    targetDate.setHours(targetHour, 0, 0, 0);
    if (targetDate.getTime() <= now.getTime()) {
      return null;
    }
    const targetTimestamp = targetDate.getTime();
    this.timers[targetHour] = { hours: "00", minutes: "00", seconds: "00" };
    // if (this.subscriptionTimer) {
    //   this.subscriptionTimer.unsubscribe();
    // }
    this.subscriptionTimer = interval(1000).subscribe(() => {
      const now = new Date().getTime();
      const remainingMilliseconds = targetTimestamp - now;
      if (remainingMilliseconds <= 0) {
        this.timers[targetHour] = { hours: "00", minutes: "00", seconds: "00" };
        this.subscriptionTimer?.unsubscribe();
      } else {
        const totalSeconds = Math.floor(remainingMilliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        this.timers[targetHour] = {
          hours: hours.toString().padStart(2, "0"),
          minutes: minutes.toString().padStart(2, "0"),
          seconds: seconds.toString().padStart(2, "0"),
        };
      }
    });

    return this.timers[targetHour];
  }

  hasIncompleteFields(): boolean {
    if (!this.form_direct_request) return false;
    return this.directRequests.some(request => {
      const control = this.form_direct_request.get(request.type);
      return control && control.invalid && control.touched;
    });
  }

  
  getIncompleteLabels(): string {
    if (!this.form_direct_request) return '';  
    const incompleteLabels = this.directRequests
      .filter(request => {
        const control = this.form_direct_request.get(request.type);
        return control && control.invalid && control.touched;
      })
      .map(request => this.direct_labels[request.type]);
  
    return incompleteLabels.join(', ');
  }
  
}
