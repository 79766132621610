import { NotificationModalComponent } from "./shared/components/modal/notification-modal/notification-modal.component";
import {
  Component,
  PLATFORM_ID,
  Inject,
  OnInit,
  ElementRef,
  Renderer2,
  ViewChild,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from "@angular/common";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { map, delay, withLatestFrom, reduce } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../app/shared/services/user.service";
import { CommonService } from "./shared/services/common.service";
import { Meta, Title } from "@angular/platform-browser";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { MasterApiService } from "./shared/services/master-api.service";
import { MatBottomSheet } from "@angular/material/bottom-sheet";

import { TokenService } from "./shared/services/token.service";

declare var gtag: Function;
declare var fbq: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  favIcon: HTMLLinkElement = document.querySelector("#appIcon");

  maintenance: any;
  maintenanceMessage: any;

  isBrowser: any;

  firefox: boolean = false;
  tokenID: string = "";
  organizationSchema: any;
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map((v) => v[1])
  );

  title = "angular-app";
  closeResult = "";
  code_title: any = "Zahra";
  meta_description: any;
  code_body: any;
  code_head: any;
  hostName: string;
  public _lang_code =
    localStorage.getItem("front-lang") != null
      ? localStorage.getItem("front-lang")
      : "ar";

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private Common: UserService,
    private loader: LoadingBarService,
    translate: TranslateService,
    public comserv: CommonService,
    private titleService: Title,
    private router: Router,
    public masterApiService: MasterApiService,
    public tokenService: TokenService,
    public notificationModalComponent: NotificationModalComponent,
    private host: ElementRef<HTMLElement>,
    private meta: Meta,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private documentt: Document,
    private bottomSheet: MatBottomSheet,
    private CommonService: CommonService
  ) {
    if (this.masterApiService.masterData) {
      // console.log('MASTER API WORKSSS', this.masterApiService.masterData);
    }

    this.isBrowser = isPlatformBrowser(this.platformId);
    // console.log("is browser ", isPlatformBrowser(this.platformId));

    /* If Browser Plateform is valid */
    if (this.isBrowser) {
      if (
        localStorage.getItem("front-lang") != null &&
        localStorage.getItem("front-lang") != ""
      ) {
        if (localStorage.getItem("front-lang") == "en") {
          document.body.classList.remove("rtl");
          document.body.classList.add("ltr");
          translate.setDefaultLang("en");
          translate.addLangs(["en", "ar"]);
        } else {
          document.body.classList.remove("ltr");
          document.body.classList.add("rtl");
          translate.setDefaultLang("ar");
          translate.addLangs(["en", "ar"]);
        }
      } else {
        document.body.classList.remove("ltr");
        document.body.classList.add("rtl");
        translate.setDefaultLang("ar");
        translate.addLangs(["en", "ar"]);
      }
      this.Common.addVisite().subscribe((data) => {
        if (data.data) {
          // console.log("add Visite in app component");
        } else {
          return;
        }
      });
    }
    localStorage.setItem("front-lang", this._lang_code);
  }

  async ngOnInit() {
    let Subtitle: string = this._lang_code == "ar" ? "الرئيسية" : "Home";
    this.comserv.setTitle(Subtitle, true, false, "app");

    this.masterApiService.getCurrentDataContact().subscribe((dataContact) => {
      if (dataContact) {
        
        this.maintenance = dataContact.maintenance_mode;
        this.maintenanceMessage = dataContact.maintenance_mode_message;
        this.titleService.setTitle(
          dataContact["tag_title"] || dataContact["front_name"]
        );
        this.meta.updateTag({
          name: "description",
          content: dataContact["meta_description"],
        });
        this.meta.updateTag({
          name: "keywords",
          content: dataContact["meta_keywords"],
        });
      } else {
        console.error("Data contact information is undefined or null");
      }

      this.code_head = dataContact["code_head"];
      this.code_body = dataContact["code_body"];
      this.meta_description = dataContact["meta_description"];
      this.comserv.currencyType = dataContact["front_currency"];
      this.code_title = dataContact["code_title"];

      localStorage.setItem(
        "paymentInfo",
        JSON.stringify(this.comserv.getAllPaymentMethods(dataContact))
      );
      //?=== add code to head ===//
      this.addCodehead(this.code_head);

      //?=== add code to body ===//

      this.addCodebody(this.code_body);

      // console.log(dataContact['front_color']);
      this.host.nativeElement.style.setProperty(
        "--theme-default",
        dataContact["front_color"] ? dataContact["front_color"] : "#cc0000"
      );
      this.host.nativeElement.style.setProperty(
        "--theme-default2",
        dataContact["front_color_2"] ? dataContact["front_color_2"] : "#fed430"
      );

      fbq("init", dataContact["facebook_pixel_id"]);

      // Set CSS variable globally
      document.documentElement.style.setProperty(
        "--theme-default",
        dataContact["front_color"] ? dataContact["front_color"] : "#cc0000"
      );
      document.documentElement.style.setProperty(
        "--theme-default2",
        dataContact["front_color_2"] ? dataContact["front_color_2"] : "#fed430"
      );

      const root = document.documentElement;
      const alphaValue = 0.2;

      root.style.setProperty(
        "--theme-default2-alpha",
        this.hexToRgba(
          dataContact["front_color_2"]
            ? dataContact["front_color_2"]
            : "#fed430",
          alphaValue
        )
      );

      root.style.setProperty(
        "--theme-default-alpha",
        this.hexToRgba(
          dataContact["front_color"] ? dataContact["front_color"] : "#cc0000",
          alphaValue
        )
      );

      // PIXEL EVENT TAG
      const navEndEvents = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      );
      navEndEvents.subscribe((event: NavigationEnd) => {
        gtag("config", dataContact["google_analytics_id"], {
          page_path: event.urlAfterRedirects,
        });
        fbq("track", "الصفحات");
      });

      if (dataContact["front_fav_icon"]) {
        this.favIcon.href =
          this.comserv._ImageUrl +
          "front_images/" +
          dataContact["front_fav_icon"];
      }
    });
  }

  hexToRgba(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r},${g},${b},${alpha})`;
  }

  closeTemplateSheetMenu() {
    this.comserv.noteOn = false;
    this.bottomSheet.dismiss();
  }

  addCodebody(code: any) {
    const parser = new DOMParser();
    //? Parse the dynamic content as if it were script content ==> code
    const doc = parser.parseFromString(`<body>${code}<body/>`, "text/html");
    const bodyElement = doc.querySelector("body");

    const elements = bodyElement.childNodes;

    const body = document.getElementsByTagName("body")[0];
    elements.forEach((ele, index) => {
      if (ele instanceof Element || ele instanceof Comment) {
        const script = document.createElement("script");
        script.textContent = ele.textContent;

        body.insertBefore(
          ele.nodeName.toLocaleLowerCase() == "script" ? script : ele,
          body.firstChild
        );
      }
    });
  }

  addCodehead(code: any) {
    const parser = new DOMParser();
    //? Parse the dynamic content as if it were script content ==> code
    const doc = parser.parseFromString(`<body>${code}<body/>`, "text/html");

    const bodyElement = doc.querySelector("body");

    const elements = bodyElement.childNodes;

    const head = document.getElementsByTagName("head")[0];
    elements.forEach((ele, index) => {
      if (ele instanceof Element || ele instanceof Comment) {
        const script = document.createElement("script");
        script.textContent = ele.textContent;

        head.appendChild(
          ele.nodeName.toLocaleLowerCase() == "script" ? script : ele
        );
      }
    });
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1:
        console.log("edge");
        this.firefox = false;
        return "edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        console.log("opera");
        this.firefox = false;
        return "opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        console.log("chrome");
        this.firefox = false;
        return "chrome";
      case agent.indexOf("trident") > -1:
        console.log("ie");
        this.firefox = false;
        return "ie";
      case agent.indexOf("firefox") > -1:
        console.log("firefox");
        this.firefox = true;
        return "firefox";
      case agent.indexOf("safari") > -1:
        console.log("safari");
        this.firefox = false;
        return "safari";
      default:
        console.log("other");
        this.firefox = false;
        return "other";
    }
  }
}
