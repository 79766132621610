import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ViewportScroller } from "@angular/common";
import { ProductService } from "../../../shared/services/product.service";
import { Product } from "../../../shared/classes/product";
import { ParamMap } from "@angular/router";
import * as _ from "lodash";
import { CommonService } from "src/app/shared/services/common.service";
import { CouponesService } from "src/app/shared/services/coupones.service";

@Component({
  selector: "app-collection-coupon",
  templateUrl: "./collection-coupon.component.html",
  styleUrls: ["./collection-coupon.component.scss"],
})
export class CollectionCouponComponent implements OnInit {
  public grid: string = "col-xl-3 col-md-6";
  public layoutView: string = "grid-view";
  public all_products: any[] = [];
  public products: any[] = [];
  public allItems: Product[] = [];
  public brands: any[] = [];
  public colors: any[] = [];
  public size: any[] = [];
  public minPrice: number = 0;
  public maxPrice: number = 1200;
  public tags: any[] = [];
  public category: string;
  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only
  public sortBy: string; // Sorting Order
  public mobileSidebar: boolean = false;
  public loader: boolean = true;
  public finished: boolean = false; // boolean when end of data is reached
  public addItemCount = 8;

  loading: boolean = true;
  loadingImgUrl: string = "";
  public getNoData: boolean = false;

  couponDetails: any;

  timeLeftTillOfferEnds: any;
  title: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private viewScroller: ViewportScroller,
    public productService: ProductService,
    public CouponesService: CouponesService,
    private commonService: CommonService
  ) {
    this.route.params.subscribe((params: ParamMap) => {
      const coupon_id = params["slug"];
      this.getOneCoupon(coupon_id);
      this.loading = true;
      this.getNoData = false;
      this.productService
        .getProductsPayParams(
          "",
          "",
          "",
          "",
          "",
          0,
          25,
          "",
          "",
          [],
          "",
          "",
          [],
          coupon_id
        )
        .subscribe((products) => {
          if (products.data) {
            this.allItems = products.data;
            this.all_products = this.allItems;
            this.loading = false;
            this.finished = true;
            if (this.allItems.length == 0) {
              this.getNoData = true;
            }
          } else {
            this.getNoData = true;
            this.loading = false;
            this.finished = true;
          }
        });
    });

    // SET PAGE TITLE

    // Get Query params..
    this.route.queryParams.subscribe((params) => {
      this.products = [];
      this.finished = false;

      this.brands = params.brand ? params.brand.split(",") : [];
      this.colors = params.color ? params.color.split(",") : [];
      this.size = params.size ? params.size.split(",") : [];
      this.minPrice = params.minPrice ? params.minPrice : this.minPrice;
      this.maxPrice = params.maxPrice ? params.maxPrice : this.maxPrice;
      this.tags = [...this.brands, ...this.colors, ...this.size]; // All Tags Array

      this.category = params.category ? params.category : null;
      this.sortBy = params.sortBy ? params.sortBy : "ascending";

      // Get Filtered Products..
      this.productService.filterProducts(this.tags).subscribe((response) => {
        // All Products
        this.all_products = response;

        // Sorting Filter
        this.all_products = this.productService.sortProducts(
          response,
          this.sortBy
        );

        // Category Filter
        if (params.category)
          this.all_products = this.all_products.filter(
            (item) => item.type == this.category
          );

        // Price Filter
        this.all_products = this.all_products.filter(
          (item) => item.price >= this.minPrice && item.price <= this.maxPrice
        );

        this.addItems();
      });
    });
  }

  ngOnInit(): void {}

  getTimeLeftOfferEnds(dateEnd) {
    var x = new Date();
    var y = new Date(dateEnd);
    let seconds = Math.abs(x.getTime() - y.getTime()) / 1000;
    this.timeLeftTillOfferEnds = seconds;
  }

  getOneCoupon(offerId: number) {
    this.CouponesService.getOneCoupon(offerId).subscribe({
      next: (result) => {
        console.log("getOneCoupon", result);
        this.couponDetails = result.data;
        this.commonService.setTitle(this.couponDetails.title);

        this.commonService.setMeta("title", this.couponDetails?.title);

        this.getTimeLeftOfferEnds(this.couponDetails.coupon_end_date);
      },
    });
  }

  addItems() {
    if (
      this.all_products.length <= this.products.length &&
      this.all_products.length <= this.addItemCount
    ) {
      this.finished = true;
      // console.log("in side addItems");
      return;
    }
    this.products = this.all_products.slice(0, this.addItemCount);
  }

  // Infinite scroll
  public onScroll() {
    // add another items
    this.addItemCount += 8;
    this.addItems();
  }

  // Append filter value to Url
  updateFilter(tags: any) {
    tags.page = null; // Reset Pagination
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: tags,
        queryParamsHandling: "merge", // preserve the existing query params in the route
        skipLocationChange: false, // do trigger navigation
      })
      .finally(() => {
        this.viewScroller.setOffset([120, 120]);
        this.viewScroller.scrollToAnchor("products"); // Anchore Link
      });
  }

  // SortBy Filter
  sortByFilter(value) {
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { sortBy: value ? value : null },
        queryParamsHandling: "merge", // preserve the existing query params in the route
        skipLocationChange: false, // do trigger navigation
      })
      .finally(() => {
        this.viewScroller.setOffset([120, 120]);
        this.viewScroller.scrollToAnchor("products"); // Anchore Link
      });
  }

  // Remove Tag
  removeTag(tag) {
    this.brands = this.brands.filter((val) => val !== tag);
    this.colors = this.colors.filter((val) => val !== tag);
    this.size = this.size.filter((val) => val !== tag);

    let params = {
      brand: this.brands.length ? this.brands.join(",") : null,
      color: this.colors.length ? this.colors.join(",") : null,
      size: this.size.length ? this.size.join(",") : null,
    };

    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: params,
        queryParamsHandling: "merge", // preserve the existing query params in the route
        skipLocationChange: false, // do trigger navigation
      })
      .finally(() => {
        this.viewScroller.setOffset([120, 120]);
        this.viewScroller.scrollToAnchor("products"); // Anchore Link
      });
  }

  // Clear Tags
  removeAllTags() {
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: {},
        skipLocationChange: false, // do trigger navigation
      })
      .finally(() => {
        this.viewScroller.setOffset([120, 120]);
        this.viewScroller.scrollToAnchor("products"); // Anchore Link
      });
  }

  // Change Grid Layout
  updateGridLayout(value: string) {
    this.grid = value;
  }

  // Change Layout View
  updateLayoutView(value: string) {
    this.layoutView = value;
    if (value == "list-view") this.grid = "col-lg-12";
    else this.grid = "col-xl-3 col-md-6";
  }

  // Mobile sidebar
  toggleMobileSidebar() {
    this.mobileSidebar = !this.mobileSidebar;
  }
}
