import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from "../../shared/services/product.service";
import { Product } from "../../shared/classes/product";
import { CommonService } from "../../shared/services/common.service";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {
  urlimage;
  public products: Product[] = [];
  page_name = localStorage.getItem('front-lang') == 'ar' ? "المفضلة" : "Wishlist";
  currentUrl: string = "";
  hostName: string = "";

  constructor(
    private router: Router,
    public productService: ProductService,
    private Common: CommonService,
    private toastrService: ToastrService
  ) {
    // SET TITLE
    this.Common.setTitle(this.page_name);

    this.productService.wishlistItems.subscribe(response => this.products = response);
    this.urlimage = this.Common._ImageUrl + 'products/thumbs/';
  }

  ngOnInit(): void {
    console.log(this.products);

    this.hostName = this.Common.getDomainFromUrl();
    this.currentUrl = `https://${this.hostName}/shop/wishlist`;

  }
  removeBrackets(str: string): string {
    return str?.replace(/[\(/%]/g, '');
  }
  async addToCart(product: any) {
    const status = await this.productService.addToCart(product);
    // if(status) {
    //   this.router.navigate(['/shop/cart']);
    //   this.removeItem(product);
    // }
    this.removeItem(product);
  }
  handleImageError(e) {
    e.src = 'assets/images/product/placeholder2.jpg';
  }
  removeItem(product: any) {
    this.productService.removeWishlistItem(product);
  }

}
