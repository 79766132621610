import { Inject, NgModule, PLATFORM_ID } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxPayPalModule } from "ngx-paypal";
import { Ng5SliderModule } from "ng5-slider";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { SharedModule } from "../shared/shared.module";
import { ShopRoutingModule } from "./shop-routing.module";

// Product Details Components
import { ProductLeftSidebarComponent } from "./product/sidebar/product-left-sidebar/product-left-sidebar.component";
import { ProductRightSidebarComponent } from "./product/sidebar/product-right-sidebar/product-right-sidebar.component";
import { ProductNoSidebarComponent } from "./product/sidebar/product-no-sidebar/product-no-sidebar.component";
import { FourImageComponent } from "./product/four-image/four-image.component";
import { ThreeColumnComponent } from "./product/three-column/three-column.component";
import { BundleProductComponent } from "./product/bundle-product/bundle-product.component";
import { ImageOutsideComponent } from "./product/image-outside/image-outside.component";
import { SanitizeHtmlPagesPipe } from "../shared/pipes/sanitize-html-pages.pipe";

import { AddToCartBlockComponent } from "../elements/product/add-to-cart-block/add-to-cart-block.component";

// Product Details Widgest Components
import { ServicesComponent } from "./product/widgets/services/services.component";
import { CountdownComponent } from "./product/widgets/countdown/countdown.component";
import { SocialComponent } from "./product/widgets/social/social.component";
import { StockInventoryComponent } from "./product/widgets/stock-inventory/stock-inventory.component";
import { RelatedProductComponent } from "./product/widgets/related-product/related-product.component";

// Collection Components
import { CollectionLeftSidebarComponent } from "./collection/collection-left-sidebar/collection-left-sidebar.component";
import { CollectionRightSidebarComponent } from "./collection/collection-right-sidebar/collection-right-sidebar.component";
import { CollectionNoSidebarComponent } from "./collection/collection-no-sidebar/collection-no-sidebar.component";
import { CollectionInfinitescrollComponent } from "./collection/collection-infinitescroll/collection-infinitescroll.component";
import { CollectionTrademarkesComponent } from "./collection/collection-trademarkes/collection-trademarkes.component";
import { CollectionOfferComponent } from "./collection/collection-offer/collection-offer.component";

// Collection Widgets
import { GridComponent } from "./collection/widgets/grid/grid.component";
import { PaginationComponent } from "./collection/widgets/pagination/pagination.component";
import { BrandsComponent } from "./collection/widgets/brands/brands.component";
import { ColorsComponent } from "./collection/widgets/colors/colors.component";
import { SizeComponent } from "./collection/widgets/size/size.component";
import { PriceComponent } from "./collection/widgets/price/price.component";

import { CartComponent } from "./cart/cart.component";
import { WishlistComponent } from "./wishlist/wishlist.component";
import { CompareComponent } from "./compare/compare.component";
import { CheckoutComponent } from "./checkout/checkout.component";
import { SuccessComponent } from "./checkout/success/success.component";

// SWEETALERT

// Import library module
import { NgxJsonLdModule } from "ngx-json-ld";
import { SanitizeHtmlPipe } from "../shared/pipes/sanitize-html.pipe";
import { WishlistSingleItemComponent } from "./wishlist/wishlist-single-item/wishlist-single-item.component";
import { BrowseLogItemModule } from "../pages/account/browse-log/browse-log-item/browse-log-item.module";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";
import { FormsModule } from "@angular/forms";
import { CustomRedirectComponent } from "./product/custom-redirect/custom-redirect.component";
//import {MatRadioModule} from '@angular/material/radio';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FooterModalComponent } from "./footer-modal/footer-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatRadioModule } from "@angular/material/radio";
import { TrimPipe } from "../shared/pipes/trim.pipe";
import { DirectRequestComponent } from "./product/sidebar/direct-request/direct-request.component";
import { NgSelectModule } from '@ng-select/ng-select';
import { CollectionCouponComponent } from './collection/collection-coupon/collection-coupon.component';

@NgModule({
  declarations: [
    AddToCartBlockComponent,
    ProductLeftSidebarComponent,
    ProductRightSidebarComponent,
    ProductNoSidebarComponent,
    ThreeColumnComponent,
    FourImageComponent,
    BundleProductComponent,
    ImageOutsideComponent,
    ServicesComponent,
    CountdownComponent,
    SocialComponent,
    StockInventoryComponent,
    RelatedProductComponent,
    CollectionLeftSidebarComponent,
    CollectionRightSidebarComponent,
    CollectionNoSidebarComponent,
    CollectionInfinitescrollComponent,
    CollectionTrademarkesComponent,
    CollectionOfferComponent,
    GridComponent,
    PaginationComponent,
    BrandsComponent,
    ColorsComponent,
    SizeComponent,
    PriceComponent,
    CartComponent,
    WishlistComponent,
    CompareComponent,
    CheckoutComponent,
    SuccessComponent,
    SanitizeHtmlPipe,
    TrimPipe,
    WishlistSingleItemComponent,
    CustomRedirectComponent,
    FooterModalComponent,
    DirectRequestComponent,
    CollectionCouponComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    NgxPayPalModule,
    Ng5SliderModule,
    InfiniteScrollModule,
    MatIconModule,
    MatExpansionModule,
    MatDialogModule,
    //MatRadioModule,
    SharedModule,
    ShopRoutingModule,
    NgxJsonLdModule,
    BrowseLogItemModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    NgSelectModule
  ],
  exports: [CartComponent],
})
export class ShopModule {
  constructor() {}
}
