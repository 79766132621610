<div class="icon-nav" *ngIf="screenWidth > 577">
    <ul>
        <!-- <li class="onhover-div mobile-search search-widgets" id="search-widgets" *ngIf="false">
            <div (click)="searchToggle()">
                <img src="assets/images/icon/search.png" class="img-fluid" alt="">
                <i class="ti-search"></i>
            </div>
            <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
                <div>
                    <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
                    <div class="overlay-content">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-12">
                                    <form>
                                        <div class="form-group">
                                            <input type="text" name="query" [(ngModel)]="query" class="form-control" id="exampleInputPassword1" placeholder="Search a Product">
                                        </div>
                                        <button type="submit" class="btn btn-primary" (click)="goToSearch()">  <img src="assets/images/icon/search.png" class="img-fluid" alt=""> </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li> -->

        <!-- <li class="onhover-div mobile-account" *ngIf="!userLogedIn">
            <div class="text-center cursor_pointer" (click)="goTo('/pages/login')">
                <img src="assets/images/icon/account.png" class="img-fluid" alt="settong" width="25px" height="25px">
                <p class="mt-1 mb-0 text-center">{{'login' | translate}}</p>
            </div>
        </li> -->

        <li class="onhover-div mobile-account" *ngIf="!userLogedIn">
            <div class="text-center cursor_pointer" (click)="goToLogin()">
                <img src="assets/images/icon/account.png" class="img-fluid" alt="settong" width="25px" height="25px">
                <p class="mt-1 mb-0 text-center">{{'login' | translate}}</p>
            </div>
        </li>
        <li class="text-center cursor-pointer onhover-div mobile-account profile-hover " *ngIf="userLogedIn">
            <div (click)="goTo('/pages/dashboard')">
                <img src="assets/images/icon/account.png" class="img-fluid" alt="settong" width="25px" height="25px">
                <p class="mt-1 mb-0 text-center">{{'Profile' | translate}}</p>
            </div>
            <div class="shadow profile-hoverd">
                <ul>

                    <li class="li-hovered">
                        <a data-lng="es" [class]="Common.isRtl ? 'link-ar':'link-en'" [routerLink]="'/pages/my-orders'">

                            <img src="assets/images/regular icons/order.svg" class="img-fluid lazy-loading"
                                alt=" defaultImage" (error)="handleImageError($event.target)">
                            {{'orders' | translate}}
                        </a>
                    </li>
                    <li class="li-hovered">
                        <a data-lng="es" [routerLink]="'/pages/profile'" [class]="Common.isRtl ? 'link-ar':'link-en'">
                            <img src="assets/images/regular icons/account.svg" class="img-fluid lazy-loading"
                                alt=" defaultImage" (error)="handleImageError($event.target)">
                            {{'my profile' | translate}}
                        </a>
                    </li>
                    <li class="li-hovered">
                        <a data-lng="es" [routerLink]="'/pages/dashboard'" [class]="Common.isRtl ? 'link-ar':'link-en'">
                            <img src="assets/images/regular icons/address.svg" class="img-fluid lazy-loading"
                                alt=" defaultImage" (error)="handleImageError($event.target)">
                            {{'address' | translate}}
                        </a>
                    </li>
                    <li class="li-hovered">
                        <a data-lng="es" [routerLink]="'/pages/browse-log'"
                            [class]="Common.isRtl ? 'link-ar':'link-en'">
                            <img src="assets/images/regular icons/browser.svg" class="img-fluid lazy-loading"
                                alt=" defaultImage" (error)="handleImageError($event.target)">
                            {{'browse log' | translate}}
                        </a>
                    </li>
                    <li class="li-hovered"><a data-lng="es" [class]="Common.isRtl ? 'link-ar':'link-en'"
                            [routerLink]="'/pages/my-coupones'">
                            <img src="assets/images/regular icons/coupon.svg" class="img-fluid lazy-loading"
                                alt=" defaultImage" (error)="handleImageError($event.target)">
                            {{'coupones' | translate}}
                        </a>
                    </li>
                    <li class="li-hovered"><a data-lng="es" [class]="Common.isRtl ? 'link-ar':'link-en'"
                            [routerLink]="'/pages/notifications'">
                            <img src="assets/images/regular icons/nofity.svg" class="img-fluid lazy-loading"
                                alt=" defaultImage" (error)="handleImageError($event.target)">
                            {{'notification' | translate}}
                        </a>
                    </li>
                    <li class="li-hovered"><a data-lng="es" [class]="Common.isRtl ? 'link-ar':'link-en'"
                            (click)="goTo('/shop/wishlist') ">
                            <img src="assets/images/regular icons/fav.svg" class="img-fluid lazy-loading"
                                alt=" defaultImage" (error)="handleImageError($event.target)">
                            {{'fav' | translate}}
                        </a>
                    </li>
                    <li *ngIf="allSupportAndBlog?.length >0 &&ShowSupportBlock >0">
                        <hr>
                    </li>
                    <ng-container *ngFor="let item of allSupportAndBlog">
                        <li *ngIf="item.show_in_header === '1'" class="li-hovered">
                            <ul [class]="Common.isRtl ? 'link-ar dropdown-header-support':'link-en dropdown-header-support'"
                                [routerLink]="'/pages/category-support/'+item.id+'/'+item.name.split(' ').join('-')">
                                <li style="width: 25px;"><img
                                        [src]="imgPath+item.img ? imgPath+item.img : 'assets/images/product/placeholder.jpg'"
                                        class="img-fluid" alt=" defaultImage" (error)="handleImageError($event.target)"
                                        width="40"></li>
                                <li>{{ item.name }}</li>
                            </ul>
                        </li>
                    </ng-container>
                    <li>
                        <hr>
                    </li>
                    <li class="li-hovered">
                        <a data-lng="es" href="javascript:void(0)" [class]="Common.isRtl ? 'link-ar':'link-en'"
                            (click)="userLogOut()">
                            <mat-icon>exit_to_app</mat-icon>

                            {{'logout' | translate}}
                        </a>
                    </li>

                </ul>

            </div>
        </li>

        <!-- <li class="onhover-div mobile-signup" *ngIf="!userLogedIn">
            <div class="text-center cursor_pointer" (click)="goTo('/pages/register') ">
                <img src="assets/images/icon/add-user.png" class="img-fluid" alt="settong">
                <p class="mt-1 mb-0 text-center">{{'register' | translate}}</p>
            </div>
        </li> -->
        <li class="onhover-div mobile-favorite">
            <div class="text-center cursor_pointer" (click)="goTo('/shop/wishlist') ">
                <img src="assets/images/icon/heart.png" class="img-fluid" alt="settong" width="25px" height="25px">
                <!-- <i class="ti-shopping-cart"></i> -->
                <p class="mt-1 mb-0 text-center">{{'Wishlist' | translate}}</p>
            </div>
        </li>

        <li class="onhover-div mobile-setting" *ngIf="false">
            <div class="text-center">
                <img src="assets/images/icon/setting.png" class="img-fluid" alt="settong">
                <!-- <i class="ti-shopping-cart"></i> -->
                <p class="mt-1 mb-0 text-center">{{'language' | translate }}</p>
            </div>
            <div class="show-div setting">
                <h6>{{'language' | translate }}</h6>
                <ul>
                    <li *ngFor="let language of languages">
                        <a href="javascript:void(0)" (click)="changeLanguage(language.code)">{{ language.name }}</a>
                    </li>
                </ul>
                <!-- <h6>currency</h6>
        <ul class="list-inline">
          <li *ngFor="let currency of currencies">
            <a href="javascript:void(0)" (click)="changeCurrency(currency)">{{ currency.name }}</a>
        </li>
        </ul> -->
            </div>
        </li>

        <li class="onhover-div mobile-cart">
            <a [routerLink]="['/shop/cart']">
                <div class="d-flex align-items-center flex-column" *ngIf='products?.length !== 0'>
                    <span class="cart-total">{{ (total_price - total_discount - total_quantity_discount
                        -total_coupon_discount - total_offer_discount)
                        | number }}
                        {{productService?.Currency.name}}</span>
                    <del class="d-block"
                        *ngIf="( (total_price) > ((total_price - total_discount - total_quantity_discount - total_coupon_discount - total_offer_discount)) )">
                        {{ (total_price | number) }}
                        {{productService?.Currency.name}}</del>
                </div>
                <div class="cart_image">
                    <img src="assets/images/icon/cart-white.png" class="img-fluid" alt="" width="25px" height="25px">
                </div>
                <span class="cart_qty_cls">{{ products?.length }}</span>
            </a>
            <div class="show-div shopping-cart show-cart-layout-block d-block">

                <div class="cart-block-products-list-layout">
                    <ul class="cart-block-products-emtpty-list" *ngIf='!products?.length'>
                        <h5>{{'Your Cart is Empty' | translate}} </h5>
                    </ul>

                    <ul class="" *ngIf='products?.length' class="cart-block-products-all-list">
                        <li *ngFor="let product of products">
                            <div class="media">
                                <a [routerLink]="['/shop/product/', product.product_id , !product.meta_url ? removeBrackets(product.product_name ? product.product_name:'').split(' ').join('-') : product.meta_url.split(' ').join('-') ]"
                                    *ngIf="product.images.length > 0 ">
                                    <img *ngIf="product.original_image && product.images[0]['type'] === 'image'"
                                        [src]="urlimage+product.original_image" [alt]="product?.product_name"
                                        (error)="handleImageError($event.target)">

                                    <img *ngIf="product.original_image && product.images[0]['type'] === 'video'"
                                        src="assets/images/video.png">

                                    <img *ngIf="!product.original_image" src="assets/images/product/placeholder.jpg"
                                        [alt]="product?.name">
                                </a>
                                <a
                                    [routerLink]="['/shop/product/', product.product_id  ,!product.meta_url ? removeBrackets(product.product_name ? product.product_name:'').split(' ').join('-') : product.meta_url.split(' ').join('-')]">

                                    <img *ngIf="product.images.length == 0" class="scale-2"
                                        src="assets/images/product/placeholder.jpg">


                                </a>
                                <div class="media-body">
                                    <a
                                        [routerLink]="['/shop/product/', product.product_id , !product.meta_url ? removeBrackets(product.product_name ? product.product_name:'').split(' ').join('-') : product.meta_url.split(' ').join('-')]">
                                        <h4 class="text-overflow-ellipsis">{{ product?.product_name }}</h4>
                                    </a>
                                </div>

                                <div class="quantity1">
                                    {{ product?.quantity }} x
                                </div>

                                <h4 class="price">
                                    {{ product?.price * productService?.Currency.price | discount:product }}
                                    {{productService?.Currency.name}}
                                </h4>

                            </div>
                            <div class="close-circle" (click)="removeItem(product )">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li class="total">
                            <h5> {{'Total' | translate}} : <span>{{ total_price - total_discount -
                                    total_quantity_discount -total_coupon_discount}}
                                    {{productService?.Currency.name}}</span></h5>
                        </li>

                    </ul>
                </div>

                <div class="footer_buttons">
                    <div class="buttons">
                        <a [routerLink]="['/shop/cart']" class="btn btn-solid view-cart">{{'view cart' | translate}}</a>
                        <button (click)="isLogin()" class="btn btn-solid checkout">{{'checkout' | translate}}</button>
                    </div>
                </div>

            </div>

        </li>
    </ul>
</div>

<!-- [ngStyle]="Common.isRtl?{'left':'0','right':'auto'}:{'right':'0','left':'auto'}" -->
<section #login class="full-sidebar" *ngIf="loginclicked">
    <div #loginFast class="shadow login-sidebar">
        <i class="ti-close login-icon" (click)="close()"></i>
        <app-fast-login (step5Reached)="onStep5Reached()"
            (stepSuccessReached)="onstepSuccessReached()"></app-fast-login>
    </div>
</section>