import { HttpBackend, HttpClient } from "@angular/common/http";
import { Inject, Injectable, Optional, PLATFORM_ID } from "@angular/core";
import * as bcrypt from "bcryptjs";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { REQUEST } from "@nguniversal/express-engine/tokens";
import { Request } from "express";
import { DataContact } from "../classes/data-contact";

@Injectable({
  providedIn: "root",
})
export class MasterApiService {
  public masterData: any;
  private hashedValPromise: Promise<any> | null = null;
  httpClient: HttpClient;
  masterApiUrl = "https://master-api.malyaan.net/web-api/settings.php?action=";

  // masterApiUrl =
  //   "https://master-api-develop.malyaan.net/web-api/settings.php?action=";

  // masterApiUrl =
  //   "http://192.168.1.60/malian/master-api/web-api/settings.php?action=";

  // masterApiUrl =
  //   "http://192.168.1.200/malyaan-dynamic-api/master-api/web-api/settings.php?action=";

  AppVersion = "web-1.0.23";
  constructor(
    private handler: HttpBackend,
    private http: HttpClient,
    @Optional() @Inject(REQUEST) private request: Request,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.httpClient = new HttpClient(handler);
  }

  initialize(): Promise<any> {
    return this.getHashedVal();
  }

  getHashedVal(): Promise<any> {
    let hostname: string;

    if (isPlatformBrowser(this.platformId)) {
      hostname = window.location.hostname;
    } else if (isPlatformServer(this.platformId)) {
      hostname = this.request.hostname;
    }
    if (!this.hashedValPromise) {
      let pass = bcrypt.hashSync("it-Plus-Secrit-Key-API-Malyaan");
      const body = {
        parse_host: hostname,
      };
      const headers = { "Secret-Api": pass };
      const options = { headers: headers };
      this.hashedValPromise = this.httpClient
        .post<any>(this.masterApiUrl + "gethashedVal", body, options)
        .toPromise()
        .then((res) => {
          this.masterData = res;
          return res;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
    return this.hashedValPromise;
  }

  public dataContactInfo = new BehaviorSubject<DataContact>({} as DataContact);

  getAllContact(settingsUrl): Promise<void> {
    return this.getAllContanctInfo(settingsUrl)
      .toPromise()
      .then((data) => {
        if (data) {
          this.updateDataContact(data.data);
          localStorage.setItem("contactinfo", JSON.stringify(data.data));
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getAllContanctInfo(settingsUrl): Observable<any> {
    const url = `${settingsUrl}getAllContanctInfo&nocache=${new Date().getTime()}`;
    return this.http.get<any>(url).pipe(
      catchError((err) => {
        return of(null);
      })
    );
  }
  GetDirectRequest(): Observable<any> {
    const hostName = this.masterData.site_url + "/web-api/";
    const settingsUrl = hostName + "settings.php?action=";
    return this.http.get<any>(settingsUrl + "GetDirectRequest");
  }

  updateDataContact(newData: DataContact) {
    this.dataContactInfo.next(newData);
  }

  getCurrentDataContact(): Observable<DataContact> {
    return this.dataContactInfo.asObservable();
  }
}
