import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  Renderer2,
  HostListener,
  PLATFORM_ID,
  Inject,
  TemplateRef,
  ViewEncapsulation,
  ChangeDetectorRef,
  NgZone,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { interval, Observable, Subscription } from "rxjs";
import { IPayPalConfig, ICreateOrderRequest } from "ngx-paypal";
import { environment } from "../../../environments/environment";
import { Product } from "../../shared/classes/product";
import { ProductService } from "../../shared/services/product.service";
import { OrderService } from "../../shared/services/order.service";
import { CommonService } from "../../shared/services/common.service";
import { UserService } from "../../shared/services/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, Meta, SafeHtml, Title } from "@angular/platform-browser";
import Swal from "sweetalert2";
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { isPlatformBrowser } from "@angular/common";
import { MatRadioButton } from "@angular/material/radio";
import { TranslateService } from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";
import { FatoorahPaymentService } from "src/app/shared/services/fatoorah-payment.service";
import { MasterApiService } from "src/app/shared/services/master-api.service";
import { policyList } from "src/app/shared/classes/privacyList";
import { ExtractedData } from "src/assets/interfaces/category-support";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit, OnDestroy {
  @ViewChildren("home") home: QueryList<ElementRef<any>>;
  @ViewChildren("store") store: QueryList<ElementRef<any>>;
  @ViewChild("mySelect") mySelect: ElementRef;
  @ViewChild("modal") modal: ElementRef;
  @ViewChild("btnInvoice") btnInvoice: ElementRef;
  @ViewChild("disableLayer") disableLayer: ElementRef;
  @ViewChild("layer") layer: ElementRef;
  @ViewChildren("panel") panels: QueryList<ElementRef<any>>;
  @ViewChildren("radioContainer") radioContainer: QueryList<ElementRef<any>>;
  @ViewChildren("PaymentContainer") paymentContainer: QueryList<
    ElementRef<any>
  >;
  @ViewChildren("btnCompany") btnCompanies!: QueryList<MatRadioButton>;
  @ViewChildren("btnpayment") btnPayments: QueryList<MatRadioButton>;
  @ViewChild("privacyTitle") privacyTitle!: ElementRef;
  @ViewChild("successOrder") successOrder: any;

  public checkoutForm: FormGroup;
  public products: Product[] = [];
  public cart_products: any = [];
  public cart_products_total_price = "";
  public cart_products_total_price_final: any;
  public countItems = "0";
  public totalDiscounts = "0";
  public product_items: any[] = [];
  public defaulty: string = "1225";

  public payPalConfig?: IPayPalConfig;
  public payment: string = "Stripe";
  public amount: any;
  public countries: any[] = [];
  public country_id = "";
  public country_name: any;
  public cities: any[] = [];
  public Districts: any[] = [];
  public myAddresess: any[] = [];
  public noMyAddresess: boolean = false;
  public myAddresessError: any = [];
  public addresessError: boolean = false;
  public quantatyMorThanStor: boolean = false;
  public city_id = "";
  public address = "";
  public savedaddress = "";
  public defaultAddress: any;
  public delivery_price = "";
  public addressIdFromCart = "";
  page_name = localStorage.getItem("front-lang") == "ar" ? "الدفع" : "Checkout";
  userLogedIn: boolean = false;
  deviceID: boolean = false;
  currentUser: any;
  user_img = "";
  existImg: boolean = false;
  user_full_name = "";
  usre_id: any;
  modalRef?: NgbModalRef;

  addInvoiceErrorResponce: string = "";
  public couponDiscount: any;
  cartId: any;
  marketingData: any;
  totalCartDiscount: any;
  loading = true;
  importApiImage: string;
  urlimage: string;
  contactInfoData: any;
  userInfo: any;
  homepanelOpenState: boolean = true;
  storepanelOpenState: boolean = false;
  allDoneMessage: any[] = [];
  inVoiceId: string = "";
  currentRoute: any;
  myColor: string = "";
  subscription: Subscription;
  constructorDone: boolean = false;
  FirstAddressId: number;
  checkLoading: boolean[] = [];
  checkCompleted: boolean[] = [];
  // paymentName: string[] = [];
  // paymentPrice: string[] = [];
  // paymentServicePrice: number[] = [];
  user_name: string = "";
  last_name: string = "";
  mobile_data: string = "";
  mobile2_data: string = "";
  successUser_name: boolean = false;
  // successLast_name: boolean = false;
  public errorUserNameMessage = "";
  public errorLastNameMessage = "";
  successMobile: boolean = false;
  successMobile2: boolean = false;
  errorMobileMessage = "";
  errorMobileMessage2 = "";
  successPassword: boolean = false;
  successconfirmPassword: boolean = false;
  errorPasswordMessage = "";
  errorconfirmPasswordMessage = "";
  phoneplaceholder: string = "";
  phone2placeholder: string = "";
  mobileLength: number = 0;
  keyLength: number = 0;
  valid_mobile: string = "";
  valid2_mobile: string = "";
  mobile_keys = [];
  mobilePattern = /^[0-9]+$/;
  mobileIsValid: boolean = false;
  mobile2IsValid: boolean = false;
  mobileIsInValid: boolean = false;
  mobile2IsInValid: boolean = false;
  country_code: any;
  image_mobile: string;
  _image_code: string;
  paymentLoading: boolean = true;
  @Input() errorOccurred = false;
  errorOccurredMessage: string;
  // ?modal variables====
  @ViewChild("newsletter", { static: false }) NewsLetterModal: TemplateRef<any>;
  @ViewChild("privacylet", { static: false }) privacyModal: TemplateRef<any>;
  @ViewChild("paymentlet", { static: false }) paymentModal: TemplateRef<any>;

  public openDashboard: boolean = false;

  step1: boolean = false;
  step2: boolean = false;
  step3: boolean = false;
  step4: boolean = false;
  step11: boolean = false;
  step22: boolean = false;
  step33: boolean = false;

  addStep: boolean = false;
  editStep: boolean = false;

  public countriesModal: any[] = [];
  public country_idModal = "";
  public country_nameModal: any;
  public myAddresessModal: any[] = [];
  public citiesModal: any[] = [];
  public city_idModal = "";
  public district_id = "";
  public title = "";
  public delete_address_id = "";
  public city_nameModal: any;
  public address_id = "";
  public city_name: string = "";
  public district_name: string = "";

  public closeResult: string;
  public modalOpen: boolean = false;
  is_default: string = "1";
  haveDefault: boolean = false;
  count_invoice: any;
  checkBtnLoader: boolean = false;
  // shippingMethodBlock: boolean[] = [];
  total_payment_price: number = 0;
  user_info: any = {};
  index: number = 0;
  paymentInfo: any[] = [];
  baseUrl: string = "";
  privacyDetails: policyList = {
    pages: [
      {
        id: "0",
      },
    ],
  };
  supportIcon: string;
  loginclicked: boolean = false;
  supportDetails: any;
  loaderSupport: boolean = true;
  noSupportContent: boolean;

  cartProducts: ExtractedData[] = [];
  cartExtractedData: ExtractedData[] = [
    {
      product_items: [] as { image: string; quantity: number; price: string }[],
      date: "",
      invoice_id: "",
      total_price: 0,
      message: "add",
    },
  ];

  currentUrl: string = "";
  hostName: string = "";

  constructor(
    private fb: FormBuilder,
    public user: UserService,
    public Common: CommonService,
    private router: Router,
    public productService: ProductService,
    private orderService: OrderService,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private host: ElementRef,
    private FatoorahPaymentService: FatoorahPaymentService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private MasterApiService: MasterApiService,
    private translate: TranslateService
  ) {
    this.hostName = this.Common.getDomainFromUrl();
    this.currentUrl = `https://${this.hostName}/shop/checkout`;
    // SET TITLE
    this.Common.setTitle(this.page_name);

    this.importApiImage = this.Common._ImageUrl + "importapi/";
    this.urlimage = this.Common._ImageUrl + "products/thumbs/";
    this._image_code = this.Common._imageUrlPlaces + "places/";
    this.supportIcon = this.Common._ImageUrl + "commitments-and-policies/";
    this.subscription = this.Common.UpdatedChecckOut.subscribe({
      next: (res) => {
        this.getCartItemsFromServer();
      },
    });
    // console.log(this.user);
    this.checkoutForm = this.fb.group({
      title: ["", [Validators.required, Validators.maxLength(255)]],
      country: ["", Validators.required],
      city: ["", Validators.required],
      District: ["", Validators.required],
      is_default: ["1", Validators.required],
      company: ["0", Validators.required],
    });
  }
  @ViewChild("styledButton") styledButton: ElementRef;

  ngOnInit(): void {
    this.baseUrl = window.location.origin;
    console.log("Base URL:", this.baseUrl);
    this.productService.cartItems.subscribe(
      (response) => (this.products = response)
    );
    this.contactInfoData =
      JSON.parse(localStorage.getItem("contactinfo")) || {};
    console.log("contactInfoData", this.contactInfoData);
    this.initConfig();
    this.getPlaces(0);
    //this.getAllAddress();
    this.checkOuth();
    //this.getCartItemsFromServer();
    // this.getAllContanctInfo();
    const rootStyles = getComputedStyle(document.documentElement);
    const themeDefault = rootStyles.getPropertyValue("--theme-default").trim();
    if (this.styledButton) {
      this.styledButton.nativeElement.style.color = themeDefault;
    }
  }

  ngAfterViewInit(): void {
    this.homepanelOpenState = true;
    this.storepanelOpenState = false;
    setTimeout(() => {
      this.getCommitmentsAndPoliciesByMode();
    }, 1500);
  }

  setInitialCheck() {
    const shippingContainersArray = this.radioContainer.toArray();
    const btnCompaniesArray = this.btnCompanies.toArray();
    const paymentContainersArray = this.paymentContainer.toArray();
    const btnPaymentsArray = this.btnPayments.toArray();
    this.cart_products.forEach((cartitem, cartIndex) => {
      const shippingIndices = this.getIndices(
        cartIndex,
        cartitem.selectedAddresse?.shipping_methods?.length
      );
      const paymentIndices = this.getIndices(
        cartIndex,
        this.paymentInfo.length
      );

      this.setContainerStyles(
        shippingContainersArray.slice(...shippingIndices),
        btnCompaniesArray.slice(...shippingIndices),
        cartitem.shippingIndex
      );

      this.setContainerStyles(
        paymentContainersArray.slice(...paymentIndices),
        btnPaymentsArray.slice(...paymentIndices),
        cartitem.paymentIndex
      );

      if (
        cartitem.selectedAddresse?.shipping_methods &&
        cartitem.selectedAddresse?.shipping_methods.length > 0
      ) {
        this.setDeliveryDate(
          cartitem.selectedAddresse?.shipping_methods[cartitem.shippingIndex],
          cartIndex
        );
      }

      cartitem.default_shipping =
        cartitem.selectedAddresse.shipping_methods[cartitem.shippingIndex]
          .default_shipping_method == "1";

      cartitem.shippingId = cartitem.shippingId
        ? cartitem.shippingId
        : cartitem.shipment_id;
      console.log("cart", cartitem);
    });
  }

  getIndices(cartIndex: number, length: number): [number, number] {
    const startIndex = cartIndex * length;
    const endIndex = startIndex + length;
    return [startIndex, endIndex];
  }

  setContainerStyles(containers: any[], buttons: any[], index: number) {
    if (containers.length > 0 && buttons.length > 0) {
      containers[
        index
      ].nativeElement.style.border = `2px solid ${this.myColor}`;
      buttons[index].checked = true;
    }
  }

  setDeliveryDate(method: any, index = 0) {
    const isInvalidFrom = this.Common.isInvalidDeliveryTime(
      method.delivery_time_from
    );
    const isInvalidTo = this.Common.isInvalidDeliveryTime(
      method.delivery_time_to
    );

    const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
    };

    if (isInvalidFrom && isInvalidTo) {
      this.cartExtractedData[index].date = "";
    } else if (!isInvalidFrom && !isInvalidTo) {
      const { start, end } = this.Common.calculateDeliveryDates(method);
      const formattedStartDate = start.toLocaleDateString(
        this.Common.isRtl ? "ar-EG-u-nu-latn" : "en-US",
        options
      );
      const formattedEndDate = end.toLocaleDateString(
        this.Common.isRtl ? "ar-EG-u-nu-latn" : "en-US",
        options
      );
      this.cartExtractedData[
        index
      ].date = `${formattedStartDate} - ${formattedEndDate}`;
    } else {
      this.cartExtractedData[index].date = this.calculateSingleDeliveryDate(
        method,
        isInvalidFrom,
        options
      );
    }
  }

  calculateSingleDeliveryDate(
    method: any,
    isInvalidFrom: boolean,
    options
  ): string {
    const now = new Date();
    let deliveryDate: Date;

    if (!isInvalidFrom) {
      deliveryDate = new Date(
        now.getTime() + method.delivery_time_from * 60 * 60 * 1000
      );
    } else {
      deliveryDate = new Date(
        now.getTime() + method.delivery_time_to * 60 * 60 * 1000
      );
    }
    const hourShippedOrders = method.hour_shipped_orders % 24;

    if (now.getHours() >= hourShippedOrders) {
      deliveryDate.setDate(deliveryDate.getDate() + 1);
    }

    const formatedDate = deliveryDate.toLocaleDateString(
      this.Common.isRtl ? "ar-EG-u-nu-latn" : "en-US",
      options
    );
    return formatedDate;
  }
  onCloseModal(modal: HTMLElement, layer: HTMLElement) {
    modal.style.bottom = "-150%";
    layer.style.display = "none";
  }
  openModalAddresses(modal: HTMLElement, layer: HTMLElement) {
    modal.style.bottom = "-10px";
    modal.style.overflow = "auto";
    layer.style.display = "block";
  }

  checkLength() {
    this.valid_mobile = this.formatMobileNumber(this.mobile_data);

    this.mobileIsValid = false;
    if (
      !this.startsWithAny(this.mobile_keys, this.valid_mobile) &&
      this.valid_mobile.length >= this.keyLength
    ) {
      this.errorOccurred = true;
      this.successMobile = true;
      this.mobileIsInValid = true;

      this.translateService
        .get("invalid mobile message")
        .subscribe((translatedMessage: any) => {
          this.errorOccurredMessage = translatedMessage;
          this.errorMobileMessage = translatedMessage;
        });
    } else {
      this.errorOccurred = false;
      this.successMobile = false;
      this.mobileIsInValid = false;
      if (this.valid_mobile.length == this.mobileLength) {
        this.mobileIsValid = true;
      } else if (this.valid_mobile.length > this.mobileLength) {
        this.mobileIsInValid = true;
        this.mobileIsValid = false;
      } else {
        this.mobileIsValid = false;
      }
      this.errorOccurredMessage = "";
      this.errorMobileMessage = "";
    }
  }

  checkLength2() {
    this.valid2_mobile = this.formatMobileNumber(this.mobile2_data);
    this.mobile2IsValid = false;
    console.log(
      !this.startsWithAny(this.mobile_keys, this.valid2_mobile),
      this.mobile_keys,
      this.valid2_mobile
    );
    if (
      !this.startsWithAny(this.mobile_keys, this.valid2_mobile) &&
      this.valid2_mobile.length >= this.keyLength
    ) {
      this.errorOccurred = true;
      this.successMobile2 = true;
      this.mobile2IsInValid = true;

      this.translateService
        .get("invalid mobile message")
        .subscribe((translatedMessage: any) => {
          this.errorOccurredMessage = translatedMessage;
          this.errorMobileMessage2 = translatedMessage;
        });
    } else {
      this.errorOccurred = false;
      this.successMobile2 = false;
      this.mobile2IsInValid = false;
      if (this.valid2_mobile.length == this.mobileLength) {
        this.mobile2IsValid = true;
      } else if (this.valid2_mobile.length > this.mobileLength) {
        this.mobile2IsInValid = true;
        this.mobile2IsValid = false;
      } else {
        this.mobile2IsValid = false;
      }
      this.errorOccurredMessage = "";
      this.errorMobileMessage2 = "";
    }
  }

  startsWithAny(array, number) {
    const numStr = number.toString();
    return array.some((element) => numStr.startsWith(element.toString()));
  }
  formatMobileNumber(mobileNumber) {
    if (mobileNumber.charAt(0) !== "0" && mobileNumber.length > 0) {
      mobileNumber = "0" + mobileNumber;
    }
    return mobileNumber;
  }
  handleDescriptionInnerHtml(description) {
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }
  getAllContanctInfo() {
    this.MasterApiService.getCurrentDataContact().subscribe(
      (dataContact) => {
        if (!dataContact) {
          console.error("Data contact information is undefined or null");
          return;
        }

        this.contactInfoData = dataContact;
        localStorage.setItem("contactinfo", JSON.stringify(dataContact));
        this.myColor = this.contactInfoData.front_color;
        this.country_idModal = dataContact.country_info.id;
        this.onChangeCountry(this.country_idModal);
        this.country_code = dataContact.country_info.country_code;
        this.image_mobile = this._image_code + dataContact.country_info.image;
        this.mobile_keys = dataContact.country_info.mobile_keys.split(",");
        this.mobileLength = parseInt(dataContact.country_info.mobile_length);
        this.keyLength = this.mobile_keys[0].length;
        const len = this.mobileLength - this.keyLength;
        this.phoneplaceholder = this.mobile_keys[0];
        for (let index = 0; index < len; index++) {
          this.phoneplaceholder += "x";
        }
        this.phone2placeholder = this.mobile_keys[0];
        for (let index = 0; index < len; index++) {
          this.phone2placeholder += "x";
        }
        localStorage.setItem(
          "paymentInfo",
          JSON.stringify(this.Common.getAllPaymentMethods(this.contactInfoData))
        );
        this.paymentInfo = this.Common.getAllPaymentMethods(
          this.contactInfoData
        )
          ? this.Common.getAllPaymentMethods(this.contactInfoData)
          : JSON.parse(localStorage.getItem("paymentInfo"));

        this.cart_products.forEach((ele, index) => {
          console.log("paymentInfo", this.paymentInfo);
          this.paymentInfo.forEach((info, index) => {
            console.log(info.key, ele.payment_method_type);

            if (info.key == ele.payment_method_type) {
              ele.paymentIndex = index;
              ele.paymentKey = info.key;
              ele.paymentName = info.name;
              ele.paymentPrice = info.price;
            }
          });
        });

        this.userInfo = JSON.parse(this.user.getCurrentUser());
      },
      (error) => {
        console.error("Error fetching contact info", error);
      }
    );
  }
  focusSelect(): void {
    this.renderer.selectRootElement(this.mySelect.nativeElement).focus();
  }
  removeBrackets(str: string): string {
    return str.replace(/[(\%)/]/g, "");
  }

  getCartItemsFromServer() {
    this.loading = true;
    this.Common.getCart().subscribe(async (data) => {
      this.products = [];
      if (data.data) {
        this.cart_products = data.data;
        console.log("cart", this.cart_products);
        this.cart_products.forEach((ele, index) => {
          if (!ele.total_offer_discount) {
            ele.total_offer_discount = 0;
          }

          if (ele.product_items.length > 0) {
            this.products.push(ele.product_items);
            ele.panelOpenState = false;
          }
          ele.shippingnNum = "my_address";
        });

        for (let index = 0; index < this.cart_products.length; index++) {
          this.cartExtractedData[index] = {
            product_items: [] as {
              image: string;
              quantity: number;
              price: string;
            }[],
            date: "",
            invoice_id: "",
            total_price: 0,
            message: "add",
          };
        }
        let validGroups = [];
        this.cart_products.forEach((ele) => {
          if (ele.cart_products_groups && ele.cart_products_groups.length > 0) {
            ele.cart_products_groups.forEach((group) => {
              if (group.total_price >= group.order_price) {
                validGroups.push(group);
                if (
                  ele.minDeliveryPrice == undefined ||
                  group.delivery_price < ele.minDeliveryPrice
                ) {
                  ele.minDeliveryPrice = parseFloat(group.delivery_price);
                }
              }
            });
          }

          if (ele.product_items && ele.product_items.length > 0) {
            ele.product_items.forEach((product) => {
              if (product.product_group_data) {
                const groupId = product.product_group_data.id;
                const isValidGroup = validGroups.some(
                  (group) => group.id === groupId
                );
                if (!isValidGroup) {
                  product.product_group_data = null;
                }
              }
            });
          }

          this.checkLoading.push(false);
          this.checkCompleted.push(false);
          this.myAddresessError.push(false);
          if (ele.address_id != null) {
            this.FirstAddressId = ele.address_id;
          }
          ele.paymentKey = "payment_method_home";
          ele.paymentName = this.contactInfoData.payment_method_home_name;
          ele.paymentPrice = this.contactInfoData.payment_method_home_price;
          ele.paymentServicePrice = ele.payment_method_price
            ? ele.payment_method_price
            : parseFloat(ele.paymentPrice);
          ele.note = ele.note ? ele.note : "";
          ele.deliveryPrice = 0;
          ele.shippingValue = 0;
          ele.shippingId = 0;
          ele.shippingCompany = "";
          ele.selectedAddresse = [];
          ele.shippingIndex = ele.shippingIndex > 0 ? ele.shippingIndex : 0;
          ele.paymentIndex = ele.paymentIndex > 0 ? ele.paymentIndex : 0;
          ele.shippingMethodBlock = true;
        });

        console.log("cart_products", this.cart_products);
        this.cart_products[0]["panelOpenState"] = true;
        this.cart_products[0]["homeOpenState"] = true;
        await this.getAllContanctInfo();
        await this.getAllAddress();
      } else {
        this.loading = false;
      }
    });
  }

  async calculateTotalPaymentPrice() {
    this.total_payment_price = 0;
    this.total_payment_price +=
      parseFloat(this.cart_products[this.index]["total_price"]) -
      parseFloat(this.cart_products[this.index]["total_coupon_discount"]) -
      parseFloat(this.cart_products[this.index]["total_offer_discount"]) -
      parseFloat(this.cart_products[this.index]["total_discount"]) +
      (this.cart_products[this.index].minDeliveryPrice != undefined &&
      this.cart_products[this.index].default_shipping
        ? parseFloat(this.cart_products[this.index].minDeliveryPrice)
        : parseFloat(this.cart_products[this.index].deliveryPrice)) -
      parseFloat(this.cart_products[this.index]["total_quantity_discount"]) +
      +this.cart_products[this.index].paymentServicePrice;
  }

  async getPaymentMethodsAfterCalculation() {
    this.paymentLoading = true;
    await this.calculateTotalPaymentPrice();
    this.getPaymentMethods(this.total_payment_price);
  }

  checkOuth() {
    if (this.user.getToken() !== null && this.user.getDeviceId() !== null) {
      this.userLogedIn = true;
      this.deviceID = true;
      this.currentUser = JSON.parse(this.user.getCurrentUser());
      this.usre_id = this.currentUser.id;
      this.user_img = this.currentUser.img;
      this.existImg =
        this.user_img != "" && this.user_img != null ? true : false;
      this.user_full_name = this.currentUser.user_name;
      this.user_name = this.currentUser.user_name;
      this.last_name = this.currentUser.last_name || "";
      this.mobile_data = this.currentUser.mobile;
      this.valid_mobile = this.formatMobileNumber(this.mobile_data);
    } else {
      this.userLogedIn = false;
      this.deviceID = false;
      this.currentUser = null;
      this.usre_id = "";
      this.existImg = false;
      this.user_img = "";
    }
  }

  async getAllAddress() {
    await this.user.getAllAddress().subscribe((data) => {
      console.log(data);

      if (data.success) {
        this.myAddresess = data.data;
        for (let i = 0; i < this.myAddresess.length; i++) {
          if (
            this.FirstAddressId == undefined &&
            this.myAddresess[i].is_default == "1"
          ) {
            this.FirstAddressId = this.myAddresess[i].id;
            this.haveDefault = true;
            this.cart_products.forEach((item) => {
              item.selectedAddresse = this.myAddresess[i];
            });

            break;
          } else if (this.FirstAddressId == this.myAddresess[i].id) {
            this.cart_products.forEach((item) => {
              item.selectedAddresse = this.myAddresess[i];
            });
            break;
          } else {
            this.cart_products.forEach((item) => {
              item.selectedAddresse = this.myAddresess[i];
            });
          }
        }
        console.log("cart_products", this.cart_products, this.FirstAddressId);

        this.cart_products.forEach((ele) => {
          ele.deliveryPrice =
            ele.selectedAddresse !== ""
              ? parseInt(ele.selectedAddresse.delivery_price)
              : 0;

          ele.shippingValue =
            ele.selectedAddresse !== ""
              ? ele.selectedAddresse.delivery_price
              : 0;

          ele.shippingId =
            ele.selectedAddresse !== "" ? ele.selectedAddresse.shipment_id : 0;

          ele.shippingCompany =
            ele.selectedAddresse !== ""
              ? ele.selectedAddresse.shipment_company
              : 0;
          ele.selectedAddresse
            ? ele.selectedAddresse.shipping_methods.forEach(
                (element, shippingIndex) => {
                  if (
                    parseInt(element.shipment_id) == parseInt(ele.shipment_id)
                  ) {
                    ele.shippingIndex = shippingIndex;
                    ele.deliveryPrice = parseFloat(element.delivery_price);
                    ele.shippingCompany = element.shipment_company;
                    ele.shippingId = element.shippingId;
                    ele.shippingValue = element.delivery_price;
                  }
                }
              )
            : (ele.shippingIndex = 0);

          if (ele.selectedAddresse) {
            ele.selectedAddresse.shipping_methods.forEach((method) => {
              const isInvalidFrom = this.Common.isInvalidDeliveryTime(
                method.delivery_time_from
              );
              const isInvalidTo = this.Common.isInvalidDeliveryTime(
                method.delivery_time_to
              );

              if (isInvalidFrom && isInvalidTo) {
                this.translate
                  .get("DELIVERY_TIME_NOT_SET")
                  .subscribe((translatedMessage) => {
                    method.message = translatedMessage;
                  });
              } else if (!isInvalidFrom && !isInvalidTo) {
                const { start, end } =
                  this.Common.calculateDeliveryDates(method);

                const options: Intl.DateTimeFormatOptions = {
                  month: "long",
                  day: "numeric",
                };

                const formattedStartDate = start.toLocaleDateString(
                  this.Common.isRtl ? "ar-EG-u-nu-latn" : "en-US",
                  options
                );
                const formattedEndDate = end.toLocaleDateString(
                  this.Common.isRtl ? "ar-EG-u-nu-latn" : "en-US",
                  options
                );

                this.translate
                  .get("DELIVERY_TIME_MESSAGE", {
                    start: formattedStartDate,
                    end: formattedEndDate,
                  })
                  .subscribe((translatedMessage) => {
                    method.message = translatedMessage;
                  });
              } else if (!isInvalidFrom || !isInvalidTo) {
                const now = new Date();
                let deliveryDate: Date;

                if (!isInvalidFrom) {
                  deliveryDate = new Date(
                    now.getTime() + method.delivery_time_from * 60 * 60 * 1000
                  );
                } else {
                  deliveryDate = new Date(
                    now.getTime() + method.delivery_time_to * 60 * 60 * 1000
                  );
                }
                const hourShippedOrders = method.hour_shipped_orders % 24;

                if (now.getHours() >= hourShippedOrders) {
                  deliveryDate.setDate(deliveryDate.getDate() + 1);
                }

                const options: Intl.DateTimeFormatOptions = {
                  // year: "numeric",
                  month: "long",
                  day: "numeric",
                };

                const formattedDate = deliveryDate.toLocaleDateString(
                  this.Common.isRtl ? "ar-EG-u-nu-latn" : "en-US",
                  options
                );

                this.translate
                  .get("DELIVERY_TIME_SINGLE", { time: formattedDate })
                  .subscribe((translatedMessage) => {
                    method.message = translatedMessage;
                  });
              }
            });
          }
        });
        this.loading = false;

        // doing this for edit to remove the shipping way
        if (this.cart_products[0].shipping_type == "shipping_from_store") {
          this.storeChecked(0);
        }
        this.route.queryParams.subscribe((params) => {
          const paymentId = params["paymentId"];
          if (paymentId) {
            this.addPaymentInvoice(
              this.btnInvoice,
              this.disableLayer,
              paymentId
            );
          }
        });

        this.cart_products.forEach((cart) => {
          cart.default_shipping =
            cart.selectedAddresse.shipping_methods[0].default_shipping_method ==
            "1";
        });
      } else {
        this.noMyAddresess = true;
        this.cart_products.forEach((item) => {
          item.selectedAddresse = "";
        });

        this.cart_products.forEach((ele) => {
          ele.deliveryPrice = 0;
        });

        this.loading = false;
      }
    });

    setTimeout(() => {
      this.setInitialCheck();
      this.cdr.detectChanges();
    }, 700);
  }

  getPlaces(parent_id) {
    this.Common.getPlaces(parent_id, this.city_id).subscribe((countries) => {
      if (countries.data) {
        this.countries = countries.data;
        this.countriesModal = countries.data;
      }
    });
  }

  onCityChange(value: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.district_id = "";
      console.log(this.checkoutForm.get("is_default"));

      if (value != "") {
        this.Common.getPlaces(this.country_idModal, value).subscribe((data) => {
          if (data.data) {
            this.Districts = data.data;
            console.log("Districts", this.Districts);
            resolve();
          } else {
            reject("No data in countries");
          }
        });

        for (let i = 0; i < this.countries.length; i++) {
          if (this.countries[i].id == value) {
            this.country_name = this.countries[i].pla_name;
          }
        }
      } else {
        resolve(); // Resolve immediately if value is empty
      }
    });
  }

  AddressTitle: any = "";
  onChangeAddress(value, i, modal: HTMLElement, layer: HTMLElement) {
    console.log(value);
    if (value !== "") {
      this.cart_products[i].selectedAddresse = this.myAddresess.filter(
        (ele) => ele.id == value
      )[0];
      this.myAddresessError[i] = false;
    } else {
      this.cart_products[i].selectedAddresse = null;
    }
    this.FirstAddressId = this.myAddresess.filter(
      (ele) => ele.id == value
    )[0].id;
    if (this.cart_products[i].selectedAddresse == null) {
      this.myAddresessError[i] = true;
    }
    this.cart_products[i].deliveryPrice = parseInt(
      this.cart_products[i].selectedAddresse.delivery_price
    );
    this.cart_products[i].shippingValue =
      this.cart_products[i].selectedAddresse.shipment_value;
    this.cart_products[i].shippingId =
      this.cart_products[i].selectedAddresse.shipment_id;
    this.cart_products[i].shippingCompany =
      this.cart_products[i].selectedAddresse.shipment_company;
    this.cart_products[i].shippingIndex = 0;
    setTimeout(() => {
      this.resetShipping(i);
      this.cdr.detectChanges();
    }, 100);
    this.onCloseModal(modal, layer);

    this.cart_products[i].selectedAddresse?.shipping_methods.forEach(
      (method) => {
        const isInvalidFrom = this.Common.isInvalidDeliveryTime(
          method.delivery_time_from
        );
        const isInvalidTo = this.Common.isInvalidDeliveryTime(
          method.delivery_time_to
        );

        if (isInvalidFrom && isInvalidTo) {
          this.translate
            .get("DELIVERY_TIME_NOT_SET")
            .subscribe((translatedMessage) => {
              method.message = translatedMessage;
            });
        } else if (!isInvalidFrom && !isInvalidTo) {
          const { start, end } = this.Common.calculateDeliveryDates(method);
          const options: Intl.DateTimeFormatOptions = {
            month: "long",
            day: "numeric",
          };

          const formattedStartDate = start.toLocaleDateString(
            this.Common.isRtl ? "ar-EG-u-nu-latn" : "en-US",
            options
          );
          const formattedEndDate = end.toLocaleDateString(
            this.Common.isRtl ? "ar-EG-u-nu-latn" : "en-US",
            options
          );

          this.translate
            .get("DELIVERY_TIME_MESSAGE", {
              start: formattedStartDate,
              end: formattedEndDate,
            })
            .subscribe((translatedMessage) => {
              method.message = translatedMessage;
            });
        } else if (!isInvalidFrom || !isInvalidTo) {
          const now = new Date();
          let deliveryDate: Date;

          if (!isInvalidFrom) {
            deliveryDate = new Date(
              now.getTime() + method.delivery_time_from * 60 * 60 * 1000
            );
          } else {
            deliveryDate = new Date(
              now.getTime() + method.delivery_time_to * 60 * 60 * 1000
            );
          }
          const hourShippedOrders = method.hour_shipped_orders % 24;

          if (now.getHours() >= hourShippedOrders) {
            deliveryDate.setDate(deliveryDate.getDate() + 1);
          }

          const options: Intl.DateTimeFormatOptions = {
            // year: "numeric",
            month: "long",
            day: "numeric",
          };

          const formattedDate = deliveryDate.toLocaleDateString(
            this.Common.isRtl ? "ar-EG-u-nu-latn" : "en-US",
            options
          );

          this.translate
            .get("DELIVERY_TIME_SINGLE", { time: formattedDate })
            .subscribe((translatedMessage) => {
              method.message = translatedMessage;
            });
        }
      }
    );
  }

  onChangeCountry(value) {
    if (value != "") {
      this.Common.getPlaces(value, this.city_id).subscribe((data) => {
        if (data.data) {
          this.cities = data.data;
          this.citiesModal = data.data;
        } else {
          //console.log"no data in countries");
        }
      });

      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].id == value) {
          this.country_name = this.countries[i].pla_name;
          this.delivery_price = this.countries[i].delivery_price;
        }
      }
    }
  }

  onChangeCity(value) {
    if (value != "") {
      for (let i = 0; i < this.cities.length; i++) {
        if (this.cities[i].id == value) {
          this.city_name = this.cities[i].pla_name;
          this.delivery_price = this.cities[i].delivery_price;
        }
      }
    }
  }

  async addPaymentInvoice(
    btnInvoice: ElementRef,
    disableLayer: ElementRef,
    invoiceId: string = ""
  ) {
    this.cart_products = JSON.parse(localStorage.getItem("cart_products"));
    let i = parseInt(localStorage.getItem("storeIndex"));
    if (this.cart_products[i].selectedAddresse == "") {
      this.myAddresessError[i] = true;
      Swal.fire({
        title: `<h2 class="text-center">${
          this.cart_products[i].details_store_external.name
            ? this.cart_products[i].details_store_external.name
            : " المخزن الرئيسي "
        }<h2>`,
        text:
          localStorage.getItem("front-lang") == "ar"
            ? "قم باضافة عنوان"
            : "add new address",
        icon: "error",
      });
      return;
    }
    if (
      !this.cart_products[i].selectedAddresse.user_name ||
      !this.cart_products[i].selectedAddresse.mobile ||
      !this.cart_products[i].selectedAddresse.district_name
    ) {
      window.scrollTo(0, 0);
      return;
    }
    this.cart_products.forEach((cart) => (cart.panelOpenState = false));
    this.myAddresessError.forEach((ele) => (ele = false));
    this.quantatyMorThanStor = false;

    window.scrollTo(0, 0);
    const cart = this.cart_products[i];

    if (this.userLogedIn == true) {
      if (this.products.length > 0) {
        this.product_items = [];

        cart.product_items.forEach((product) => {
          let obj = {
            product_id: product.product_id,
            quantity: product.quantity,
            product_type_name: product.product_type_name,
            offer_id: product.offer_id,
            coupon_value: product.coupon_value,
            is_gift: product.is_gift,
            api_product_id: product.api_product_id,
            product_group_data: product.product_group_data,
          };

          if (product.quantity > product["store_amounts_product"]) {
            this.quantatyMorThanStor = true;
            return;
          }
          this.product_items.push(obj);
        });
      }
      let body = {
        payment_method_type: cart.paymentKey,
        payment_method_name: cart.paymentName,
        payment_method_price: cart.paymentPrice,
        cart_id: cart.id,
        transaction_id: invoiceId ? parseInt(invoiceId) : 0,
        invoice_edit_id: cart["invoice_edit_id"],
        api_invoice_edit_id: cart["api_invoice_edit_id"],
        address_full_name:
          cart.shippingnNum == "my_address"
            ? this.cart_products[i].selectedAddresse["user_name"] &&
              this.cart_products[i].selectedAddresse["last_name"]
              ? this.cart_products[i].selectedAddresse["user_name"] +
                " " +
                this.cart_products[i].selectedAddresse["last_name"]
              : ""
            : "",
        mobile:
          cart.shippingnNum == "my_address"
            ? this.cart_products[i].selectedAddresse["mobile"]
              ? this.cart_products[i].selectedAddresse["mobile"]
              : ""
            : "",
        mobile_2:
          cart.shippingnNum == "my_address"
            ? this.cart_products[i].selectedAddresse["mobile_2"]
              ? this.cart_products[i].selectedAddresse["mobile_2"]
              : ""
            : "",
        country_id:
          cart.shippingnNum == "my_address"
            ? parseInt(this.cart_products[i].selectedAddresse.country_id)
            : 0,
        city_id:
          cart.shippingnNum == "my_address"
            ? parseInt(this.cart_products[i].selectedAddresse.city_id)
            : 0,
        city_name:
          cart.shippingnNum == "my_address"
            ? this.cart_products[i].selectedAddresse.city_name
            : "",
        district_id:
          cart.shippingnNum == "my_address"
            ? parseInt(this.cart_products[i].selectedAddresse.district_id)
            : 0,
        district_name:
          cart.shippingnNum == "my_address"
            ? this.cart_products[i].selectedAddresse.district_name
            : "",
        address:
          cart.shippingnNum == "my_address"
            ? this.cart_products[i].selectedAddresse.title
            : "",
        note: cart.note,
        discount: parseInt(cart["total_discount"]),
        offer_discount: parseInt(cart["total_offer_discount"]),
        coupon_discount: cart.total_coupon_discount,
        marketing_data: cart.marketing_data,
        delivery_price:
          cart.minDeliveryPrice !== undefined && cart.default_shipping
            ? cart.minDeliveryPrice
            : cart.shippingnNum == "my_address"
            ? parseInt(cart.deliveryPrice)
            : 0,
        show_free_delivery:
          cart.minDeliveryPrice !== undefined && cart.default_shipping
            ? "1"
            : "0",
        invoice_items: this.product_items,
        address_id:
          cart.shippingnNum == "my_address"
            ? parseInt(this.cart_products[i].selectedAddresse.id)
            : 0,
        shipping_type: cart.shippingnNum,
        api_id_to: cart.api_id_to,
        total_price_collected_from_customer:
          cart.shippingnNum == "my_address"
            ? cart.total_price_collected_from_customer
            : 0,
        drop_shipping_commission:
          cart.shippingnNum == "my_address"
            ? parseInt(cart.drop_shipping_commission)
            : 0,
        drop_shipping_commission_value:
          cart.shippingnNum == "my_address"
            ? parseInt(cart.drop_shipping_commission_value)
            : 0,
        profit: 0,
        shipment_value:
          cart.shippingnNum == "my_address" ? parseInt(cart.shippingValue) : 0,
        shipment_company:
          cart.shippingnNum == "my_address" ? cart.shippingCompany : "",
        shipment_id:
          cart.shippingnNum == "my_address" ? parseInt(cart.shippingId) : 0,
        use_credit: cart.use_credit,
        shipping_method:
          cart.api_id_to == "0"
            ? ""
            : cart.details_store_external.shipping_method,
        account_id:
          cart.api_id_to == "0" ? "" : cart.details_store_external.account_id,
        status: "pending",
        price_after_discount: (
          parseFloat(cart["total_price"]) -
          parseFloat(cart["total_discount"]) -
          parseFloat(cart["total_coupon_discount"]) -
          parseFloat(cart["total_quantity_discount"]) -
          parseFloat(cart["total_offer_discount"])
        ).toString(),
        total_quantity_discount: cart["total_quantity_discount"],
      };

      console.log("cart_products", this.cart_products);
      console.log("body", body);

      this.checkLoading[i] = true;
      this.checkBtnLoader = true;
      this.cartProducts = [];
      const data = await this.orderService
        .addInvoice(
          body,
          cart.api_id_to,
          cart.details_store_external.secret_key
        )
        .toPromise();
      if (data.success) {
        this.cartExtractedData[i].date = this.cart_products[i].date;

        this.cartExtractedData[i].total_price =
          cart["total_price"] -
          cart["total_coupon_discount"] -
          cart["total_offer_discount"] -
          cart["total_discount"] +
          (cart.minDeliveryPrice != undefined && cart.default_shipping
            ? cart.minDeliveryPrice
            : cart.deliveryPrice) -
          cart["total_quantity_discount"] +
          +cart.paymentServicePrice;
        console.log("invoiceRestrictions", this.cart_products[i].date);
        const res = await this.orderService
          .invoiceRestrictions(data.invoice_id)
          .toPromise();
        this.checkLoading[i] = false;
        this.checkCompleted.splice(i, 1);
        console.log("checkCompleted", this.checkCompleted);
        this.count_invoice = res.count_invoice_type;
        this.emptyCart("", data.invoice_id, cart.id, "request_is_done");
        this.cart_products.splice(i, 1);
        localStorage.setItem(
          "cart_products",
          JSON.stringify(this.cart_products)
        );

        if (this.cart_products.length == 0) {
          this.checkBtnLoader = false;
          this.router.navigate(["/shop/checkout/success"]);
        } else {
          this.checkBtnLoader = false;
          this.router.navigate(["/shop/checkout"]);
        }
        this.cartExtractedData[i].invoice_id = data.invoice_id;
        cart.product_items.forEach((productItem) => {
          this.cartExtractedData[i].product_items.push({
            image: productItem.original_image
              ? this.urlimage + productItem.original_image
              : "",
            quantity: productItem.quantity,
            price: productItem.price,
          });
        });

        this.cartProducts.push(this.cartExtractedData[i]);
        if (isPlatformBrowser(this.platformId)) {
          // For SSR
          this.modalService.open(this.successOrder, {
            size: "lg",
            ariaLabelledBy: "successOrder-Modal",
            centered: true,
            windowClass: "success-order-modal",
          });
        }
      }
    }
  }
  async addInvoice(btnInvoice: ElementRef, disableLayer: ElementRef) {
    for (let i = 0; i < this.cart_products.length; i++) {
      for (let j = 0; j < this.cart_products[i].product_items.length; j++) {
        if (
          this.cart_products[i].product_items[j]["quantity"] >
          this.cart_products[i].product_items[j]["store_amounts_product"]
        ) {
          if (localStorage.getItem("front-lang") == "en") {
            Swal.fire({
              html: `
  The selected quantity is not available for the product: 
  <span style="color: #dd0e1c;">
  ${this.cart_products[i].product_items[j]["product_name"]}</span>
  Please update the product quantity or remove it from the cart to continue and complete your order
  `,
              icon: "warning",
              confirmButtonColor: getComputedStyle(
                document.querySelector("app-root")
              ).getPropertyValue("--theme-default"),
              cancelButtonColor: getComputedStyle(
                document.querySelector("app-root")
              ).getPropertyValue("--theme-default2"),
            });
          } else {
            Swal.fire({
              html: `
                الكمية المحددة غير متوفرة للمنتج: 
                <span style="color: #dd0e1c;">
  ${this.cart_products[i].product_items[j]["product_name"]}</span>
  قم بتحديث كمية المنتج او احذفه من السلة للمتابعة و استكمال الطلب
  `,
              icon: "warning",
              confirmButtonColor: getComputedStyle(
                document.querySelector("app-root")
              ).getPropertyValue("--theme-default"),
              cancelButtonColor: getComputedStyle(
                document.querySelector("app-root")
              ).getPropertyValue("--theme-default2"),
            });
          }
          return;
        }

        if (
          this.cart_products[i].product_items[j]["active"] == 2 &&
          this.cart_products[i].product_items[j]["invoice_edit_id"] == "0"
        ) {
          if (localStorage.getItem("front-lang") == "en") {
            Swal.fire({
              html: `
              <span style="color: #dd0e1c;">
  ${this.cart_products[i].product_items[j]["product_name"]}</span>
   currently being inventoried so please delete it to complete the order!
  `,
              icon: "warning",
              confirmButtonColor: getComputedStyle(
                document.querySelector("app-root")
              ).getPropertyValue("--theme-default"),
              cancelButtonColor: getComputedStyle(
                document.querySelector("app-root")
              ).getPropertyValue("--theme-default2"),
            });
          } else {
            Swal.fire({
              html: `
                <span style="color: #dd0e1c;">
  ${this.cart_products[i].product_items[j]["product_name"]}</span>
                  جاري جرده لذلك قم بحذفه لاستكمال الطلب! 

  `,
              icon: "warning",
              confirmButtonColor: getComputedStyle(
                document.querySelector("app-root")
              ).getPropertyValue("--theme-default"),
              cancelButtonColor: getComputedStyle(
                document.querySelector("app-root")
              ).getPropertyValue("--theme-default2"),
            });
          }
          return;
        }

        if (this.cart_products[i].product_items[j]["active"] == 0) {
          if (localStorage.getItem("front-lang") == "en") {
            Swal.fire({
              html: `
              <span style="color: #dd0e1c;">
  ${this.cart_products[i].product_items[j]["product_name"]}</span>
  not available now!
  `,
              icon: "warning",
              confirmButtonColor: getComputedStyle(
                document.querySelector("app-root")
              ).getPropertyValue("--theme-default"),
              cancelButtonColor: getComputedStyle(
                document.querySelector("app-root")
              ).getPropertyValue("--theme-default2"),
            });
          } else {
            Swal.fire({
              html: `
                <span style="color: #dd0e1c;">
  ${this.cart_products[i].product_items[j]["product_name"]}</span>
غير متاح حاليا
  `,
              icon: "warning",
              confirmButtonColor: getComputedStyle(
                document.querySelector("app-root")
              ).getPropertyValue("--theme-default"),
              cancelButtonColor: getComputedStyle(
                document.querySelector("app-root")
              ).getPropertyValue("--theme-default2"),
            });
          }
          return;
        }
      }

      if (this.cart_products[i].selectedAddresse == "") {
        this.myAddresessError[i] = true;
        Swal.fire({
          title: `<h2 class="text-center">${
            this.cart_products[i].details_store_external.name
              ? this.cart_products[i].details_store_external.name
              : " المخزن الرئيسي "
          }<h2>`,
          text:
            localStorage.getItem("front-lang") == "ar"
              ? "قم باضافة عنوان"
              : "add new address",
          icon: "error",
        });
        return;
      }
      if (
        !this.cart_products[i].selectedAddresse.user_name ||
        !this.cart_products[i].selectedAddresse.mobile ||
        !this.cart_products[i].selectedAddresse.district_name
      ) {
        window.scrollTo(0, 0);
        return;
      }
    }

    this.renderer.setAttribute(btnInvoice, "disable", "true");
    this.renderer.removeClass(disableLayer, "d-none");

    this.myAddresessError.forEach((ele) => (ele = false));
    this.quantatyMorThanStor = false;

    window.scrollTo(0, 0);

    let done = 0;
    this.cart_products.forEach((cart) => (cart.panelOpenState = false));
    this.cartProducts = [];

    for (let i = 0; i < this.cart_products.length; i++) {
      const cart = this.cart_products[i];

      if (this.userLogedIn == true) {
        if (this.products.length > 0) {
          this.product_items = [];

          cart.product_items.forEach((product) => {
            let obj = {
              product_id: product.product_id,
              quantity: product.quantity,
              product_type_name: product.product_type_name,
              offer_id: product.offer_id,
              coupon_value: product.coupon_value,
              is_gift: product.is_gift,
              api_product_id: product.api_product_id,
              product_group_data: product.product_group_data,
            };

            if (product.quantity > product["store_amounts_product"]) {
              this.quantatyMorThanStor = true;
              return;
            }
            this.product_items.push(obj);
          });
        }

        console.log("cart", cart);
        let body = {
          payment_method_type: cart.paymentKey,
          payment_method_name: cart.paymentName,
          payment_method_price: cart.paymentPrice,
          cart_id: cart.id,
          transaction_id: "",
          invoice_edit_id: cart["invoice_edit_id"],
          api_invoice_edit_id: cart["api_invoice_edit_id"],
          address_full_name:
            cart.shippingnNum == "my_address"
              ? this.cart_products[i].selectedAddresse["user_name"] &&
                this.cart_products[i].selectedAddresse["last_name"]
                ? this.cart_products[i].selectedAddresse["user_name"] +
                  " " +
                  this.cart_products[i].selectedAddresse["last_name"]
                : ""
              : "",
          mobile:
            cart.shippingnNum == "my_address"
              ? this.cart_products[i].selectedAddresse["mobile"]
                ? this.cart_products[i].selectedAddresse["mobile"]
                : ""
              : "",
          mobile_2:
            cart.shippingnNum == "my_address"
              ? this.cart_products[i].selectedAddresse["mobile_2"]
                ? this.cart_products[i].selectedAddresse["mobile_2"]
                : ""
              : "",
          country_id:
            cart.shippingnNum == "my_address"
              ? parseInt(this.cart_products[i].selectedAddresse.country_id)
              : 0,
          city_id:
            cart.shippingnNum == "my_address"
              ? parseInt(this.cart_products[i].selectedAddresse.city_id)
              : 0,
          city_name:
            cart.shippingnNum == "my_address"
              ? this.cart_products[i].selectedAddresse.city_name
              : "",
          district_id:
            cart.shippingnNum == "my_address"
              ? parseInt(this.cart_products[i].selectedAddresse.district_id)
              : 0,
          district_name:
            cart.shippingnNum == "my_address"
              ? this.cart_products[i].selectedAddresse.district_name
              : "",

          address:
            cart.shippingnNum == "my_address"
              ? this.cart_products[i].selectedAddresse.title
              : "",
          note: cart.note,
          discount: parseInt(cart["total_discount"]),
          offer_discount: parseInt(cart["total_offer_discount"]),
          coupon_discount: cart.total_coupon_discount,
          marketing_data: cart.marketing_data,
          delivery_price:
            cart.minDeliveryPrice !== undefined && cart.default_shipping
              ? cart.minDeliveryPrice
              : cart.shippingnNum == "my_address"
              ? parseInt(cart.deliveryPrice)
              : 0,
          show_free_delivery:
            cart.minDeliveryPrice !== undefined && cart.default_shipping
              ? "1"
              : "0",
          invoice_items: this.product_items,
          address_id:
            cart.shippingnNum == "my_address"
              ? parseInt(this.cart_products[i].selectedAddresse.id)
              : 0,
          shipping_type: cart.shippingnNum,
          api_id_to: cart.api_id_to,
          total_price_collected_from_customer:
            cart.shippingnNum == "my_address"
              ? parseInt(cart.total_price_collected_from_customer)
              : 0,
          drop_shipping_commission:
            cart.shippingnNum == "my_address"
              ? parseInt(cart.drop_shipping_commission)
              : 0,
          drop_shipping_commission_value:
            cart.shippingnNum == "my_address"
              ? parseInt(cart.drop_shipping_commission_value)
              : 0,
          profit: 0,
          shipment_value:
            cart.shippingnNum == "my_address"
              ? parseInt(cart.shippingValue)
              : 0,
          shipment_company:
            cart.shippingnNum == "my_address" ? cart.shippingCompany : "",
          shipment_id:
            cart.shippingnNum == "my_address" ? parseInt(cart.shippingId) : 0,
          use_credit: cart.use_credit,
          shipping_method:
            cart.api_id_to == "0"
              ? ""
              : cart.details_store_external.shipping_method,
          account_id:
            cart.api_id_to == "0" ? "" : cart.details_store_external.account_id,
          status: "pending",
          price_after_discount: (
            parseFloat(cart["total_price"]) -
            parseFloat(cart["total_discount"]) -
            parseFloat(cart["total_coupon_discount"]) -
            parseFloat(cart["total_offer_discount"]) -
            parseFloat(cart["total_quantity_discount"])
          ).toString(),
          total_quantity_discount: cart["total_quantity_discount"],
        };

        console.log("body", body);

        this.checkLoading[i] = true;
        this.checkBtnLoader = true;
        const data = await this.orderService
          .addInvoice(
            body,
            cart.api_id_to,
            cart.details_store_external.secret_key
          )
          .toPromise();
        if (data.success) {
          const res = await this.orderService
            .invoiceRestrictions(data.invoice_id)
            .toPromise();
          this.checkLoading[i] = false;
          this.checkCompleted[i] = true;
          done++;
          this.count_invoice = res.count_invoice_type;

          this.cartExtractedData[i].invoice_id = data.invoice_id;
          this.cartExtractedData[i].total_price =
            cart["total_price"] -
            cart["total_coupon_discount"] -
            cart["total_offer_discount"] -
            cart["total_discount"] +
            (cart.minDeliveryPrice != undefined && cart.default_shipping
              ? cart.minDeliveryPrice
              : cart.deliveryPrice) -
            cart["total_quantity_discount"] +
            +cart.paymentServicePrice;

          cart.product_items.forEach((productItem) => {
            this.cartExtractedData[i].product_items.push({
              image: productItem.original_image
                ? this.urlimage + productItem.original_image
                : "",
              quantity: productItem.quantity,
              price: productItem.price,
            });
          });
          if (cart["invoice_edit_id"] != "0") {
            console.log("invoice EDITED");
            this.emptyCart(
              cart["invoice_edit_id"],
              "",
              cart.id,
              "request_is_done"
            );
            this.cartExtractedData[i].message = "edit";
            this.cartProducts.push(this.cartExtractedData[i]);
            if (
              isPlatformBrowser(this.platformId) &&
              i == this.cart_products.length - 1
            ) {
              // For SSR
              this.modalService.open(this.successOrder, {
                size: "lg",
                ariaLabelledBy: "successOrder-Modal",
                centered: true,
                windowClass: "success-order-modal",
              });
            }
            this.checkBtnLoader = false;
            this.router.navigate([""]);
          } else {
            this.emptyCart("", data.invoice_id, cart.id, "request_is_done");
            if (done == this.cart_products.length) {
              this.checkBtnLoader = false;
              this.router.navigate(["/shop/checkout/success"]);
            }
            this.cartProducts.push(this.cartExtractedData[i]);
            console.log("cartProducts", this.cartProducts);
            if (
              isPlatformBrowser(this.platformId) &&
              i == this.cart_products.length - 1
            ) {
              // For SSR
              this.modalService.open(this.successOrder, {
                size: "lg",
                ariaLabelledBy: "successOrder-Modal",
                centered: true,
                windowClass: "success-order-modal",
              });
            }
          }
        } else {
          this.checkLoading[i] = false;
          this.checkCompleted[i] = true;
          if (
            data.message ==
            "TheOrderHasBeenExecutedDoYouWantToAddANewOrderOrCancel"
          ) {
            this.addInvoiceErrorResponce =
              this.Common._lang_code == "ar"
                ? "لقد تم تنفيذ الطلب. هل تريد إضافة طلب جديد أم إلفاء تعديل الطلب؟"
                : "The Order Has Been Executed Do You Want To Add A New Order Or Cancel?";
            Swal.fire({
              text: (this.addInvoiceErrorResponce =
                this.addInvoiceErrorResponce),
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: getComputedStyle(
                document.querySelector("app-root")
              ).getPropertyValue("--theme-default"),
              cancelButtonColor: getComputedStyle(
                document.querySelector("app-root")
              ).getPropertyValue("--theme-default2"),
              // cancelButtonColor: "var(--theme-default)",
              confirmButtonText: "أضف طلب جديد",
              cancelButtonText: "إلغاء التعديل",
            }).then((result) => {
              if (result.isConfirmed) {
                console.log("cart", cart);
                let body = {
                  payment_method_type: cart.paymentKey,
                  payment_method_name: cart.paymentName,
                  payment_method_price: cart.paymentPrice,
                  city_id: cart.selectedAddresse.city_id,
                  city_name: cart.selectedAddresse.city_name,
                  district_id: cart.selectedAddresse.district_id,
                  district_name: cart.selectedAddresse.district_name,
                  address: cart.selectedAddresse,
                  note: cart.note,
                  delivery_price:
                    cart.minDeliveryPrice !== undefined && cart.default_shipping
                      ? cart.minDeliveryPrice
                      : cart.deliveryPrice,
                  invoice_items: this.product_items,
                  cart_id: cart.id,
                  marketing_data: this.marketingData,
                  coupon_discount: this.couponDiscount,
                  discount: this.totalDiscounts,
                  shipment_id: cart.shippingId,
                  shipment_value: cart.shippingValue,
                  shipment_company: cart.shippingCompany,
                };
                this.orderService
                  .addInvoice(
                    body,
                    cart.api_id_to,
                    cart.details_store_external.secret_key
                  )
                  .subscribe((data) => {
                    if (data.success) {
                      this.emptyCart(
                        "",
                        data.invoice_id,
                        cart.id,
                        "request_is_done"
                      );
                      this.resetData();
                      this.router.navigate([
                        "/shop/checkout/success/" + data.invoice_id,
                      ]);
                    } else {
                      this.addInvoiceErrorResponce = data.message;
                      console.log("addInvoice in server again NO");
                      Swal.fire({
                        text: this.addInvoiceErrorResponce,
                      });
                    }
                  });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                this.Common.emptyCart(
                  cart["invoice_edit_id"],
                  "",
                  cart.id,
                  "canceled"
                ).subscribe((data) => {
                  if (data.success) {
                    Swal.fire({
                      text: data.data,
                    });
                    this.router.navigate(["/"]);
                  } else {
                    //console.log"errors  in server", data.errors);
                    return;
                  }
                });
              }
            });
            this.addInvoiceErrorResponce = data.message;
            this.checkLoading[i] = false;
            this.checkBtnLoader = false;
            this.checkCompleted[i] = true;
          } else {
            this.route.queryParams.subscribe((params) => {
              const paymentId = params["paymentId"];
              if (!paymentId) {
                this.renderer.setAttribute(btnInvoice, "disable", "false");
                this.renderer.addClass(disableLayer, "d-none");
              }
            });
            this.addInvoiceErrorResponce = data.message;
            this.checkBtnLoader = false;
            console.log("data", data);
            Swal.fire({
              title: `<h2 class="text-center">${this.addInvoiceErrorResponce}<h2>`,
              text: data.errors.invoice_items || data.errors,
              icon: "error",
            });
            this.router.navigate(["/"]);
            break;
          }
        }
      }
    }
  }

  selectComapny(radioContainer, btnCompany, company, i, index) {
    this.onChangeCompany(
      btnCompany.value,
      i,
      company,
      radioContainer,
      btnCompany,
      index
    );
  }
  onChangeCompany(value, i, company, radioContainer, btnCompany, index) {
    this.radioContainer.forEach((ele) => {
      ele.nativeElement.style.border = `1px solid #dddddd`;
    });
    radioContainer.style.border = `2px solid ${this.myColor}`;
    btnCompany.checked = true;
    console.log("company", company, this.cart_products[i]);
    this.cart_products[i].deliveryPrice = parseInt(company.delivery_price);
    this.cart_products[i].shippingValue = company.shipment_value;
    this.cart_products[i].shippingId = company.shipment_id;
    this.cart_products[i].shippingCompany = company.shipment_company;
    this.cart_products[i].shippingIndex = index;
    this.setDeliveryDate(
      this.cart_products[i].selectedAddresse.shipping_methods[index],
      i
    );

    this.cart_products[i].default_shipping =
      this.cart_products[i].selectedAddresse.shipping_methods[index]
        .default_shipping_method == "1";
  }

  selectPayment(
    paymentContainer,
    i,
    btnPayment,
    paymentKey,
    paymentName,
    paymentPrice,
    index
  ) {
    this.onChangePayment(
      paymentKey,
      paymentName,
      i,
      paymentPrice,
      paymentContainer,
      btnPayment,
      index
    );
  }
  onChangePayment(
    paymentKey,
    paymentName,
    i,
    paymentPrice,
    paymentContainer,
    btnPayment,
    index
  ) {
    this.paymentContainer.forEach((ele) => {
      ele.nativeElement.style.border = `1px solid #dddddd`;
    });
    paymentContainer.style.border = `2px solid ${this.myColor}`;
    btnPayment.checked = true;
    this.cart_products[i].paymentKey = paymentKey;
    this.cart_products[i].paymentName = paymentName;
    this.cart_products[i].paymentPrice = paymentPrice;
    this.cart_products[i].paymentServicePrice = parseInt(
      this.cart_products[i].paymentPrice
    );
    this.cart_products[i].paymentIndex = index;
    console.log(index);
  }
  resetData() {
    this.countries = [];
    this.country_id = "";
    this.country_name = "";
    this.cities = [];
    this.city_id = "";
    this.city_name = "";
    this.district_id = "";
    this.district_name = "";
    this.address = "";
    // this.note = "";
    this.delivery_price = "";
    this.product_items = [];
    localStorage.setItem(
      "cartItems",
      JSON.stringify([])
    ); /* maybe Error Here */
    // reset basket
  }

  emptyCart(invoiceEditId: any, invoiceId: any, cartId: any, type: any) {
    this.Common.emptyCart(invoiceEditId, invoiceId, cartId, type).subscribe(
      (data) => {
        // console.log(data);
        if (data.data) {
          //this.Common.changeCart("");
          this.Common.UpdatedSetting.next("checked");
          this.Common.Updatedheaderone.next("checked");
          //console.log"emptyCart in server");
        } else {
          //console.log"errors  in server", data.errors);
          return;
        }
      }
    );
  }

  public get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }
  handleImageError(e) {
    e.src = "assets/images/product/placeholder2.jpg";
  }
  // Stripe Payment Gateway
  stripeCheckout() {
    var handler = (<any>window).StripeCheckout.configure({
      key: environment.stripe_token, // publishble key
      locale: "auto",
      token: (token: any) => {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        this.orderService.createOrder(
          this.products,
          this.checkoutForm.value,
          token.id,
          this.amount
        );
      },
    });
    handler.open({
      name: "Multikart",
      description: "Online Fashion Store",
      amount: this.amount * 100,
    });
  }

  // Paypal Payment Gateway
  private initConfig(): void {
    this.payPalConfig = {
      currency: this.productService.Currency?.currency,
      clientId: environment.paypal_token,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: this.productService.Currency.currency,
                value: this.amount,
                breakdown: {
                  item_total: {
                    currency_code: this.productService.Currency.currency,
                    value: this.amount,
                  },
                },
              },
            },
          ],
        },
      advanced: {
        commit: "true",
      },
      style: {
        label: "paypal",
        size: "small", // small | medium | large | responsive
        shape: "rect", // pill | rect
      },
      onApprove: (data, actions) => {
        this.orderService.createOrder(
          this.products,
          this.checkoutForm.value,
          data.orderID,
          this.getTotal
        );
        // console.log(
        //   "onApprove - transaction was approved, but not authorized",
        //   data,
        //   actions
        // );
        actions.order.get().then((details) => {
          // console.log(
          //   "onApprove - you can get full order details inside onApprove: ",
          //   details
          // );
        });
      },
      onClientAuthorization: (data) => {
        // console.log(
        //   "onClientAuthorization - you should probably inform your server about completed transaction at this point",
        //   data
        // );
      },
      onCancel: (data, actions) => {
        // console.log("OnCancel", data, actions);
      },
      onError: (err) => {
        // console.log("OnError", err);
      },
      onClick: (data, actions) => {
        // console.log("onClick", data, actions);
      },
    };
  }

  homeExpandeed: boolean = true;
  storeExpanded: boolean = false;
  homeChecked(i) {
    this.cart_products[i].shippingMethodBlock = true;
    let homeInput = this.home?.filter(
      (ele) => ele?.nativeElement.getAttribute("data-custom-index") == i
    )[0];
    let storeInput = this.store?.filter(
      (ele) => ele?.nativeElement.getAttribute("data-custom-index") == i
    )[0];
    this.cart_products[i].shippingnNum = "my_address";
    if (storeInput) {
      storeInput.nativeElement.checked = false;
    }
    if (homeInput) {
      homeInput.nativeElement.checked = true;
    }
    this.homeExpandeed = true;
    this.storeExpanded = false;
    setTimeout(() => {
      this.resetShipping(i);
    }, 100);
  }
  storeChecked(i) {
    this.cart_products[i].shippingMethodBlock = false;
    let homeInput = this.home.filter(
      (ele) => ele.nativeElement.getAttribute("data-custom-index") == i
    )[0];
    let storeInput = this.store.filter(
      (ele) => ele.nativeElement.getAttribute("data-custom-index") == i
    )[0];
    this.cart_products[i].shippingnNum = "shipping_from_store";
    if (storeInput) {
      storeInput.nativeElement.checked = true;
    }
    if (homeInput) {
      homeInput.nativeElement.checked = false;
    }
    this.homeExpandeed = false;
    this.storeExpanded = true;
    let cartitem = this.cart_products[i];
    cartitem.deliveryPrice = 0;
    console.log(this.cart_products);
  }
  preventToggle(e: MouseEvent) {
    e.preventDefault();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.timerSubscriptions.length > 0 && this.timerSubscriptions) {
      this.timerSubscriptions.forEach((subscription) =>
        subscription.unsubscribe()
      );
    }
  }

  async openModal(index: number, status: string) {
    this.resetForm();
    this.successUser_name = false;
    // this.successLast_name = false;
    this.successPassword = false;
    this.successconfirmPassword = false;
    this.successMobile = false;
    this.mobileIsInValid = false;
    this.mobileIsValid = false;
    this.successMobile2 = false;
    this.mobile2IsInValid = false;
    this.mobile2IsValid = false;
    this.step22 = false;
    this.step33 = false;
    this.step11 = false;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;

    this.addStep = false;
    this.editStep = false;
    if (status == "add") {
      this.step1 = true;
      this.step4 = false;
    }
    this.is_default = "1";
    if (status == "edit") {
      this.city_idModal = this.myAddresess[index].city_id;
      await this.onCityChange(this.city_idModal);
      this.step1 = false;
      this.step4 = true;
      // console.log("address", this.myAddresess[index]);

      this.district_id =
        this.myAddresess[index].district_id == "0"
          ? ""
          : this.myAddresess[index].district_id;
      this.district_name = this.myAddresess[index].district_name || "";
      this.city_name = this.myAddresess[index].city_name;
      this.address_id = this.myAddresess[index].id;
      this.title = this.myAddresess[index].title;
      this.user_name = this.myAddresess[index].user_name;
      this.last_name = this.myAddresess[index].last_name;
      this.mobile_data = this.myAddresess[index].mobile;
      this.mobile2_data = this.myAddresess[index].mobile_2;
      this.is_default = this.myAddresess[index].is_default;
      console.log("dist", this.district_id);

      if (this.mobile2_data) {
        this.valid2_mobile = this.formatMobileNumber(this.mobile2_data);
      } else {
        this.valid2_mobile = "";
      }
      if (this.mobile_data) {
        this.valid_mobile = this.formatMobileNumber(this.mobile_data);
      } else {
        this.valid_mobile = "";
      }
    } else {
      this.title = "";
      this.city_idModal = "";
      this.district_id = "";
      this.city_name = "";
      this.district_name = "";
      this.user_name = this.currentUser.user_name;
      this.last_name = this.currentUser.last_name || "";
      this.mobile_data = this.currentUser.mobile;
      this.mobile2_data = "";
      if (this.mobile_data) {
        this.valid_mobile = this.formatMobileNumber(this.mobile_data);
      } else {
        this.valid_mobile = "";
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.NewsLetterModal, {
          // data:,
          size: "md",
          ariaLabelledBy: "NewsLetter-Modal",
          centered: true,
          windowClass: "theme-modal newsletterm NewsLetterModal",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }
  resetForm() {
    this.checkoutForm.controls["title"].reset();
    this.checkoutForm.controls["title"].setErrors(null);
    this.checkoutForm.controls["title"].markAsUntouched();
    this.checkoutForm.controls["city"].reset();
    this.checkoutForm.controls["city"].setErrors(null);
    this.checkoutForm.controls["city"].markAsUntouched();
    this.checkoutForm.controls["District"].reset();
    this.checkoutForm.controls["District"].setErrors(null);
    this.checkoutForm.controls["District"].markAsUntouched();
  }

  openPaymentModal(index) {
    for (let j = 0; j < this.cart_products[index].product_items.length; j++) {
      if (
        this.cart_products[index].product_items[j]["quantity"] >
        this.cart_products[index].product_items[j]["store_amounts_product"]
      ) {
        if (localStorage.getItem("front-lang") == "en") {
          Swal.fire({
            html: `
  The selected quantity is not available for the product: 
  <span style="color: #dd0e1c;">
  ${this.cart_products[index].product_items[j]["product_name"]}</span>
  Please update the product quantity or remove it from the cart to continue and complete your order
  `,
            icon: "warning",
            confirmButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default"),
            cancelButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default2"),
          });
        } else {
          Swal.fire({
            html: `
                الكمية المحددة غير متوفرة للمنتج: 
                <span style="color: #dd0e1c;">
  ${this.cart_products[index].product_items[j]["product_name"]}</span>
  قم بتحديث كمية المنتج او احذفه من السلة للمتابعة و استكمال الطلب
  `,
            icon: "warning",
            confirmButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default"),
            cancelButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default2"),
          });
        }
        setTimeout(() => {
          this.resetPayment(index);
          this.cdr.detectChanges();
        }, 100);
        window.scrollTo(0, 0);
        return;
      }

      if (
        this.cart_products[index].product_items[j]["active"] == 2 &&
        this.cart_products[index].product_items[j]["invoice_edit_id"] == "0"
      ) {
        if (localStorage.getItem("front-lang") == "en") {
          Swal.fire({
            html: `
              <span style="color: #dd0e1c;">
  ${this.cart_products[index].product_items[j]["product_name"]}</span>
   currently being inventoried so please delete it to complete the order!
  `,
            icon: "warning",
            confirmButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default"),
            cancelButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default2"),
          });
        } else {
          Swal.fire({
            html: `
                <span style="color: #dd0e1c;">
                ${this.cart_products[index].product_items[j]["product_name"]}</span>
                  جاري جرده لذلك قم بحذفه لاستكمال الطلب! 

                `,
            icon: "warning",
            confirmButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default"),
            cancelButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default2"),
          });
        }
        setTimeout(() => {
          this.resetPayment(index);
          this.cdr.detectChanges();
        }, 100);
        window.scrollTo(0, 0);
        return;
      }

      if (this.cart_products[index].product_items[j]["active"] == 0) {
        if (localStorage.getItem("front-lang") == "en") {
          Swal.fire({
            html: `
              <span style="color: #dd0e1c;">
              ${this.cart_products[index].product_items[j]["product_name"]}</span>
              not available now!
              `,
            icon: "warning",
            confirmButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default"),
            cancelButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default2"),
          });
        } else {
          Swal.fire({
            html: `
                <span style="color: #dd0e1c;">
                  ${this.cart_products[index].product_items[j]["product_name"]}
                </span>
                غير متاح حاليا
                  `,
            icon: "warning",
            confirmButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default"),
            cancelButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default2"),
          });
        }
        return;
      }
    }
    this.index = index;
    localStorage.setItem("storeIndex", index);

    if (this.cart_products[index].selectedAddresse == "") {
      this.myAddresessError[index] = true;
      Swal.fire({
        title: `<h2 class="text-center">${
          this.cart_products[index].details_store_external.name
            ? this.cart_products[index].details_store_external.name
            : " المخزن الرئيسي "
        }<h2>`,
        text:
          localStorage.getItem("front-lang") == "ar"
            ? "قم باضافة عنوان"
            : "add new address",
        icon: "error",
      });
      setTimeout(() => {
        this.resetPayment(index);
        this.cdr.detectChanges();
      }, 100);
      return;
    }
    if (
      !this.cart_products[index].selectedAddresse.user_name ||
      !this.cart_products[index].selectedAddresse.mobile
    ) {
      setTimeout(() => {
        this.resetPayment(index);
        this.cdr.detectChanges();
      }, 100);
      window.scrollTo(0, 0);
      return;
    }
    this.cart_products[index].date = this.cartExtractedData[index].date;

    localStorage.setItem("cart_products", JSON.stringify(this.cart_products));
    this.getPaymentMethodsAfterCalculation();
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.paymentModal, {
          size: "md",
          ariaLabelledBy: "payment-Modal",
          centered: true,
          windowClass: "theme-modal newsletterm paymentModal",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }
  resetPayment(cartIndex: number) {
    const paymentContainersArray = this.paymentContainer.toArray();
    const btnPaymentsArray = this.btnPayments.toArray();

    const cartItem = this.cart_products[cartIndex];
    const startPaymentIndex = cartIndex * this.paymentInfo.length;
    const endPaymentIndex = startPaymentIndex + this.paymentInfo.length;

    const cartItemPaymentContainers = paymentContainersArray.slice(
      startPaymentIndex,
      endPaymentIndex
    );

    const cartItemBtnPayments = btnPaymentsArray.slice(
      startPaymentIndex,
      endPaymentIndex
    );

    if (
      cartItemPaymentContainers.length > 0 &&
      cartItemBtnPayments.length > 0
    ) {
      cartItemPaymentContainers.forEach((paymentContainer) => {
        paymentContainer.nativeElement.style.border = `1px solid #dddddd`;
      });

      cartItemBtnPayments.forEach((btnPayment) => {
        btnPayment.checked = false;
      });

      cartItemPaymentContainers[0].nativeElement.style.border = `2px solid ${this.myColor}`;
      cartItemBtnPayments[0].checked = true;
      cartItem.paymentKey = "payment_method_home";
      cartItem.paymentName = this.contactInfoData.payment_method_home_name;
      cartItem.paymentPrice = this.contactInfoData.payment_method_home_price;
      cartItem.paymentServicePrice = parseFloat(cartItem.paymentPrice);
      console.log(cartItemBtnPayments[0]);
    }
  }
  resetShipping(cartIndex: number) {
    const shippingContainersArray = this.radioContainer.toArray();
    const btnCompaniesArray = this.btnCompanies.toArray();
    const totalShippingMethodsLength =
      this.calculateTotalShippingMethodsLength(cartIndex);

    const startShippingIndex = totalShippingMethodsLength;
    const cartitem = this.cart_products[cartIndex];
    const endShippingIndex =
      startShippingIndex +
      (cartitem.selectedAddresse?.shipping_methods.length || 0);

    const cartItemCompanyContainers = shippingContainersArray.slice(
      startShippingIndex,
      endShippingIndex
    );
    const cartItemBtnCompanies = btnCompaniesArray.slice(
      startShippingIndex,
      endShippingIndex
    );

    if (
      cartItemCompanyContainers.length > 0 &&
      cartItemBtnCompanies.length > 0
    ) {
      this.resetAllShippingMethods(
        cartItemCompanyContainers,
        cartItemBtnCompanies
      );
      this.setShippingMethod(
        cartItemCompanyContainers,
        cartItemBtnCompanies,
        cartitem,
        cartIndex
      );
    }
    this.cart_products[cartIndex].default_shipping =
      this.cart_products[cartIndex].selectedAddresse.shipping_methods[0]
        .default_shipping_method == "1";
  }

  calculateTotalShippingMethodsLength(cartIndex: number): number {
    let totalShippingMethodsLength = 0;
    for (let i = 0; i < cartIndex; i++) {
      const cartItem = this.cart_products[i];
      if (cartItem.selectedAddresse?.shipping_methods) {
        totalShippingMethodsLength +=
          cartItem.selectedAddresse.shipping_methods.length;
      }
    }
    return totalShippingMethodsLength;
  }

  resetAllShippingMethods(containers: any[], buttons: any[]) {
    buttons.forEach((btn) => (btn.checked = false));
    containers.forEach(
      (container) =>
        (container.nativeElement.style.border = `1px solid #dddddd`)
    );
  }

  setShippingMethod(
    containers: any[],
    buttons: any[],
    cartitem: any,
    index: number
  ) {
    containers[
      cartitem.shippingIndex
    ].nativeElement.style.border = `2px solid ${this.myColor}`;
    buttons[cartitem.shippingIndex].checked = true;

    const company =
      cartitem.selectedAddresse?.shipping_methods[cartitem.shippingIndex];
    cartitem.deliveryPrice = cartitem.deliveryPrice
      ? cartitem.deliveryPrice
      : parseInt(company.delivery_price);
    cartitem.shippingValue = company.shipment_value;
    cartitem.shippingId = company.shipment_id;
    cartitem.shippingCompany = company.shipment_company;
    cartitem.shippingIndex = cartitem.shippingIndex || 0;
    this.setDeliveryDate(cartitem.selectedAddresse?.shipping_methods[0], index);
  }

  opendeleteModal(address_id) {
    this.step2 = false;
    this.step3 = false;
    this.step1 = false;
    this.step22 = false;
    this.step33 = false;
    this.step11 = true;

    if (address_id != "" && address_id > 0) {
      this.delete_address_id = address_id;
      if (isPlatformBrowser(this.platformId)) {
        // For SSR
        this.modalService
          .open(this.NewsLetterModal, {
            size: "md",
            ariaLabelledBy: "NewsLetter-Modal",
            centered: true,
            windowClass: "theme-modal newsletterm NewsLetterModal",
          })
          .result.then(
            (result) => {
              this.modalOpen = true;
              `Result ${result}`;
            },
            (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
          );
      }
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  addAddress() {
    this.successUser_name = false;
    // this.successLast_name = false;
    this.successPassword = false;
    this.successconfirmPassword = false;
    this.successMobile = false;
    this.mobileIsInValid = false;
    this.mobileIsValid = false;
    this.successMobile2 = false;
    this.mobile2IsInValid = false;
    this.mobile2IsValid = false;

    if (this.user_name == "" || this.user_name.length < 3) {
      this.successUser_name = true;
      this.translateService
        .get("first name message")
        .subscribe((translatedMessage: any) => {
          this.errorUserNameMessage = translatedMessage;
        });
      return;
    }
    // if (this.last_name == "" || this.last_name.length < 3) {
    //   this.successLast_name = true;
    //   this.translateService
    //     .get("last name message")
    //     .subscribe((translatedMessage: any) => {
    //       this.errorLastNameMessage = translatedMessage;
    //     });
    //   return;
    // }

    if (!this.startsWithAny(this.mobile_keys, this.valid_mobile)) {
      this.successMobile = true;
      this.mobileIsInValid = true;
      this.translateService
        .get("invalid mobile message")
        .subscribe((translatedMessage: any) => {
          this.errorMobileMessage = translatedMessage;
        });
      return;
    } else if (this.valid_mobile.length < this.mobileLength) {
      this.successMobile = true;
      this.mobileIsInValid = true;
      this.translateService
        .get("short mobile message")
        .subscribe((translatedMessage: any) => {
          this.errorMobileMessage = translatedMessage;
        });
      return;
    } else if (this.valid_mobile.length > this.mobileLength) {
      this.successMobile = true;
      this.mobileIsInValid = true;
      this.translateService
        .get("long mobile message")
        .subscribe((translatedMessage: any) => {
          this.errorMobileMessage = translatedMessage;
        });
      return;
    } else if (!this.mobilePattern.test(this.valid_mobile)) {
      this.successMobile = true;
      this.mobileIsInValid = true;
      this.translateService
        .get("valid mobile message")
        .subscribe((translatedMessage: any) => {
          this.errorMobileMessage = translatedMessage;
        });
      return;
    }
    if (!this.successMobile) {
      this.mobileIsValid = true;
      this.mobileIsInValid = false;
    }
    if (this.mobile2_data != "") {
      if (!this.startsWithAny(this.mobile_keys, this.valid2_mobile)) {
        this.successMobile2 = true;
        this.mobile2IsInValid = true;
        this.translateService
          .get("invalid mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorMobileMessage2 = translatedMessage;
          });
        return;
      } else if (this.valid2_mobile.length < this.mobileLength) {
        this.successMobile2 = true;
        this.mobile2IsInValid = true;
        this.translateService
          .get("short mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorMobileMessage2 = translatedMessage;
          });
        return;
      } else if (this.valid2_mobile.length > this.mobileLength) {
        this.successMobile2 = true;
        this.mobile2IsInValid = true;
        this.translateService
          .get("long mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorMobileMessage2 = translatedMessage;
          });
        return;
      } else if (!this.mobilePattern.test(this.valid2_mobile)) {
        this.successMobile2 = true;
        this.mobile2IsInValid = true;
        this.translateService
          .get("valid mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorMobileMessage2 = translatedMessage;
          });
        return;
      }
      if (!this.successMobile2) {
        this.mobile2IsValid = true;
        this.mobile2IsInValid = false;
      }
    }
    if (
      this.title == "" ||
      this.city_idModal == "" ||
      this.country_idModal == "" ||
      this.district_id == ""
    ) {
      this.checkoutForm.markAllAsTouched();
      return;
    }
    this.step2 = true;
    this.addStep = true;

    this.step3 = false;
    this.step1 = false;
    this.step4 = false;
    if (this.userLogedIn == true) {
      let body = {
        title: this.title,
        city_id: this.city_idModal,
        district_id: this.district_id,
        city_name: this.city_name,
        district_name: this.district_name,
        country_id: this.country_idModal,
        is_default: this.is_default,
        user_name: this.user_name,
        last_name: this.last_name,
        mobile: this.valid_mobile,
        mobile_2: this.valid2_mobile,
      };
      this.user.addAddress(body).subscribe((data) => {
        if (data.success) {
          this.step1 = false;
          this.step4 = false;
          this.step2 = false;
          this.step3 = true;
          this.addStep = true;
          this.getAllAddress();
        } else {
          return;
        }
      });
    } else {
      // this.router.navigate(["/pages/login"]);
      this.goToLogin();
    }
  }

  editAddress() {
    this.successUser_name = false;
    // this.successLast_name = false;
    this.successPassword = false;
    this.successconfirmPassword = false;
    this.successMobile = false;
    this.mobileIsInValid = false;
    this.mobileIsValid = false;
    this.successMobile2 = false;
    this.mobile2IsInValid = false;
    this.mobile2IsValid = false;

    if (!this.user_name || this.user_name.length < 3) {
      this.successUser_name = true;
      this.translateService
        .get("first name message")
        .subscribe((translatedMessage: any) => {
          this.errorUserNameMessage = translatedMessage;
        });
      return;
    }
    // if (!this.last_name || this.last_name.length < 3) {
    //   this.successLast_name = true;
    //   this.translateService
    //     .get("last name message")
    //     .subscribe((translatedMessage: any) => {
    //       this.errorLastNameMessage = translatedMessage;
    //     });
    //   return;
    // }

    if (!this.startsWithAny(this.mobile_keys, this.valid_mobile)) {
      this.successMobile = true;
      this.mobileIsInValid = true;
      this.translateService
        .get("invalid mobile message")
        .subscribe((translatedMessage: any) => {
          this.errorMobileMessage = translatedMessage;
        });
      return;
    } else if (this.valid_mobile.length < this.mobileLength) {
      this.successMobile = true;
      this.mobileIsInValid = true;
      this.translateService
        .get("short mobile message")
        .subscribe((translatedMessage: any) => {
          this.errorMobileMessage = translatedMessage;
        });
      return;
    } else if (this.valid_mobile.length > this.mobileLength) {
      this.successMobile = true;
      this.mobileIsInValid = true;
      this.translateService
        .get("long mobile message")
        .subscribe((translatedMessage: any) => {
          this.errorMobileMessage = translatedMessage;
        });
      return;
    } else if (!this.mobilePattern.test(this.valid_mobile)) {
      this.successMobile = true;
      this.mobileIsInValid = true;
      this.translateService
        .get("valid mobile message")
        .subscribe((translatedMessage: any) => {
          this.errorMobileMessage = translatedMessage;
        });
      return;
    }
    if (!this.successMobile) {
      this.mobileIsValid = true;
      this.mobileIsInValid = false;
    }

    if (this.mobile2_data != null && this.mobile2_data != "") {
      if (!this.startsWithAny(this.mobile_keys, this.valid2_mobile)) {
        this.successMobile2 = true;
        this.mobile2IsInValid = true;
        this.translateService
          .get("invalid mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorMobileMessage2 = translatedMessage;
          });
        return;
      } else if (this.valid2_mobile.length < this.mobileLength) {
        this.successMobile2 = true;
        this.mobile2IsInValid = true;
        this.translateService
          .get("short mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorMobileMessage2 = translatedMessage;
          });
        return;
      } else if (this.valid2_mobile.length > this.mobileLength) {
        this.successMobile2 = true;
        this.mobile2IsInValid = true;
        this.translateService
          .get("long mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorMobileMessage2 = translatedMessage;
          });
        return;
      } else if (!this.mobilePattern.test(this.valid2_mobile)) {
        this.successMobile2 = true;
        this.mobile2IsInValid = true;
        this.translateService
          .get("valid mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorMobileMessage2 = translatedMessage;
          });
        return;
      }
      if (!this.successMobile2) {
        this.mobile2IsValid = true;
        this.mobile2IsInValid = false;
      }
    }

    if (
      this.title == "" ||
      this.city_idModal == "" ||
      this.country_idModal == "" ||
      this.district_id == "" ||
      this.district_id == "0"
    ) {
      this.checkoutForm.markAllAsTouched();
      return;
    }
    this.step2 = true;
    this.editStep = true;
    this.step3 = false;
    this.step1 = false;
    this.step4 = false;
    if (this.userLogedIn == true) {
      let body = {
        id: this.address_id,
        title: this.title,
        city_id: this.city_idModal,
        district_id: this.district_id,
        city_name: this.city_name,
        district_name: this.district_name,
        country_id: this.country_idModal,
        is_default: this.is_default,
        user_name: this.user_name,
        last_name: this.last_name,
        mobile: this.valid_mobile,
        mobile_2: this.valid2_mobile,
      };
      this.user.editAddress(body).subscribe((data) => {
        this.getAllAddress();
        if (data.success) {
          this.step1 = false;
          this.step4 = false;
          this.step2 = false;
          this.step3 = true;
          this.editStep = true;
          // this.getAllAddress();
        } else {
          return;
        }
      });
    } else {
      this.goToLogin();
    }
  }

  deleteAddress(id) {
    if (this.delete_address_id != "") {
      this.step11 = false;
      this.step22 = true;
      this.step33 = false;

      if (this.userLogedIn == true) {
        let body = {
          address_id: id,
        };
        this.user.deleteAddress(body).subscribe((data) => {
          if (data.success) {
            this.step11 = false;
            this.step22 = false;
            this.step33 = true;
            this.getAllAddress();
          } else {
            this.step11 = false;
            this.step22 = false;
            this.step33 = false;
            return;
          }
        });
      } else {
        // this.router.navigate(["/pages/login"]);
        this.goToLogin();
      }
    }
  }

  goToLogin() {
    this.loginclicked = true;
  }
  close() {
    this.loginclicked = false;
  }

  calculateTextColor(themeVariable: string): string {
    const cssValue = getComputedStyle(
      document.querySelector("app-root")
    ).getPropertyValue(themeVariable);
    return this.Common.tinyColor(cssValue);
  }

  paymentMethods: any[] = [];
  selectedPaymentMethod: string = "";

  getPaymentMethods(totalPrice: any): void {
    const body = {
      InvoiceAmount: totalPrice,
      CurrencyIso: this.contactInfoData.payment_gateway_currency_iso,
    };

    this.FatoorahPaymentService.getPaymentMethods(
      body,
      this.contactInfoData.payment_gateway_api_key
    ).subscribe(
      (response) => {
        if (response.IsSuccess) {
          this.paymentMethods = response.Data.PaymentMethods;
          this.paymentLoading = false;
        } else {
          // Handle error
          console.error("Failed to fetch payment methods", response);
        }
      },
      (error) => {
        console.error("API error", error);
      }
    );
  }

  ExecutePayment(method: any): void {
    this.user_info = JSON.parse(localStorage.getItem("currentUserFront"));
    const paymentData = {
      InvoiceValue: method.TotalAmount,
      Language: this.Common.isRtl ? "ar" : "en",
      PaymentMethodId: method.PaymentMethodId,
      DisplayCurrencyIso: this.contactInfoData.payment_gateway_currency_iso,
      CustomerName: `${this.user_info.user_name} ${this.user_info.last_name}`,
      // MobileCountryCode: this.country_code,
      UserDefinedField: "CK-" + this.user_info.id,
      CustomerMobile: this.user_info.mobile,
      CustomerEmail: this.user_info.email,
      CustomerCivilId: this.user_info.id,
      CustomerReference: this.cart_products[this.index].id,
      CallBackUrl: this.baseUrl + "/shop/checkout",
    };
    this.FatoorahPaymentService.ExecutePayment(
      paymentData,
      this.contactInfoData.payment_gateway_api_key
    ).subscribe({
      next: (response) => {
        console.log(response);
        if (response.IsSuccess) {
          window.location.href = response.Data.PaymentURL;
        } else {
          console.error("Payment failed", response);
        }
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      },
    });
  }

  updateNote(note: string, index: number) {
    this.cart_products[index].note = note;
  }
  getCommitmentsAndPoliciesByMode() {
    this.Common.getCommitmentsAndPoliciesByMode(
      "show_in_order_confirmation_page"
    ).subscribe({
      next: (res) => {
        console.log("show_in_order_confirmation_page", res);
        if (res?.success) {
          this.privacyDetails?.pages.shift();
          this.privacyDetails = res?.data;
          const gradient = `linear-gradient(${this.privacyDetails?.color_deg}, ${this.privacyDetails?.color_1}, ${this.privacyDetails?.color_2})`;
          this.renderer.setStyle(
            this.privacyTitle?.nativeElement,
            "background",
            gradient
          );
          this.setFontColorBasedOnBackground(
            this.privacyTitle?.nativeElement.children[0]
          );
        } else {
          this.privacyDetails?.pages.shift();
        }
      },
      error: (err) => {
        console.log(err);
        this.privacyDetails?.pages.shift();
      },
    });
  }
  isColorDark(color: string): boolean {
    let r: number = 0,
      g: number = 0,
      b: number = 0;

    if (color.match(/^#([0-9a-f]{3}){1,2}$/i)) {
      let hex = color.substring(1);
      if (hex.length === 3) {
        hex = hex
          .split("")
          .map((h) => h + h)
          .join("");
      }
      const num = parseInt(hex, 16);
      r = (num >> 16) & 255;
      g = (num >> 8) & 255;
      b = num & 255;
    } else if (color.match(/^rgb/i)) {
      const rgbValues = color.match(/\d+/g);
      if (rgbValues) {
        r = parseInt(rgbValues[0], 10);
        g = parseInt(rgbValues[1], 10);
        b = parseInt(rgbValues[2], 10);
      }
    } else {
      throw new Error("Invalid color format");
    }

    // Calculate brightness using the formula
    // Brightness formula: (0.299*R + 0.587*G + 0.114*B)
    const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

    // Return true if brightness is below a threshold (e.g., 128), indicating a dark color
    return brightness < 128;
  }

  setFontColorBasedOnBackground(element: HTMLElement): void {
    const backgroundColor = this.privacyDetails?.color_1;
    if (this.isColorDark(backgroundColor)) {
      element.style.color = "white";
    } else {
      element.style.color = "black";
    }
  }
  showPrivacyDetails(id: string) {
    this.showSupportCategory(id);
    this.modalService
      .open(this.privacyModal, {
        size: "lg",
        ariaLabelledBy: "privacyModal-Modal",
        centered: true,
        windowClass: "theme-modal newsletterm NewsLetterModal",
        scrollable: true,
      })
      .result.then(
        (result) => {
          // this.modalOpen = true;
          `Result ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  showSupportCategory(id: string) {
    this.loaderSupport = true;
    this.Common.getSupportPageById(id).subscribe({
      next: (data) => {
        console.log(data);
        if (data?.success) {
          this.supportDetails = data.data;
          this.loaderSupport = false;
        }
        this.loaderSupport = false;
        this.noSupportContent = true;
      },
      error: (err) => {
        console.log(err);
        this.loaderSupport = false;
        this.noSupportContent = true;
      },
    });
  }

  private timerSubscriptions: Subscription[] = [];
  private timers: {
    [key: number]: { hours: string; minutes: string; seconds: string };
  } = {};

  calculateTimer(
    targetHour: number
  ): { hours: string; minutes: string; seconds: string } | null {
    const now = new Date();
    const targetDate = new Date();

    if (this.timers[targetHour]) {
      const { hours, minutes, seconds } = this.timers[targetHour];
      if (hours === "00" && minutes === "00" && seconds === "00") {
        return null;
      }
      return this.timers[targetHour];
    }

    targetDate.setHours(targetHour, 0, 0, 0);

    if (targetDate.getTime() <= now.getTime()) {
      return null;
    }

    const targetTimestamp = targetDate.getTime();
    this.timers[targetHour] = { hours: "00", minutes: "00", seconds: "00" };

    const subscription = interval(1000).subscribe(() => {
      const now = new Date().getTime();
      const remainingMilliseconds = targetTimestamp - now;

      if (remainingMilliseconds <= 0) {
        this.timers[targetHour] = { hours: "00", minutes: "00", seconds: "00" };
      } else {
        const totalSeconds = Math.floor(remainingMilliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        this.timers[targetHour] = {
          hours: hours.toString().padStart(2, "0"),
          minutes: minutes.toString().padStart(2, "0"),
          seconds: seconds.toString().padStart(2, "0"),
        };
      }
    });

    this.timerSubscriptions.push(subscription);
    return this.timers[targetHour];
  }
}
function viewchild(
  store: any
): (target: CheckoutComponent, propertyKey: "") => void {
  throw new Error("Function not implemented.");
}
