<ng-container *ngIf="!productLoading else productLoad">
    <div class="breadcrumb-section">
        <div class="container">
            <div class="row">

                <div class="col-sm-12">
                    <nav aria-label="breadcrumb" class="theme-breadcrumb">
                        <div class="breadcrumb">
                            <a [routerLink]="'/'" class="">
                                {{'home' | translate}}
                            </a>
                            <a class=""
                                [routerLink]="['/shop/category' , breadCrumb.id , breadCrumb.ser_name.split(' ').join('-')]"
                                *ngFor="let breadCrumb of breadCrumblist ;  let isFirst = first ; let isLast = last">>
                                {{breadCrumb.ser_name | translate}} <span *ngIf="!isFirst || isLast">></span></a>
                            <a class="">
                                {{breadCrumbProductName | translate}}
                            </a>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>

    <!-- section start -->
    <section class="pt-0 section-b-space">
        <div class="collection-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-sm-12 col-xs-12">
                        <div class="container-fluid">

                            <div class="row">
                                <div class="col-lg-9 main-section-start" #mainParent>
                                    <div class="slider-description-main-section">
                                        <!-- <button class="btn wishlist-btn wish-mobile" (click)="addToWishlist(product)">
                                            <i class="fa fa-heart fa-2x"
                                                [ngClass]="isFavourit ? 'text-danger' : 'text-icon'"></i>
                                        </button> -->
                                        <div class="p_slider" #swiperContainer>
                                            <span class="lable5" *ngIf="product?.attachment_id> 0"
                                                [class]="Common.isRtl ? 'label-ar' : 'label-en'">
                                                <b [style.background]="product['attachment_color']"
                                                    [style.color]="Common.tinyColor(product['attachment_color'])">
                                                    {{product?.attachment_name}}
                                                </b>
                                            </span>
                                            <!-- <span class="lable6" *ngIf="product?.offer_id> 0 ">
                                                <b>{{product?.offer_data['title']}}</b>
                                            </span> -->


                                            <!-- Main Swiper -->
                                            <swiper [pagination]="true" class="mainProductSlider1 "
                                                *ngIf="product?.images?.length > 0" [thumbs]="{ swiper: thumbsSwiper }"
                                                #mainProductSwiper>
                                                <ng-template class="h-100" *ngFor="let image of
                                                    productimages; index as i" swiperSlide let-data>
                                                    <div *ngIf="image" class="h-100">
                                                        <img *ngIf="image.type === 'image'" class="img-fluid h-100"
                                                            (error)="handleImageError($event.target)"
                                                            [src]="urlimage+image.name" (click)="showImageModal(i)"
                                                            style="cursor:pointer;" alt="{{ product?.name }}"
                                                            class="img-fluid" [title]="">

                                                        <video controls [autoplay]="data.isActive"
                                                            *ngIf="image.type === 'video' && data.isActive"
                                                            class="img-fluid">
                                                            <source [src]="urlimage+image.name">
                                                        </video>
                                                    </div>
                                                </ng-template>
                                            </swiper>
                                            <div class="text-center">
                                                {{'smallSlider' | translate}}
                                            </div>
                                            <swiper [pagination]="true" class="mainProductSlider1"
                                                *ngIf="product?.images?.length == 0"
                                                [thumbs]="{ swiper: thumbsSwiper }">
                                                <ng-template swiperSlide let-data>
                                                    <img [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                        [lazyLoad]="'assets/images/product/placeholder.jpg'"
                                                        class="img-fluid border-1 rounded-2" [title]="">
                                                </ng-template>
                                            </swiper>

                                            <!-- Small Swiper -->
                                            <swiper (swiper)="thumbsSwiper = $event" [spaceBetween]="10"
                                                *ngIf="product?.images?.length > 0" [slidesPerView]="'auto'"
                                                [freeMode]="true" [watchSlidesProgress]="true"
                                                class="productImagesSideSlider">
                                                <ng-template *ngFor="let image of productimages; index as i"
                                                    swiperSlide>

                                                    <img *ngIf="image.type === 'image'"
                                                        (error)="handleImageError($event.target)"
                                                        style="cursor:pointer;" [src]="urlimage+image.name"
                                                        alt="{{ product?.name }}"
                                                        class="border rounded img-fluid small-silder">

                                                    <video *ngIf="image.type === 'video'" class="img-fluid">
                                                        <source src="{{urlimage+image.original_name}}">
                                                    </video>

                                                </ng-template>

                                            </swiper>

                                            <swiper (swiper)="thumbsSwiper = $event" [spaceBetween]="10"
                                                *ngIf="product?.images?.length == 0" [slidesPerView]="'auto'"
                                                [freeMode]="true" [watchSlidesProgress]="true"
                                                class="productImagesSideSlider">

                                                <ng-template swiperSlide>
                                                    <img [lazyLoad]="'assets/images/product/placeholder.jpg'"
                                                        class="img-fluid">
                                                </ng-template>

                                            </swiper>

                                            <!-- <owl-carousel-o [options]="ProductDetailsMainSliderConfig" #owlCar
                                                class="product-slick main-img">
                                                <ng-container *ngFor="let image of
                                                productimages; index as i">
                                                    <ng-template carouselSlide [id]="i">
                                                        <div class="">
                                                            <img [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                                [lazyLoad]="urlimage+image.name" alt="{{ product?.name }}"
                                                                class="img-fluid">
                                                        </div>
                                                    </ng-template>
                                                </ng-container>
                                            </owl-carousel-o> -->


                                            <!-- <div class="p-0 col-12 side-product-images" *ngIf="productimages.length> 1">
                                                <div class="slider-nav">
                                                    <owl-carousel-o [options]="ProductDetailsThumbConfig"
                                                        class="product-slick">
                                                        <ng-container *ngFor="let image
                                                        of productimages; index as
                                                        i">
                                                            <ng-template carouselSlide [id]="i">
                                                                <div class="owl-thumb" [class.active]="i ==
                                                                activeSlide">
                                                                    <img [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                                        [lazyLoad]="urlimage+image.name"
                                                                        alt="{{ product?.name }}" class="img-fluid" (click)="owlCar.to(activeSlide=
                                                                    i.toString())">
                                                                </div>
                                                            </ng-template>
                                                        </ng-container>
                                                    </owl-carousel-o>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="second-child-placeholder" #placeholder></div>
                                        <div class="title-description-section">
                                            <span *ngIf="product?.price> 0 && (product?.last_price > 0)"
                                                class="pt-1 discout">
                                                {{'discount' | translate}}
                                                {{ getDiscountPercentage(product?.last_price,product?.price)
                                                + "%" }}
                                            </span>
                                            <div
                                                class="flex-wrap p-0 product-status trademark-container d-flex justify-content-between align-items-center">
                                                <div *ngIf="product?.trademark_title">
                                                    <span class="info-key-title">
                                                        {{'brand' | translate}}:
                                                    </span>
                                                    <a style="text-decoration: underline;color: #0062d1;"
                                                        [routerLink]="['/shop/trademarkes/'  , product?.trademark_id , product?.trademark_title.split(' ').join('-')]">
                                                        <span class="spacer-marg" style="color:#0062d1;">
                                                            {{product?.trademark_title}}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div class="social-icons">
                                                    <i *ngIf="!isFavourit" class="fa fa-heart-o heart"
                                                        (click)="addToWishlist(product)" aria-hidden="true"></i>
                                                    <i *ngIf="isFavourit" class="fa fa-heart heart text-danger"
                                                        aria-hidden="true"></i>
                                                    <span class="" ngbDropdown>
                                                        <i class="mx-4 fa fa-share-alt share" id="dropdownBasic1"
                                                            ngbDropdownToggle aria-hidden="true"></i>
                                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                            class="p-0 text-center">
                                                            <button ngbDropdownItem (click)="copyToCliboard(product)">
                                                                <img src="assets/images/social/copy.png" alt="copy-icon"
                                                                    srcset="" width="20" height="20">
                                                                {{'copy the link' | translate}}
                                                            </button>
                                                            <button ngbDropdownItem style="border-top: 1px solid #ddd;"
                                                                (click)="shareWithWhatsapp()">
                                                                <img alt="label-icon" width="20"
                                                                    style="border-radius:50%;" height="20"
                                                                    src="assets/images/social/DWhatsapp.jpg">
                                                                {{'whatsapp' | translate}}
                                                            </button>
                                                            <button ngbDropdownItem style="border-top: 1px solid #ddd;"
                                                                (click)="shareViaFacebook()">
                                                                <img alt="label-icon" width="20"
                                                                    style="border-radius:50%;" height="20"
                                                                    src="assets/images/social/DFacebook.png">
                                                                {{'facebook' | translate}}
                                                            </button>
                                                            <button ngbDropdownItem style="border-top: 1px solid #ddd;"
                                                                (click)="shareViaX()">
                                                                <img alt="label-icon" width="20"
                                                                    style="border-radius:50%;" height="20"
                                                                    src="assets/images/social/DX.png">
                                                                {{'twiiter' | translate}}
                                                            </button>
                                                        </div>
                                                    </span>
                                                    <span class="tradmark-icon" *ngIf="product?.trademark_image">
                                                        <a style="text-decoration: underline;"
                                                            [routerLink]="['/shop/trademarkes/'  , product?.trademark_id , product?.trademark_title.split(' ').join('-')]">
                                                            <img [lazyLoad]="imagetrademarkurl + product?.trademark_image"
                                                                width="70px" height="70px" />
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="title">
                                                <h1 style="line-height: normal;" class="font-title font-weight-bold">{{
                                                    product?.name }}</h1>
                                            </div>

                                            <div class="desktop-descrition list-style">
                                                <ul class="product-status d-flex flex-column align-items-start ">
                                                    <li class="price"
                                                        *ngIf="(product?.price > 0 && product?.store_amounts > 0) && getDiscountPercentage(product?.last_price,product?.price) > 0">
                                                        <div class="price-container">
                                                            <del class="price-text">
                                                                {{ product?.last_price ? product?.last_price :
                                                                product?.price }} {{currency?.currency}}
                                                            </del>
                                                            <span class="mx-3 badge badge-pill badge-danger"
                                                                *ngIf="product?.price> 0 && (product?.last_price > 0)">
                                                                {{
                                                                getDiscountPercentage(product?.last_price,product?.price)
                                                                + "% -" }}
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li class="last-price"
                                                        *ngIf="(product?.price > 0 && product?.store_amounts > 0)">
                                                        <div class="last-price-container">
                                                            {{ (product?.price) }}
                                                            {{ currency?.currency }}
                                                        </div>
                                                        <span class="tax"
                                                            *ngIf="settings?.including_value_added_tax == '1'"
                                                            style="font-weight: 300;">
                                                            {{'including TAX' | translate}}
                                                        </span>
                                                    </li>
                                                    <li class="d-flex align-items-center w-100"
                                                        *ngIf="(product?.store_amounts > 0 && product?.price > 0 &&product?.active=='1')">
                                                        <!-- <i class="fa fa-check-circle-o text-success check-container" aria-hidden="true"></i> -->
                                                        <div class="d-flex align-items-center">
                                                            <img src="assets/images/icon/healthy (1)/healthy (1).webp"
                                                                width="30" height="30" alt="out">
                                                            <span class="text-success spacer-marg"
                                                                style="font-weight: 600 !important;white-space:nowrap">
                                                                {{'available' | translate}}
                                                            </span>
                                                        </div>

                                                        <div class="image-shipping"
                                                            *ngIf="product.product_group_data&&product.product_group_data.img">
                                                            <span class="shipping-icon"
                                                                *ngIf="product.product_group_data.description"
                                                                (click)="shippingModal(product.product_group_data.description)">
                                                                <i class="fa fa-solid fa-question"></i>
                                                            </span>
                                                            <img [src]="Common._ImageUrl + 'groups/' + product.product_group_data.img"
                                                                alt="Shipping image" width="auto" height="20"
                                                                (error)="handleImageError($event)">
                                                        </div>
                                                    </li>
                                                    <li class="d-flex align-items-center w-100"
                                                        *ngIf="((product?.store_amounts == 0 && product?.price == 0) ||(product?.store_amounts == 0 && product?.price > 0))&&product?.active=='1' ">
                                                        <div class="d-flex align-items-center">
                                                            <img src="assets/images/icon/Downloads/out100.webp"
                                                                width="30" height="30" alt="out">
                                                            <span class="text-danger spacer-marg"
                                                                style="font-weight: 600 !important;white-space:nowrap">
                                                                {{'out of stock' | translate}}
                                                            </span>
                                                        </div>
                                                        <div class="image-shipping "
                                                            *ngIf="product.product_group_data&&product.product_group_data.img">
                                                            <span class="shipping-icon"
                                                                *ngIf="product.product_group_data.description"
                                                                (click)="shippingModal(product.product_group_data.description)">
                                                                <i class="fa fa-solid fa-question"></i>
                                                            </span>
                                                            <img [src]="Common._ImageUrl + 'groups/' + product.product_group_data.img"
                                                                alt="Shipping image" width="auto" height="20"
                                                                (error)="handleImageError($event)">
                                                        </div>
                                                    </li>

                                                    <li class="d-flex align-items-center w-100"
                                                        *ngIf="(product?.store_amounts> 0 && product?.price == 0 &&product?.active=='1') ">
                                                        <div class="d-flex align-items-center">

                                                            <img src="assets/images/icon/Downloads/coming soon.webp"
                                                                width="30" height="30" alt="soon">
                                                            <span class="soon-status spacer-marg"
                                                                style="font-weight: 600 !important;white-space:nowrap">
                                                                {{'Will Soon' | translate}}
                                                            </span>
                                                        </div>

                                                        <div class="image-shipping "
                                                            *ngIf="product.product_group_data&&product.product_group_data.img">
                                                            <span class="shipping-icon"
                                                                *ngIf="product.product_group_data.description"
                                                                (click)="shippingModal(product.product_group_data.description)">
                                                                <i class="fa fa-solid fa-question"></i>
                                                            </span>
                                                            <img [src]="Common._ImageUrl + 'groups/' + product.product_group_data.img"
                                                                alt="Shipping image" width="auto" height="20"
                                                                (error)="handleImageError($event)">
                                                        </div>
                                                    </li>
                                                    <li *ngIf="product?.active=='2'"
                                                        class="d-flex align-items-center w-100">
                                                        <div class="d-flex align-items-center">
                                                            <img src="assets/images/icon/Downloads/stocktaking2.webp"
                                                                width="30" height="30" alt="out">
                                                            <span class="text-danger spacer-marg"
                                                                style="font-weight: 600 !important;white-space:nowrap">
                                                                {{'Wiil Inventory is in progress' | translate}}
                                                            </span>
                                                        </div>

                                                        <div class="image-shipping "
                                                            *ngIf="product.product_group_data&&product.product_group_data.img">
                                                            <span class="shipping-icon"
                                                                *ngIf="product.product_group_data.description"
                                                                (click)="shippingModal(product.product_group_data.description)">
                                                                <i class="fa fa-solid fa-question"></i>
                                                            </span>
                                                            <img [src]="Common._ImageUrl + 'groups/' + product.product_group_data.img"
                                                                alt="Shipping image" width="auto" height="20"
                                                                (error)="handleImageError($event)">
                                                        </div>
                                                    </li>
                                                    <li class="original d-flex align-items-center"
                                                        *ngFor="let item of product?.product_options">
                                                        <!-- <div class="original-container" *ngIf="!item?.img">
                                                            <i class="fa fa-thumbs-up" aria-hidden="true" style="color: #FFEE02;"></i>
                                                        </div> -->
                                                        <img [src]="mainUrlimage + item?.img" width="25px" height="25px"
                                                            alt="done-icon" *ngIf="item?.img">
                                                        <span class="original-name">
                                                            {{item?.name}}
                                                        </span>

                                                        <span *ngIf="item?.page_id !== '0'"
                                                            class="pt-1 mx-2 cursor-pointer"
                                                            (click)="showPrivacyDetails(item?.page_id , item?.name)">
                                                            <img src="assets/images/icon/info.png" alt="info"
                                                                width="20px" height="20px" />
                                                        </span>
                                                    </li>
                                                    <li *ngIf="product?.product_barcode"
                                                        class="d-flex flex-nowrap align-items-center">
                                                        <img src="assets/images/icon/Downloads/barcode-scan100.webp"
                                                            width="30" height="30" alt="out">
                                                        <span class="mx-1 info-key-title">
                                                            {{'barcode' | translate}}:
                                                        </span>
                                                        <span style="color: #222222;" class="spacer-marg">
                                                            {{product?.product_barcode}}
                                                        </span>
                                                    </li>
                                                    <!-- <li *ngIf="product?.product_code" class="d-flex flex-nowrap spacer-marg-link">
                                                        <i class="fa fa-solid fa-circle"></i>
                                                        <span class="info-key-title">
                                                            {{'product code' | translate}}:
                                                        </span>
                                                        <span style="color: #222222;" class="spacer-marg">
                                                            {{product?.product_code}}
                                                        </span>
                                                    </li> -->
                                                </ul>
                                            </div>
                                            <div class="desktop-discount-quantity">
                                                <hr *ngIf="ProductQuantity>=1">
                                                <span class="mb-3 product-title added-title"
                                                    *ngIf="ProductQuantity >= 1">
                                                    {{'Enjoy the quantitative discount'|translate}}
                                                </span>
                                                <ul class="row">
                                                    <li class="col-md-8"
                                                        *ngFor="let disc of quantity_discount let d=index"
                                                        [class.d-none]="!(disc.quantity <= this.product?.stores[0]['store_amounts_product'] && +(disc.quantity) > 1)">
                                                        <ng-container
                                                            *ngIf="disc.quantity <= this.product?.stores[0]['store_amounts_product'] && +(disc.quantity) > 1">
                                                            <div class="mb-2 cursor-pointer d-flex justify-content-start align-items-center discount-item"
                                                                (click)="addProductDiscountToCart(disc)">
                                                                <div>
                                                                    <img src="assets/images/icon/Downloads/Quantity discount.webp"
                                                                        width="25" alt="quantity-discount-icon">
                                                                </div>
                                                                <div
                                                                    class="d-flex justify-content-center align-items-start flex-column">
                                                                    <div class="mb-2 d-flex align-items-center">
                                                                        <p class="px-2 m-0 text-black text-size p-line related-text"
                                                                            *ngIf="disc.purchase_text !='' &&disc.purchase_text!=null">
                                                                            {{disc.purchase_text}}</p>

                                                                        <p class="px-2 m-0 text-black text-size p-line related-text"
                                                                            *ngIf="disc.purchase_text=='' ||disc.purchase_text ==null">
                                                                            {{'buy'|translate}}
                                                                            <span class="text-main">
                                                                                {{disc.quantity}}
                                                                            </span>
                                                                            {{'price'|translate}}
                                                                            {{disc.quantity*disc.unit_price}} {{
                                                                            currency?.currency }}
                                                                        </p>

                                                                    </div>
                                                                    <div class="d-flex align-items-center">
                                                                        <p
                                                                            class="px-2 m-0 text-black text-size related-text">
                                                                            {{'piece' | translate}}
                                                                        </p>
                                                                        <p
                                                                            class="m-0 text-black text-size related-text">
                                                                            <span class="text-main">
                                                                                {{disc.unit_price}} {{
                                                                                currency?.currency }}
                                                                            </span>
                                                                            {{'instead of' | translate}}
                                                                        </p>
                                                                        <p style="text-decoration: line-through;"
                                                                            class="px-1 m-0 text-black text-muted text-size related-text"
                                                                            *ngIf="unitPricefor1quantity">
                                                                            {{product?.price
                                                                            }} {{ currency?.currency }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="desktop-choose-options" *ngIf="optionsData.length>1">
                                                <hr>
                                                <span class="mb-3 product-title added-title">
                                                    {{'Choose an option'|translate}}
                                                </span>
                                                <div class="flex-wrap gap-3 d-flex">
                                                    <div (click)="chooseOptions(i,item)" class="option-choose-data"
                                                        [class.active]="i==optionChekced"
                                                        *ngFor="let item of optionsData let first=first let i =index">
                                                        <span>{{item.name}}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="desktop-descrition">
                                                <hr *ngIf="productFormShapesDetails.length >0">
                                                <span class="mb-2 product-title added-title description-desktop"
                                                    *ngIf="productFormShapesDetails.length >0">
                                                    {{"Specifications" | translate}}:
                                                </span>
                                                <div class="list-style w-100 ">
                                                    <ul class="product-status d-flex flex-column align-items-start">


                                                        <li *ngFor="let form of productFormShapesDetails; let index=index; let last=last"
                                                            class="flex-nowrap d-flex spacer-marg-link"
                                                            [class.p-0]="last">
                                                            <i class="fa fa-solid fa-circle"></i>
                                                            <span class="info-key-title" style="white-space: nowrap;">
                                                                {{form.form_name|trim | translate}}<i
                                                                    class="fa fa-exclamation-circle pointer-event "
                                                                    *ngIf="!form.form_shape_id"
                                                                    (click)="getNoteDetails(3)"></i>:&nbsp;
                                                            </span>
                                                            <span style="color: #222222;"
                                                                *ngIf="form.type != 'm_select' && form.type != 'text' &&form.type">
                                                                <a style="text-decoration: underline;"
                                                                    [routerLink]="['/pages/formshapes/' + form.form_value]">{{form.form_value}}</a>

                                                            </span>
                                                            <span style="color: #222222;" *ngIf="form.type == 'text'">
                                                                <a>{{form.form_value}}</a>
                                                            </span>
                                                            <span style="color: #222222;" *ngIf="!form.type">
                                                                <a>{{form.form_value}}</a>
                                                            </span>

                                                            <!-- <span style="color: #222222;" class="flex-wrap d-flex">
                                                
                                                                                                    </span> -->
                                                            <div class="flex-wrap d-flex ">
                                                                <ng-container *ngIf="form.type == 'm_select'">
                                                                    <span style="color: #222222;"
                                                                        *ngFor="let select of multi_select[index]; let s=index; last as isLast">
                                                                        <a style="text-decoration: underline;"
                                                                            [routerLink]="['/pages/formshapes/' + select]">
                                                                            {{select}}
                                                                            <span class="mx-2 d-inline-block"
                                                                                *ngIf="!isLast"> -
                                                                            </span>
                                                                        </a>
                                                                    </span>
                                                                </ng-container>
                                                            </div>


                                                        </li>

                                                    </ul>
                                                </div>

                                            </div>

                                            <div *ngIf="!isMobile &&showDirectRequestComponent"
                                                #directRequestContainerLarge class="direct-request-desktop">
                                                <app-direct-request #directRequestComponentLarge
                                                    *ngIf="product?.api_id_to =='0'" [product_type]="optionItemData"
                                                    [store_amount]="optionItemData?.store_amounts_product"
                                                    (directRequestAdded)="onDirectRequestAdded()"
                                                    [product_id]="id_of_product"
                                                    [product]="product"></app-direct-request>
                                            </div>



                                            <div class="discount-options">

                                                <hr *ngIf="related_product?.length >1">

                                                <span class="mb-3 product-title added-title"
                                                    *ngIf="related_product?.length >1">
                                                    {{'Other options are available'|translate}}
                                                </span>
                                                <ng-container *ngIf="related_product?.length > 1">
                                                    <div class="options-container">
                                                        <div class="text-center options-item"
                                                            [class.active]="index == 0"
                                                            [class.cursor-pointer]="index != 0"
                                                            *ngFor="let prod of related_product; let index=index"
                                                            [routerLink]="index != 0 ? ['/shop/product/', prod.id, prod.meta_url ? prod.meta_url.split(' ').join('-').replace('+', '') : removeBrackets(prod.product_name)?.split(' ').join('-').replace('+', '')] : null">

                                                            <p class="text-center text-black option_title text-size">
                                                                {{prod.related_details}}</p>
                                                            <div
                                                                *ngIf="(prod.store_amounts > 0 && prod.price > 0 && prod?.stores?.length == 1)">
                                                                <span class="mr-2 text-black text-size"
                                                                    [class.text-red]="prod.last_price"
                                                                    [class.text-black]=" !prod.last_price">{{prod.price}}
                                                                    {{productService?.Currency.name}}</span>
                                                                <span class="mr-2 text-black text-muted text-size"
                                                                    style="text-decoration: line-through;"
                                                                    *ngIf="prod.last_price">{{prod.last_price}}
                                                                    {{productService?.Currency.name}}</span>
                                                            </div>
                                                            <span class="text-stock text-soon text-size"
                                                                *ngIf="(prod.store_amounts == 0 && prod.price == 0) || (prod.store_amounts == 0 && prod.price > 0)">
                                                                {{'Out of stock' | translate}}
                                                            </span>
                                                            <span class="text-soon text-size"
                                                                *ngIf="(prod.store_amounts > 0 && prod.price == 0)">
                                                                {{'Soon' | translate}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <hr *ngIf="similar_product?.length > 1">

                                                <div class="similar-container container-fit position-relative"
                                                    *ngIf="similar_product?.length > 1 && similar_product?.length <3 ">
                                                    <h2 class="product-title" [style.color]="calculateTextColor('--theme-default')">
                                                        {{'Products that are frequently purchased together'|translate}}
                                                    </h2>
                                                    <div class="similar-product d-flex justify-content-center">
                                                        <ng-container
                                                            *ngIf="similar_product?.length >1&&similar_product?.length < 3">
                                                            <div class="d-flex align-items-center related-products-slide1"
                                                                *ngFor="let sProduct of similar_product; let s = index; last as isLast">
                                                                <div class="product-box">
                                                                    <div class="product-box-inner">
                                                                        <div class="img-wrapper position-relative">
                                                                            <input type="checkbox"
                                                                                class="form-check product-check"
                                                                                [checked]="sProduct.checked"
                                                                                (change)="toggleProductChecked(s)">
                                                                            <div class="text-center"
                                                                                [class.cursor-pointer]="s != 0"
                                                                                [routerLink]="s != 0 ?  ['/shop/product/', sProduct.id , sProduct.meta_url ? sProduct.meta_url?.split(' ').join('-').replace('+' , '') : removeBrackets(sProduct.product_name)?.split(' ').join('-').replace('+' , '') ] :null">
                                                                                <div class="front">
                                                                                    <a>
                                                                                        <div
                                                                                            class="text-center image-frame">
                                                                                            <img [src]="(sProduct.images.length > 0 ? urlimage + sProduct.images[0].original_name : 'assets/images/product/placeholder.jpg')"
                                                                                                [lazyLoad]="urlimage + sProduct.original_name"
                                                                                                class="img-fluid lazy-loading"
                                                                                                (error)="sProduct.images.length = 0; $event.target.src = 'assets/images/product/placeholder.jpg';"
                                                                                                alt="Product Image" />
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                                <h3
                                                                                    class="text-center text-black text-size">
                                                                                    {{
                                                                                    sProduct.product_name
                                                                                    }}</h3>
                                                                                <p
                                                                                    class="text-center text-black product-price text-size">
                                                                                    {{
                                                                                    sProduct.price }} {{
                                                                                    productService?.Currency.name }}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p class="plus"
                                                                    [ngStyle]="isLast?{'opacity':'0'}:{'opacity':'1'}">+
                                                                </p>
                                                            </div>

                                                        </ng-container>

                                                    </div>
                                                    <button type="button" class="btn w-100"
                                                        [disabled]="selectedProductsCount === 0"
                                                        (click)="!isloading && addSimilarToCart()">
                                                        <h5
                                                            class="flex-wrap cursor-pointer d-flex justify-content-between product-title">
                                                            <span class="product_title">
                                                                {{'addproduct'|translate}} {{selectedProductsCount +"
                                                                "}}{{'to cart'|translate}} </span>
                                                            <span class="product_title">|</span>
                                                            <div>
                                                                <span class="product_title">{{ totalPrice }} {{
                                                                    productService?.Currency.name }}</span>
                                                                <span *ngIf="isloading" class="cutom-loader"></span>
                                                            </div>
                                                        </h5>
                                                    </button>
                                                </div>
                                                <div class="my-3 similar-container position-relative"
                                                    *ngIf="similar_product?.length >=3">
                                                    <h2 class="product-title" [style.color]="calculateTextColor('--theme-default')">
                                                        {{'Products that are frequently purchased together'|translate}}
                                                    </h2>
                                                    <div class="similar-product">

                                                        <swiper [config]="similarProductsSliderConfig">

                                                            <ng-template
                                                                class="d-flex align-items-center related-products-slide1"
                                                                *ngFor="let sProduct of similar_product; let s = index; last as isLast"
                                                                swiperSlide>
                                                                <div class="product-box">
                                                                    <div class="product-box-inner">
                                                                        <div class="img-wrapper position-relative">
                                                                            <input type="checkbox"
                                                                                class="form-check product-check"
                                                                                [checked]="sProduct.checked"
                                                                                (change)="toggleProductChecked(s)">
                                                                            <div class="text-center"
                                                                                [class.cursor-pointer]="s != 0"
                                                                                [routerLink]="s != 0 ?  ['/shop/product/', sProduct.id , sProduct.meta_url ? sProduct.meta_url?.split(' ').join('-').replace('+' , '') : removeBrackets(sProduct.product_name)?.split(' ').join('-').replace('+' , '') ] :null">
                                                                                <div class="front">
                                                                                    <a>
                                                                                        <div
                                                                                            class="text-center image-frame">
                                                                                            <img [src]="(sProduct.images.length > 0 ? urlimage + sProduct.images[0].original_name : 'assets/images/product/placeholder.jpg')"
                                                                                                [lazyLoad]="urlimage + sProduct.original_name"
                                                                                                class="img-fluid lazy-loading"
                                                                                                (error)="sProduct.images.length = 0; $event.target.src = 'assets/images/product/placeholder.jpg';"
                                                                                                alt="Product Image" />
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                                <h3
                                                                                    class="text-center text-black text-size">
                                                                                    {{
                                                                                    sProduct.product_name
                                                                                    }}</h3>
                                                                                <p
                                                                                    class="text-center text-black product-price text-size">
                                                                                    {{
                                                                                    sProduct.price }} {{
                                                                                    productService?.Currency.name }}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p class="plus"
                                                                    [ngStyle]="isLast?{'opacity':'0'}:{'opacity':'1'}">+
                                                                </p>
                                                            </ng-template>

                                                        </swiper>
                                                        <button type="button" class="btn w-100"
                                                            [disabled]="selectedProductsCount === 0"
                                                            (click)="!isloading && addSimilarToCart()">
                                                            <h5
                                                                class="flex-wrap cursor-pointer d-flex justify-content-between product-title">
                                                                <span class="product_title">
                                                                    {{'addproduct'|translate}} {{selectedProductsCount
                                                                    +"
                                                                    "}}{{'to cart'|translate}} </span>
                                                                <span class="product_title">|</span>
                                                                <div>
                                                                    <span class="product_title">{{ totalPrice }} {{
                                                                        productService?.Currency.name }}</span>
                                                                    <span *ngIf="isloading" class="cutom-loader"></span>
                                                                </div>
                                                            </h5>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="frequently-bought" *ngIf="packageData">
                                        <div class="title">
                                            <h2>
                                                {{'frequently bought together' | translate}}
                                            </h2>
                                        </div>
                                        <div class="main">
                                            <div class="products-images-section">
                                                <div style="display: flex"
                                                    *ngFor="let product of packageData.products ; let i = index">
                                                    <a [routerLink]="'/shop/product/'+ product?.id" target="_blank">
                                                        <div [ngClass]="'single-product-layout'" class="">
                                                            <div class="image-box">
                                                                <img src="{{urlimage+product?.image}}"
                                                                    alt="{{ product?.name }}">
                                                                <!-- <img [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                                [lazyLoad]="urlimage+product?.image" alt="{{ product?.name }}"> -->
                                                            </div>
                                                            <div class="price-box">
                                                                <span>
                                                                    {{product?.price}} {{productService?.Currency.name}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <div class="plus-sign-block" *ngIf="i != 2">
                                                        <i class="ti-plus"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="products-details-section">
                                                <div class="select-product-names-list">
                                                    <div class="single-product-list-item"
                                                        *ngFor="let product of packageData.products ; let index = index">
                                                        <a [routerLink]="'/shop/product/'+ product?.id" target="_blank">
                                                            <i class="ti-angle-left"></i>
                                                            <span
                                                                [ngClass]="freqBoughtCheckboxList[index] ? 'selected' : 'not-selected'">
                                                                {{product?.name}}
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="frequent-bought-total-price-block">
                                                    <span>
                                                        {{'package price' | translate}}
                                                        <span style="color: var(--theme-default)">

                                                            {{newPackagePrice}} {{productService?.Currency.name}}
                                                        </span>

                                                        {{'instead of' | translate}}
                                                        <span style="text-decoration: line-through;">
                                                            {{packageData.total_price}}
                                                            {{productService?.Currency.name}}
                                                        </span>

                                                        {{'save' | translate}}
                                                        {{packageData.discount}} {{productService?.Currency.name}}

                                                    </span>
                                                </div>
                                                <div class="add-selected-frequent-button-layout">
                                                    <div class="add-to-cart-button product-buttons">
                                                        <a href="javascript:void(0)" class="btn btn-animation"
                                                            (click)="addPackageToCart(packageData.products[0], packageData.products[1], packageData.products[2])">
                                                            <i class="fa fa-shopping-cart me-1" aria-hidden="true">
                                                            </i>
                                                            {{'add products to cart' | translate}}
                                                        </a>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tabs-layout-descriptions-desktop">
                                        <div class="tabs-block">
                                            <div class="tab-single"
                                                *ngIf="settings?.full_description_web == '1' && product?.description"
                                                [ngClass]="tabId == 'general' ? 'tab-single-active' : ''"
                                                (click)="tabChange('general')">
                                                <h2 class="">
                                                    {{"General-Description" | translate}}
                                                </h2>

                                            </div>
                                            <div class="tab-single" *ngIf="productFormShapesDetails.length > 0"
                                                [ngClass]="tabId == 'description' ? 'tab-single-active' : ''"
                                                (click)="tabChange('description')">
                                                <h2 class="">
                                                    {{"Specifications" | translate}}
                                                </h2>
                                            </div>
                                            <div class="tab-single"
                                                *ngIf="settings?.promotional_description_web == '1' && product['promotional_description']"
                                                [ngClass]="tabId == 'promotional' ? 'tab-single-active' : ''"
                                                (click)="tabChange('promotional')">
                                                <h2 class="">
                                                    {{"promotional description" | translate}}
                                                </h2>
                                            </div>
                                        </div>

                                        <div class="tab-general-content" *ngIf="tabId === 'general' && settings?.full_description_web == '1' &&
                                            product?.description" [style.text-align]="Common.isRtl ? 'right' : 'left'">

                                            <div class="productDescription" *ngIf="product?.description"
                                                [innerHTML]="product?.description | sanitizeHtml: 'html'">
                                            </div>

                                            <!-- <div class="label-section">
                                                        <span class="badge badge-grey-color" *ngIf="(product?.store_amounts> 0 &&
                                                        product?.price > 0 )">In Stock</span>
                                                        <span class="badge badge-grey-color" *ngIf="!(product?.store_amounts> 0 &&
                                                        product?.price > 0 )">Out of
                                                            Stock</span>
                                                    </div> -->
                                        </div>


                                        <div class="tab-description-content pe-2"
                                            *ngIf="tabId === 'description' && productFormShapesDetails.length != 0">

                                            <div class="no-content" *ngIf="productFormShapesDetails.length == 0">
                                                <img src="assets/images/empty-search1.png" class="img-fluid">
                                                <p>
                                                    {{"No-Specifications" | translate}}
                                                </p>
                                            </div>

                                            <div class="description-content-block">
                                                <div *ngFor="let form of productFormShapesDetails; let index=index;"
                                                    class="discription-content-single"
                                                    [ngClass]="{'red-background-single': 0 === index % 2}">
                                                    <div class="discription-title" style="font-weight: bold">
                                                        <span class="">
                                                            {{form.form_name | translate}}
                                                            <i class="fa fa-exclamation-circle pointer-event "
                                                                *ngIf="!form.form_shape_id"
                                                                (click)="getNoteDetails(3)"></i>
                                                        </span>
                                                    </div>
                                                    <div class="discription-title"
                                                        *ngIf="form.type != 'm_select' && form.type != 'text' &&form.type">
                                                        <span class="">
                                                            <a class="form-shape"
                                                                [routerLink]="['/pages/formshapes/' + form.form_value]">{{form.form_value}}</a>
                                                        </span>
                                                    </div>
                                                    <div class="discription-title" *ngIf="form.type == 'text'">
                                                        <span class="">
                                                            <a>{{form.form_value}}</a>
                                                        </span>
                                                    </div>
                                                    <div class="discription-title" *ngIf="!form.type">
                                                        <span class="">
                                                            <a>{{form.form_value}}</a>
                                                        </span>
                                                    </div>
                                                    <div class="flex-row flex-wrap discription-title"
                                                        *ngIf="form.type == 'm_select'">
                                                        <ng-container
                                                            *ngFor="let select of multi_select[index]; let s=index; last as isLast">
                                                            <a class="form-shape"
                                                                [routerLink]="['/pages/formshapes/' + select]">{{select}}</a>
                                                            <span class="mx-2 d-inline-block" *ngIf="!isLast"> - </span>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <div class="tab-general-content" *ngIf="tabId === 'promotional' && settings?.promotional_description_web == '1' &&
                                            product['promotional_description']"
                                            [style.text-align]="Common.isRtl ? 'right' : 'left'">

                                            <div class="" *ngIf="product['promotional_description']"
                                                [innerHTML]="product['promotional_description'] | sanitizeHtml: 'html'">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 rtl-text add-to-cart-desktop-section">
                                    <div class="product-right ">
                                        <div class="add-to-cart-layout">
                                            <div class="add-to-cart-main">
                                                <div class="price-quantity-block">
                                                    <div class="product-price">
                                                        <h3 class="px-2 py-3 price-detail"
                                                            *ngIf="(product?.price > 0 && product?.store_amounts > 0)">
                                                            <div class="d-flex">
                                                                <del *ngIf="product?.price > 0 && (product?.last_price > 0)"
                                                                    class="d-block">
                                                                    {{ product?.last_price }} {{currency?.currency}}
                                                                </del>
                                                                <div *ngIf="product?.price> 0 && (product?.last_price > 0)"
                                                                    class="mx-3 discount-block align-content-center">
                                                                    {{'discount' | translate}}
                                                                    {{
                                                                    getDiscountPercentage(product?.last_price,product?.price)
                                                                    + "%" }}
                                                                </div>
                                                            </div>
                                                        </h3>

                                                    </div>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div class="px-2 product-price"
                                                            *ngIf="((product?.store_amounts !== 0 && product?.price !== 0) || (product?.store_amounts !== 0 && product?.price!> 0)) && ((product?.price > 0 && product?.store_amounts > 0))">
                                                            <div
                                                                class="d-flex align-items-center justify-content-between w-100">
                                                                <span
                                                                    style="font-size: 24px; color: var(--theme-default); font-weight:600;">
                                                                    {{ product?.price | discount:product }}
                                                                    {{ currency?.currency }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="image-shipping"
                                                            *ngIf="product.product_group_data&&product.product_group_data.img">
                                                            <span class="shipping-icon"
                                                                *ngIf="product.product_group_data.description"
                                                                (click)="shippingModal(product.product_group_data.description)">
                                                                <i class="fa fa-solid fa-question"></i>
                                                            </span>
                                                            <img [src]="Common._ImageUrl + 'groups/' + product.product_group_data.img"
                                                                alt="Shipping image" width="auto" height="20"
                                                                (error)="handleImageError($event)">
                                                        </div>
                                                    </div>

                                                    <div class="p-2 my-2 address-block">
                                                        <p>
                                                            {{'Please select the city to determine the shipping cost' |
                                                            translate}}
                                                        </p>
                                                        <div class="p-0 container-fluid">
                                                            <div class="row">
                                                                <div class="mb-0 form-group col-md-12 col-sm-12 col-xs-12"
                                                                    [class.select-rtl]="Common.isRtl">
                                                                    <ng-select [clearable]="false" [items]="cities"
                                                                        bindLabel="pla_name" bindValue="id"
                                                                        [(ngModel)]="city_id"
                                                                        (change)="onChangeCity(city_id)"
                                                                        [clearOnBackspace]="false"
                                                                        [placeholder]="'select your city'|translate"
                                                                        [notFoundText]="('noItemsFoundCity'|translate)"
                                                                        (clear)="onClearCity()">
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngFor="let date of ExpectedDate; let i = index"
                                                            class="mt-2 panel-contianer"
                                                            [class.active-panel]="shipment_id == currentCity.shipping_methods[i]?.shipment_id">
                                                            <mat-accordion>
                                                                <mat-expansion-panel [expanded]="true"
                                                                    [hideToggle]="true" [disabled]="true"
                                                                    (click)="setOpenPanel(i,currentCity.shipping_methods[i]?.shipment_id)"
                                                                    class="m-0">
                                                                    <mat-expansion-panel-header style="padding: 10px;">
                                                                        <mat-panel-title>
                                                                            <input type="radio"
                                                                                name="shipping_company_desktop"
                                                                                id="shipping_company_{{i}}"
                                                                                [(ngModel)]="shipment_id"
                                                                                [value]="currentCity.shipping_methods[i]?.shipment_id">
                                                                            <span class="mb-0">
                                                                                {{currentCity.shipping_methods[i]?.shipment_company}}
                                                                            </span>
                                                                        </mat-panel-title>
                                                                    </mat-expansion-panel-header>

                                                                    <div
                                                                        class="delivery-price mat-expansion-panel-body-custom">
                                                                        <span class="mt-2"
                                                                            *ngIf="currentCity.shipping_methods[i]?.delivery_price">
                                                                            {{'deliver with' | translate}} {{
                                                                            currentCity.shipping_methods[i]?.delivery_price
                                                                            }} {{' '+currency?.currency}}
                                                                        </span>
                                                                        <br />
                                                                        <span *ngIf="date?.start || date?.end">
                                                                            {{'Expected Delivery Time' | translate}}
                                                                            <ng-container
                                                                                *ngIf="date?.start && date?.end; else singleDate">

                                                                                {{'deliver between' | translate}}

                                                                                {{date.start.getDate() + ' ' +
                                                                                months[date.start.getMonth() +
                                                                                1][frontLang]}}

                                                                                {{'to' | translate}}

                                                                                {{date.end.getDate() + ' ' +
                                                                                months[date.end.getMonth() +
                                                                                1][frontLang]}}
                                                                            </ng-container>
                                                                            <ng-template #singleDate>
                                                                                {{
                                                                                date?.start ?
                                                                                date.start.getDate() + ' ' +
                                                                                months[date.start.getMonth() +
                                                                                1][frontLang]
                                                                                :
                                                                                date.end.getDate() + ' ' +
                                                                                months[date.end.getMonth() +
                                                                                1][frontLang]
                                                                                }}
                                                                            </ng-template>
                                                                        </span>
                                                                        <span *ngIf="!date?.start && !date?.end">
                                                                            {{'can not expect date' | translate}}
                                                                        </span>
                                                                        <p *ngIf="(date?.start || date?.end) && !timerOff[i]"
                                                                            class="my-1"
                                                                            style="color:var(--theme-default)">
                                                                            {{'if you order that before' | translate}}
                                                                            {{countdown[i]?.hours }}
                                                                            {{'hours' | translate}}
                                                                            {{countdown[i]?.minutes }}
                                                                            {{'minutes' | translate}}
                                                                            {{countdown[i]?.seconds }}
                                                                            {{'seconds' | translate}}
                                                                        </p>
                                                                    </div>

                                                                </mat-expansion-panel>
                                                            </mat-accordion>
                                                        </div>
                                                    </div>
                                                    <div class="product-quantity"
                                                        *ngIf="((product?.store_amounts !== 0 && product?.price !== 0) || (product?.store_amounts !== 0 && product?.price!> 0)) && ((product?.price > 0 && product?.store_amounts > 0))">
                                                        <div *ngIf="(product?.store_amounts !== 0 && product?.price !== 0) || (product?.store_amounts !== 0 && product?.price!> 0) "
                                                            class=" product-description border-product">
                                                            <div class="errorOccurred" *ngIf="quantatyMorThanStor">
                                                                {{'quantaty Mor Than Stor' | translate}}
                                                                <br>
                                                                <br>
                                                            </div>

                                                            <!-- <div class="errorOccurred" *ngIf="userNotLogedInError && deviceID">
                                                                                                    {{'please login' | translate}}
                                        
                                                                                                    <br>
                                                                                                    <br>
                                                                                                </div> -->
                                                            <div
                                                                class="d-flex align-items-center justify-content-between w-100">
                                                                <span style="font-size: 24px;font-weight:600;">
                                                                    {{ 'quantity' | translate}}
                                                                </span>
                                                                <div class="qty-box">
                                                                    <div class="input-group">
                                                                        <span class="input-group-prepend">
                                                                            <button type="button"
                                                                                class="btn quantity-left-minus"
                                                                                data-type="minus" (click)="decrement()">
                                                                                <i class="ti-minus"></i>
                                                                            </button>
                                                                        </span>
                                                                        <input type="text" name="quantity"
                                                                            class="form-control input-number"
                                                                            [value]="counter" disabled />
                                                                        <span class="input-group-prepend">
                                                                            <button type="button"
                                                                                class="btn quantity-right-plus"
                                                                                data-type="plus" (click)="increment()">
                                                                                <i class="ti-plus"
                                                                                    aria-hidden="true"></i>
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="notify-me-button" *ngIf="product?.active == 2"
                                                    [attr.disabeld]="true">
                                                    <a class="btn btn-animation" (click)="getNoteDetails(2)">
                                                        <i class="fa fa-bell" aria-hidden="true"></i>
                                                        {{'Inventory is in progress' | translate}}
                                                    </a>
                                                </div>
                                                <div class="notify-me-button"
                                                    *ngIf="(product?.store_amounts == 0 && product?.price == 0) || (product?.store_amounts == 0 && product?.price > 0) ">
                                                    <a class="btn btn-animation"
                                                        [class.disabled]="counter > product?.stock">
                                                        {{'out of stock' | translate}}
                                                    </a>
                                                </div>
                                                <div class="notify-me-button"
                                                    *ngIf="(product?.store_amounts == 0 && product?.price == 0) ||(product?.store_amounts == 0 && product?.price> 0) ">
                                                    <a class="btn btn-animation"
                                                        (click)="openNotifyMeModal(notifyModal, product?.id)">
                                                        <i class="fa fa-bell" aria-hidden="true"></i>
                                                        {{'Notify me' | translate}}
                                                    </a>
                                                </div>
                                                <div class="add-to-cart-button d-flex flex-column align-items-stretch"
                                                    *ngIf="product?.active != 2"
                                                    [ngClass]="(product?.store_amounts == 0 && product?.price == 0) ||(product?.store_amounts == 0 && product?.price> 0) ? 'product-buttons-out-of-stock' : 'product-buttons'">
                                                    <a *ngIf="(product?.store_amounts> 0 && product?.price > 0 && +product?.quantity_in_cart ==0)"
                                                        href="javascript:void(0)" class="btn btn-animation"
                                                        [class.disabled]="counter > product?.stock"
                                                        (click)="addToCart(product)">
                                                        <i class="fa fa-shopping-cart me-1" aria-hidden="true"></i>
                                                        {{'add-to-cart' | translate}}
                                                    </a>
                                                    <!-- <a *ngIf="(product?.store_amounts == 0 && product?.price == 0) || (product?.store_amounts == 0 && product?.price > 0) "
                                                                                            href="javascript:void(0)" class="btn btn-animation"
                                                                                            [class.disabled]="counter > product?.stock">
                                                                                            {{'out of stock' | translate}}
                                                                                        </a> -->


                                                    <a *ngIf="(product?.store_amounts> 0 && product?.price == 0) "
                                                        (click)="getNoteDetails(1)" class="mb-3 btn1 btn-animation"
                                                        [class.disabled]="counter > product?.stock">
                                                        {{'Soon' | translate}}
                                                    </a>


                                                    <a *ngIf="+product?.quantity_in_cart >0" href="javascript:void(0)"
                                                        class="btn btn-product-added" [class.disabled]="true">
                                                        {{'product-added' | translate}}
                                                    </a>


                                                    <div class="buy-now-button"
                                                        *ngIf="(product?.store_amounts !== 0 && product?.price > 0)">
                                                        <a href="javascript:void(0)" class="btn btn-solid"
                                                            [class.disabled]="counter> product?.stock"
                                                            (click)="buyNow(product)">
                                                            {{'buy now' | translate}}
                                                        </a>
                                                    </div>

                                                </div>

                                                <!-- 
                                                                                    <div class="border-product">
                                                                                        <div class="product-icon">
                                                                                            <form class="d-inline-block">
                                                                                                <button class="wishlist-btn" (click)="addToWishlist(product)">
                                                                                                    <i class="fa fa-heart"
                                                                                                        [ngClass]="isFavourit ? 'text-danger' : 'text-icon'"></i>
                                                                                                </button>
                                                                                                <span class="title-font font-weight-bold">{{'Add To WishList' |
                                                                                                    translate}}</span>
                                                                                            </form>
                                                                                        </div>
                                                                                    </div> -->


                                                <div class="share-via-whatsapp"
                                                    *ngIf="customer_service?.product_page?.length > 0 ">
                                                    <button (click)="shareViaWhatapp(customer_service?.product_page[0])"
                                                        class="btn btn-animation">

                                                        <img [lazyLoad]="labelWhatAppUrl" alt="label-icon" width="35"
                                                            height="35" [defaultImage]="'assets/images/whatsapp.png'">
                                                        <span>
                                                            {{customer_service?.product_page[0]?.label}}
                                                        </span>
                                                    </button>
                                                </div>

                                            </div>
                                            <div class="privacy-details" *ngIf="privacyDetails?.pages?.length > 0">
                                                <div class="privacy-title" #privacyTitle>
                                                    <h3 style="margin: 0;">
                                                        {{privacyDetails?.name}}
                                                    </h3>
                                                </div>
                                                <div class="pt-2 bg-white">
                                                    <div class="privacy-list"
                                                        *ngFor="let page of privacyDetails?.pages; let isLast=last;">
                                                        <div class="item-title">
                                                            <div class="d-flex align-items-start">
                                                                <img [lazyLoad]="supportIcon + page.img"
                                                                    alt="label-icon" width="30" height="35"
                                                                    [defaultImage]="'assets/images/privacy.png'"
                                                                    width="25" height="25" alt="list-icon" />
                                                                <div class="d-flex flex-column">
                                                                    <h6>{{page.name}}</h6>
                                                                    <div class="mt-0 item-description text-muted"
                                                                        style="text-align: start;"
                                                                        *ngIf="page.description">
                                                                        <p>
                                                                            {{page.description}}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span *ngIf="page?.page_id !== '0'"
                                                                (click)="showPrivacyDetails(page?.page_id)"
                                                                class="cursor-pointer text-info">
                                                                {{'details' | translate}}
                                                            </span>
                                                        </div>
                                                        <hr *ngIf="!isLast" style="
                                                        margin-top: 10px;
                                                        margin-bottom: -10px;
                                                        border-style: dashed;
                                                        ">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="add-to-cart-main"
                                                *ngIf="product?.api_id_to > 0 || product?.seller_name !='' || settings?.front_name !=''">
                                                <div class="price-quantity-block">
                                                    <div class="sell-item" *ngIf="product?.seller_name !=''">
                                                        <h3 class="price-detail seller-item-content d-flex seller-title-style align-items-center"
                                                            [class.seller-title-no-style]="product?.api_id_to <= 0">
                                                            <div class="logo-container">
                                                                <img width="20" height="20"
                                                                    [src]="Common._ImageUrl +'front_images/'+settings['front_fav_icon']"
                                                                    alt="" srcset="">
                                                            </div>
                                                            {{'seller' | translate}}:
                                                            <span
                                                                class="company company-name">{{product?.seller_name}}</span>
                                                        </h3>
                                                    </div>
                                                    <div class="my-2 sell-item" *ngIf="settings?.front_name !=''">
                                                        <h3
                                                            class="pb-2 price-detail seller-item-content d-inline-block seller-title-style">
                                                            <img width="25" height="25"
                                                                src="assets/images/warehouse.webp"
                                                                style="margin-inline-start: 3px;margin-inline-end:2px;"
                                                                alt="warehouse">
                                                            {{'deliver from' | translate}}:<span
                                                                class="company company-name">{{settings?.front_name}}</span>
                                                        </h3>
                                                    </div>
                                                    <div class="product-price" *ngIf="product?.api_id_to > 0">
                                                        <h3 class="price-detail d-inline-block company-title-style"
                                                            *ngIf="(product?.price > 0 && product?.store_amounts > 0)">
                                                            <img width="35" height="35"
                                                                style="border: 2px solid #DDD; border-radius: 50%;"
                                                                [src]="importApiImage + product?.details_store_external?.image"
                                                                alt="" srcset="">
                                                            <svg class="w-6 h-6 text-gray-800 dark:text-white"
                                                                aria-hidden="true"
                                                                *ngIf="product?.details_store_external?.image == ''"
                                                                xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 20 18">
                                                                <path stroke="currentColor" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2"
                                                                    d="M10 12.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z" />
                                                                <path stroke="currentColor" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2"
                                                                    d="M17 3h-2l-.447-.894A2 2 0 0 0 12.764 1H7.236a2 2 0 0 0-1.789 1.106L5 3H3a2 2 0 0 0-2 2v11a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V5a2 2 0 0 0-2-2Z" />
                                                            </svg>
                                                            {{ product?.details_store_external?.name }}
                                                        </h3>
                                                    </div>

                                                    <div class="product-quantity"
                                                        *ngIf="((product?.store_amounts !== 0 && product?.price !== 0) || (product?.store_amounts !== 0 && product?.price!> 0) )&& product?.api_id_to > 0 ">
                                                        <h5 class="text-danger" style="font-size: 10px;
                                                        font-weight: bold;
                                                        margin-right: 10px;
                                                        width: fit-content;">
                                                            {{ ("This product ships from " | translate ) +
                                                            product?.details_store_external?.name}} </h5>
                                                    </div>
                                                </div>

                                            </div>
                                            <!-- <div class="add-to-cart-shipping ">
                                                <div class="single-block">
                                                    <div class="image-side">
                                                        <img src="assets/images/product/delivery1.png">
                                                    </div>
                                                    <div class="feature-title-side">
                                                        <span>
                                                            {{'home delivery' | translate}}
                                                        </span>
    
                                                    </div>
                                                </div>
                                                <div class="single-block">
                                                    <div class="image-side">
                                                        <img src="assets/images/product/pod.png">
                                                    </div>
                                                    <div class="feature-title-side">
                                                        <span>
                                                            {{'pod' | translate}}
                                                        </span>
    
                                                    </div>
                                                </div>
                                                <div class="single-block">
                                                    <div class="image-side">
                                                        <img src="assets/images/product/support.png">
                                                    </div>
                                                    <div class="feature-title-side">
                                                        <span>
                                                            {{'technical support on orders' | translate}}
                                                        </span>
    
                                                    </div>
                                                </div>
    
                                            </div> -->

                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div class="mobile-product-info-layout">
                                <div>
                                    <div class="list-style ">
                                        <ul>
                                            <li class="price"
                                                *ngIf="(product?.price > 0 && product?.store_amounts > 0) && getDiscountPercentage(product?.last_price,product?.price) > 0">
                                                <div class="price-container d-flex align-items-center">
                                                    <del class="price-text">
                                                        {{ product?.last_price ? product?.last_price : product?.price }}
                                                        {{currency?.currency}}
                                                    </del>
                                                    <span class="mx-3 badge badge-pill badge-danger"
                                                        *ngIf="product?.price> 0 && (product?.last_price > 0)">
                                                        {{ getDiscountPercentage(product?.last_price,product?.price)
                                                        + "% -" }}
                                                    </span>
                                                </div>
                                            </li>
                                            <li class="last-price"
                                                *ngIf="(product?.price > 0 && product?.store_amounts > 0)">
                                                <div class="last-price-container">
                                                    {{ (product?.price) }}
                                                    {{ currency?.currency }}
                                                </div>
                                                <span class="tax" style="font-weight: 300;"
                                                    *ngIf="settings?.including_value_added_tax == '1'">
                                                    {{'including TAX' | translate}}
                                                </span>
                                            </li>
                                            <li class="d-flex align-items-center w-100"
                                                *ngIf="(product?.store_amounts > 0 && product?.price > 0 &&product?.active=='1')">
                                                <!-- <i class="fa fa-check-circle-o text-success check-container" aria-hidden="true"></i> -->
                                                <div class="d-flex align-items-center">
                                                    <img src="assets/images/icon/healthy (1)/healthy (1).webp"
                                                        width="30" height="30" alt="out">
                                                    <span class="text-success spacer-marg"
                                                        style="font-weight: 600 !important;white-space:nowrap">
                                                        {{'available' | translate}}
                                                    </span>
                                                </div>

                                                <div class="image-shipping "
                                                    *ngIf="product.product_group_data&&product.product_group_data.img">
                                                    <span class="shipping-icon"
                                                        *ngIf="product.product_group_data.description"
                                                        (click)="shippingModal(product.product_group_data.description)">
                                                        <i class="fa fa-solid fa-question"></i>
                                                    </span>
                                                    <img [src]="Common._ImageUrl + 'groups/' + product.product_group_data.img"
                                                        alt="Shipping image" width="auto" height="20"
                                                        (error)="handleImageError($event)">
                                                </div>
                                            </li>

                                            <li class="d-flex align-items-center w-100"
                                                *ngIf="(product?.store_amounts == 0 && product?.price == 0) ||(product?.store_amounts == 0 && product?.price > 0) ">
                                                <div class="d-flex align-items-center">
                                                    <img src="assets/images/icon/Downloads/out100.webp" width="30"
                                                        height="30" alt="out">
                                                    <span class="text-danger spacer-marg"
                                                        style="font-weight:600 !important;white-space:nowrap">
                                                        {{'out of stock' | translate}}
                                                    </span>
                                                </div>
                                                <div class="image-shipping "
                                                    *ngIf="product.product_group_data&&product.product_group_data.img">
                                                    <span class="shipping-icon"
                                                        *ngIf="product.product_group_data.description"
                                                        (click)="shippingModal(product.product_group_data.description)">
                                                        <i class="fa fa-solid fa-question"></i>
                                                    </span>
                                                    <img [src]="Common._ImageUrl + 'groups/' + product.product_group_data.img"
                                                        alt="Shipping image" width="auto" height="20"
                                                        (error)="handleImageError($event)">
                                                </div>
                                            </li>

                                            <li class="d-flex align-items-center w-100"
                                                *ngIf="(product?.store_amounts> 0 && product?.price == 0) ">
                                                <div class="d-flex align-items-center">
                                                    <img src="assets/images/icon/Downloads/coming soon.webp" width="30"
                                                        height="30" alt="soon">
                                                    <span class="soon-status spacer-marg"
                                                        style="font-weight:600 !important;white-space:nowrap">
                                                        {{'Will Soon' | translate}}
                                                    </span>
                                                </div>
                                                <div class="image-shipping "
                                                    *ngIf="product.product_group_data&&product.product_group_data.img">
                                                    <span class="shipping-icon"
                                                        *ngIf="product.product_group_data.description"
                                                        (click)="shippingModal(product.product_group_data.description)">
                                                        <i class="fa fa-solid fa-question"></i>
                                                    </span>
                                                    <img [src]="Common._ImageUrl + 'groups/' + product.product_group_data.img"
                                                        alt="Shipping image" width="auto" height="20"
                                                        (error)="handleImageError($event)">
                                                </div>
                                            </li>

                                            <li *ngIf="product?.active=='2'" class="d-flex align-items-center w-100">
                                                <div class="d-flex align-items-center">
                                                    <img src="assets/images/icon/Downloads/stocktaking2.webp" width="30"
                                                        height="30" alt="out">
                                                    <span class="text-danger spacer-marg"
                                                        style="font-weight: 600 !important;white-space:nowrap ">
                                                        {{'Wiil Inventory is in progress' | translate}}
                                                    </span>
                                                </div>
                                                <div class="image-shipping "
                                                    *ngIf="product.product_group_data&&product.product_group_data.img">
                                                    <span class="shipping-icon"
                                                        *ngIf="product.product_group_data.description"
                                                        (click)="shippingModal(product.product_group_data.description)">
                                                        <i class="fa fa-solid fa-question"></i>
                                                    </span>
                                                    <img [src]="Common._ImageUrl + 'groups/' + product.product_group_data.img"
                                                        alt="Shipping image" width="auto" height="20"
                                                        (error)="handleImageError($event)">
                                                </div>
                                            </li>

                                            <li class="my-1 original d-flex align-items-center"
                                                *ngFor="let item of product?.product_options">
                                                <!-- <div class="original-container" *ngIf="!item?.img">
                                                    <i class="fa fa-thumbs-up" aria-hidden="true" style="color: #FFEE02;"></i>
                                                </div> -->
                                                <img [src]="mainUrlimage + item?.img" width="25px" height="25px"
                                                    *ngIf="item?.img" alt="done-icon">
                                                <span class="original-name">
                                                    {{item?.name}}
                                                </span>
                                                <span *ngIf="item?.page_id !== '0'" class="pt-1 mx-2 cursor-pointer"
                                                    (click)="showPrivacyDetails(item?.page_id , item?.name)">
                                                    <img src="assets/images/icon/info.png" alt="info" width="20px"
                                                        height="20px" />
                                                </span>
                                            </li>
                                            <li *ngIf="product?.product_barcode"
                                                class="flex-nowrap d-flex spacer-marg-link align-items-center">
                                                <img src="assets/images/icon/Downloads/barcode-scan100.webp" width="30"
                                                    height="30" alt="out">
                                                <span class="mx-1 info-key-title">
                                                    {{'barcode' | translate}}:
                                                </span>
                                                <span class="spacer-marg">
                                                    {{product?.product_barcode}}
                                                </span>
                                            </li>

                                            <div class="my-2 address-block">
                                                <p class="mb-0">
                                                    {{'Please select the city to determine the shipping cost' |
                                                    translate}}
                                                </p>
                                                <div style="display: flex; gap:5px; padding:0"
                                                    class="mt-2 mb-0 form-group text-start">
                                                    <img src="./assets/images/map.webp" width="20" alt="icon">
                                                    <!-- <i class="fa fa-map-marker" aria-hidden="true"></i> -->
                                                    <span>
                                                        {{'deliver to' | translate}}
                                                    </span>
                                                    <button class="p-0 border-none btn" (click)="openBottomSheet()"
                                                        style="color:#0062d1;font-weight: 400;">
                                                        <span class="p-0">
                                                            {{currentCity ? currentCity.pla_name : 'city select' |
                                                            translate}}
                                                        </span>


                                                    </button>
                                                </div>
                                            </div>

                                            <div *ngFor="let date of ExpectedDate; let i = index"
                                                class="mt-2 panel-contianer"
                                                [class.active-panel]="shipment_id == currentCity.shipping_methods[i]?.shipment_id">
                                                <mat-accordion>
                                                    <mat-expansion-panel [expanded]="true" [hideToggle]="true"
                                                        [disabled]="true"
                                                        (click)="setOpenPanel(i,currentCity.shipping_methods[i]?.shipment_id)"
                                                        class="m-0">
                                                        <mat-expansion-panel-header style="padding: 10px;">
                                                            <mat-panel-title>
                                                                <input type="radio" name="shipping_company"
                                                                    id="shipping_company_{{i}}"
                                                                    [(ngModel)]="shipment_id"
                                                                    [value]="currentCity.shipping_methods[i]?.shipment_id">
                                                                <span class="mb-0">
                                                                    {{currentCity.shipping_methods[i]?.shipment_company}}
                                                                </span>
                                                            </mat-panel-title>
                                                        </mat-expansion-panel-header>

                                                        <div class="delivery-price mat-expansion-panel-body-custom">
                                                            <span class="mt-2" *ngIf="currentCity.delivery_price">
                                                                {{'deliver with' | translate}} {{
                                                                currentCity.shipping_methods[i].delivery_price
                                                                }} {{' '+currency?.currency}}
                                                            </span>
                                                            <br />
                                                            <span *ngIf="date?.start || date?.end">
                                                                {{'Expected Delivery Time' | translate}}
                                                                <ng-container
                                                                    *ngIf="date?.start && date?.end; else singleDate">

                                                                    {{'deliver between' | translate}}

                                                                    {{date.start.getDate() + ' ' +
                                                                    months[date.start.getMonth() + 1][frontLang]}}

                                                                    {{'to' | translate}}

                                                                    {{date.end.getDate() + ' ' +
                                                                    months[date.end.getMonth() + 1][frontLang]}}
                                                                </ng-container>
                                                                <ng-template #singleDate>
                                                                    {{
                                                                    date?.start ?
                                                                    date.start.getDate() + ' ' +
                                                                    months[date.start.getMonth() + 1][frontLang]
                                                                    :
                                                                    date.end.getDate() + ' ' +
                                                                    months[date.end.getMonth() + 1][frontLang]
                                                                    }}
                                                                </ng-template>
                                                            </span>
                                                            <span *ngIf="!date?.start && !date?.end">
                                                                {{'can not expect date' | translate}}
                                                            </span>
                                                            <p *ngIf="(date?.start || date?.end) && !timerOff[i]"
                                                                class="my-1" style="color:var(--theme-default)">
                                                                {{'if you order that before' | translate}}
                                                                {{countdown[i]?.hours }}
                                                                {{'hours' | translate}}
                                                                {{countdown[i]?.minutes }}
                                                                {{'minutes' | translate}}
                                                                {{countdown[i]?.seconds }}
                                                                {{'seconds' | translate}}
                                                            </p>
                                                        </div>

                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                            </div>

                                        </ul>
                                    </div>
                                </div>


                                <div *ngIf="optionsData.length>1">
                                    <hr>
                                    <span class="mb-3 product-title added-title">
                                        {{'Choose an option'|translate}}
                                    </span>
                                    <div class="flex-wrap gap-3 d-flex">
                                        <div (click)="chooseOptions(i,item)" class="option-choose-data"
                                            [class.active]="i==optionChekced"
                                            *ngFor="let item of optionsData let first=first let i =index">
                                            <span>{{item.name}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <hr *ngIf="ProductQuantity >= 1">
                                    <span class="mb-3 product-title added-title" *ngIf="ProductQuantity >= 1">
                                        {{'Enjoy the quantitative discount'|translate}}
                                    </span>
                                    <ul class="row" *ngIf="ProductQuantity >= 1">
                                        <li class="col-md-10" *ngFor="let disc of quantity_discount let d=index"
                                            [class.d-none]="!(disc.quantity <= this.product?.stores[0]['store_amounts_product'] && +(disc.quantity) > 1)">
                                            <ng-container
                                                *ngIf="disc.quantity <= this.product?.stores[0]['store_amounts_product'] && +(disc.quantity) > 1">
                                                <div class="mb-2 cursor-pointer d-flex justify-content-between align-items-center discount-item"
                                                    (click)="addProductDiscountToCart(disc)">
                                                    <div>
                                                        <img src="assets/images/icon/Downloads/Quantity discount.webp"
                                                            width="25" alt="">
                                                    </div>
                                                    <div
                                                        class="d-flex flex-column justify-content-center align-items-start">
                                                        <div class="d-flex align-items-center">
                                                            <p class="px-2 m-0 text-black text-size p-line related-text"
                                                                *ngIf="disc.purchase_text !='' && disc.purchase_text!=null">
                                                                {{disc.purchase_text}}</p>

                                                            <p class="px-2 m-0 text-black text-size p-line related-text"
                                                                *ngIf="disc.purchase_text =='' || disc.purchase_text==null">
                                                                {{'buy'|translate}}
                                                                <span class="text-main">
                                                                    {{disc.quantity}}
                                                                </span>
                                                                {{'price'|translate}}
                                                                {{disc.quantity*disc.unit_price}} {{ currency?.currency
                                                                }}

                                                            </p>

                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <p class="px-2 m-0 text-black text-size related-text">
                                                                {{'piece' | translate}}
                                                            </p>
                                                            <p class="m-0 text-black text-size related-text">
                                                                <span class="text-main">
                                                                    {{disc.unit_price}}
                                                                    {{currency?.currency }}
                                                                </span>
                                                                {{'instead of' | translate}}
                                                            </p>
                                                            <p style="text-decoration: line-through;"
                                                                class="px-1 m-0 text-black text-muted text-size related-text"
                                                                *ngIf="unitPricefor1quantity">
                                                                {{product?.price
                                                                }} {{ currency?.currency }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>

                                        </li>

                                    </ul>
                                </div>
                                <div>
                                    <hr *ngIf="productFormShapesDetails.length > 0">
                                    <span class="mb-2 product-title added-title"
                                        *ngIf="productFormShapesDetails.length >0 ">
                                        {{"Specifications" | translate}}:
                                    </span>

                                    <div class="list-style">
                                        <ul class="d-flex flex-column align-items-start ">
                                            <li *ngFor="let form of productFormShapesDetails; let index=index; let last=last"
                                                class="flex-nowrap d-flex spacer-marg-link " [class.p-0]="last">
                                                <i class="fa fa-solid fa-circle"></i>
                                                <span class="info-key-title" style="white-space: nowrap;">
                                                    {{form.form_name | translate}}<i
                                                        class="fa fa-exclamation-circle pointer-event "
                                                        *ngIf="!form.form_shape_id"
                                                        (click)="getNoteDetails(3)"></i>:&nbsp;
                                                </span>
                                                <span style="color: #222222;"
                                                    *ngIf="form.type != 'm_select' && form.type != 'text' &&form.type">
                                                    <a style="text-decoration: underline;"
                                                        [routerLink]="['/pages/formshapes/' + form.form_value]">{{form.form_value}}</a>

                                                </span>
                                                <span style="color: #222222;" *ngIf="form.type == 'text'">
                                                    <a>{{form.form_value}}</a>
                                                </span>
                                                <span style="color: #222222;" *ngIf="!form.type">
                                                    <a>{{form.form_value}}</a>
                                                </span>
                                                <div class="flex-wrap d-flex align-items-start">
                                                    <ng-container *ngIf="form.type == 'm_select'">
                                                        <span style="color: #222222; white-space:nowrap"
                                                            *ngFor="let select of multi_select[index]; let s=index; last as isLast">
                                                            <a style="text-decoration: underline;"
                                                                [routerLink]="['/pages/formshapes/' + select]">{{select}}
                                                                <span class="mx-2 d-inline-block" *ngIf="!isLast"> -
                                                                </span>
                                                            </a>

                                                        </span>
                                                    </ng-container>
                                                </div>


                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div *ngIf="isMobile &&showDirectRequestComponent" #directRequestContainerSmall
                                    class="direct-request-phone">
                                    <app-direct-request *ngIf="product?.api_id_to=='0'" #directRequestComponentSmall
                                        [product_type]="optionItemData"
                                        [store_amount]="optionItemData?.store_amounts_product"
                                        [product_id]="id_of_product" (directRequestAdded)="onDirectRequestAdded()"
                                        [product]="product"></app-direct-request>
                                </div>
                                <hr>
                                <div class="privacy-details" *ngIf="privacyDetails?.pages?.length > 0">
                                    <div class="privacy-title" #privacyTitle>
                                        <h3 style="margin: 0;">
                                            {{privacyDetails?.name}}
                                        </h3>
                                    </div>
                                    <div class="pt-2 bg-white">
                                        <div class="privacy-list"
                                            *ngFor="let page of privacyDetails?.pages;let isLast = last">

                                            <div class="item-title">
                                                <div class="d-flex align-items-start">
                                                    <img [lazyLoad]="supportIcon + page.img" alt="label-icon" width="30"
                                                        height="35" [defaultImage]="'assets/images/privacy.png'"
                                                        width="25" height="25" alt="list-icon" />
                                                    <div class="d-flex flex-column">
                                                        <h6>{{page.name}}</h6>
                                                        <div class="mt-0 item-description text-muted"
                                                            style="text-align: start;" *ngIf="page.description">
                                                            <p>
                                                                {{page.description}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <i *ngIf="page?.page_id !== '0'&&Common.isRtl"
                                                    class="fa fa-chevron-left" aria-hidden="true"
                                                    (click)="showPrivacyDetails(page?.page_id)"></i>
                                                <i *ngIf="page?.page_id !== '0'&&!Common.isRtl"
                                                    class="fa fa-chevron-right" aria-hidden="true"
                                                    (click)="showPrivacyDetails(page?.page_id)"></i>
                                            </div>
                                            <hr *ngIf="!isLast" style="
                                            margin-top: 10px;
                                            margin-bottom: 0px;
                                            border-style: dashed;
                                            ">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="container-mobile">
                                <hr *ngIf="related_product?.length > 1">
                                <span class="mb-3 product-title added-title" *ngIf="related_product?.length > 1">
                                    {{'Other options are available'|translate}}
                                </span>
                                <ng-container *ngIf="related_product?.length > 1">
                                    <div class="options-container">
                                        <div class="text-center options-item" [class.active]="index == 0"
                                            [class.cursor-pointer]="index != 0"
                                            *ngFor="let prod of related_product; let index=index"
                                            [routerLink]="index != 0 ? ['/shop/product/', prod.id, prod.meta_url ? prod.meta_url.split(' ').join('-').replace('+', '') : removeBrackets(prod.product_name)?.split(' ').join('-').replace('+', '')] : null">

                                            <p class="text-center text-black option_title text-size">
                                                {{prod.related_details}}</p>
                                            <div
                                                *ngIf="(prod.store_amounts > 0 && prod.price > 0 && prod?.stores?.length == 1)">
                                                <span class="mr-2 text-black text-size"
                                                    [class.text-red]="prod.last_price"
                                                    [class.text-black]=" !prod.last_price">{{prod.price}}
                                                    {{productService?.Currency.name}}</span>
                                                <span class="mr-2 text-black text-muted text-size"
                                                    style="text-decoration: line-through;"
                                                    *ngIf="prod.last_price">{{prod.last_price}}
                                                    {{productService?.Currency.name}}</span>
                                            </div>
                                            <span class="text-stock text-size"
                                                *ngIf="(prod.store_amounts == 0 && prod.price == 0) || (prod.store_amounts == 0 && prod.price > 0)">
                                                {{'Out of stock' | translate}}
                                            </span>
                                            <span class="text-soon text-size"
                                                *ngIf="(prod.store_amounts > 0 && prod.price == 0)">
                                                {{'Soon' | translate}}
                                            </span>
                                        </div>
                                    </div>
                                </ng-container>

                                <hr *ngIf="similar_product?.length > 1">

                                <div class=" similar-container container-fit position-relative"
                                    *ngIf="similar_product?.length > 1 &&similar_product?.length <3">
                                    <h2 class="product-title" [style.color]="calculateTextColor('--theme-default')">
                                        {{'Products that are frequently purchased together'|translate}}</h2>
                                    <div class="similar-product d-flex">
                                        <ng-container *ngIf="similar_product?.length < 3">
                                            <div class="d-flex align-items-center related-products-slide1"
                                                *ngFor="let sProduct of similar_product; let s = index; last as isLast">
                                                <div class="product-box">
                                                    <div class="product-box-inner">
                                                        <div class="img-wrapper position-relative">
                                                            <input type="checkbox" class="form-check product-check"
                                                                [checked]="sProduct.checked"
                                                                (change)="toggleProductChecked(s)">
                                                            <div class="text-center" [class.cursor-pointer]="s != 0"
                                                                [routerLink]="s != 0 ?  ['/shop/product/', sProduct.id , sProduct.meta_url ? sProduct.meta_url?.split(' ').join('-').replace('+' , '') : removeBrackets(sProduct.product_name)?.split(' ').join('-').replace('+' , '') ] :null">
                                                                <div class="front">
                                                                    <a>
                                                                        <div class="text-center image-frame">
                                                                            <img [src]="(sProduct.images.length > 0 ? urlimage + sProduct.images[0].original_name : 'assets/images/product/placeholder.jpg')"
                                                                                [lazyLoad]="urlimage + sProduct.original_name"
                                                                                class="img-fluid lazy-loading"
                                                                                (error)="sProduct.images.length = 0; $event.target.src = 'assets/images/product/placeholder.jpg';"
                                                                                alt="Product Image" />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <h3 class="text-center text-black text-size">
                                                                    {{
                                                                    sProduct.product_name
                                                                    }}</h3>
                                                                <p
                                                                    class="text-center text-black product-price text-size">
                                                                    {{
                                                                    sProduct.price }} {{
                                                                    productService?.Currency.name }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p class="plus" [ngStyle]="isLast?{'opacity':'0'}:{'opacity':'1'}">+</p>

                                            </div>
                                        </ng-container>
                                    </div>
                                    <button type="button" class="btn w-100" [disabled]="selectedProductsCount === 0"
                                        (click)="!isloading && addSimilarToCart()">
                                        <h5
                                            class="flex-wrap cursor-pointer d-flex justify-content-between product-title">
                                            <span class="product_title">
                                                {{'addproduct'|translate}} {{selectedProductsCount +"
                                                "}}{{'to cart'|translate}} </span>
                                            <span class="product_title">|</span>
                                            <div>
                                                <span class="product_title">{{ totalPrice }} {{
                                                    productService?.Currency.name }}</span>
                                                <span *ngIf="isloading" class="cutom-loader"></span>
                                            </div>
                                        </h5>
                                    </button>
                                </div>
                                <div class="similar-container position-relative" *ngIf="similar_product?.length>=3">
                                    <h2 class="product-title" [style.color]="calculateTextColor('--theme-default')">
                                        {{'Products that are frequently purchased together'|translate}}
                                    </h2>
                                    <div class="similar-product">

                                        <swiper [config]="similarProductsSliderConfig">
                                            <ng-template class="d-flex align-items-center related-products-slide1"
                                                *ngFor="let sProduct of similar_product; let s = index; last as isLast"
                                                swiperSlide>
                                                <div class="product-box">
                                                    <div class="product-box-inner">
                                                        <div class="img-wrapper position-relative">
                                                            <input type="checkbox" class="form-check product-check"
                                                                [checked]="sProduct.checked"
                                                                (change)="toggleProductChecked(s)">
                                                            <div class="text-center" [class.cursor-pointer]="s != 0"
                                                                [routerLink]="s != 0 ?  ['/shop/product/', sProduct.id , sProduct.meta_url ? sProduct.meta_url?.split(' ').join('-').replace('+' , '') : removeBrackets(sProduct.product_name)?.split(' ').join('-').replace('+' , '') ] :null">
                                                                <div class="front">
                                                                    <a>
                                                                        <div class="text-center image-frame">
                                                                            <img [src]="(sProduct.images.length > 0 ? urlimage + sProduct.images[0].original_name : 'assets/images/product/placeholder.jpg')"
                                                                                [lazyLoad]="urlimage + sProduct.original_name"
                                                                                class="img-fluid lazy-loading"
                                                                                (error)="sProduct.images.length = 0; $event.target.src = 'assets/images/product/placeholder.jpg';"
                                                                                alt="Product Image" />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <h3 class="text-center text-black text-size">
                                                                    {{
                                                                    sProduct.product_name
                                                                    }}</h3>
                                                                <p
                                                                    class="text-center text-black product-price text-size">
                                                                    {{
                                                                    sProduct.price }} {{
                                                                    productService?.Currency.name }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p class="plus" [ngStyle]="isLast?{'opacity':'0'}:{'opacity':'1'}">+</p>

                                            </ng-template>
                                        </swiper>
                                        <button type="button" class="btn w-100" [disabled]="selectedProductsCount === 0"
                                            (click)="!isloading && addSimilarToCart()">
                                            <h5
                                                class="flex-wrap cursor-pointer d-flex justify-content-between product-title">
                                                <span class="product_title">
                                                    {{'addproduct'|translate}} {{selectedProductsCount +"
                                                    "}}{{'to cart'|translate}} </span>
                                                <span class="product_title">|</span>
                                                <div>
                                                    <span class="product_title">{{ totalPrice }} {{
                                                        productService?.Currency.name }}</span>
                                                    <span *ngIf="isloading" class="cutom-loader"></span>
                                                </div>
                                            </h5>
                                        </button>
                                    </div>
                                </div>
                                <hr>
                                <div class="add-to-cart-main"
                                    *ngIf="product?.api_id_to > 0 || product?.seller_name !='' || settings?.front_name !=''">
                                    <div class="price-quantity-block product-price-mobile">
                                        <div class="sell-item text-start" *ngIf="product?.seller_name !=''">
                                            <h3 class="price-detail seller-item-content d-flex align-items-center seller-title-style"
                                                [class.seller-title-no-style]="product?.api_id_to <= 0">
                                                <div class="logo-container">
                                                    <img width="20" height="20"
                                                        [src]="Common._ImageUrl +'front_images/'+settings['front_fav_icon']"
                                                        alt="" srcset="">
                                                </div>
                                                <!-- <img width="40" height="40"
                                                    style="border: 2px solid #DDD; border-radius: 50%; padding:4px;"
                                                    [src]="Common._ImageUrl +'front_images/'+settings['front_fav_icon']"
                                                    alt="" srcset=""> -->
                                                {{'seller' | translate}}:
                                                <span class="company company-name">{{ ' '+product?.seller_name}}</span>
                                            </h3>
                                        </div>
                                        <div class="my-1 sell-item text-start" *ngIf="settings?.front_name !=''">
                                            <h3
                                                class="pb-2 price-detail seller-item-content d-inline-block seller-title-style">
                                                <img width="25" height="25"
                                                    style="margin-inline-start: 3px;margin-inline-end:2px;"
                                                    src="assets/images/warehouse.webp" alt="warehouse">
                                                {{'deliver from' | translate}}: <span
                                                    class="company company-name">{{settings?.front_name}}</span>
                                            </h3>
                                        </div>
                                        <div class="product-price text-start" *ngIf="product?.api_id_to > 0">
                                            <h3 class="text-black price-detail d-inline-block company-title-style"
                                                *ngIf="(product?.price > 0 && product?.store_amounts > 0)">
                                                <img width="20px" height="20"
                                                    style="border: 2px solid #DDD; border-radius: 50%;"
                                                    [src]="importApiImage + product?.details_store_external?.image"
                                                    alt="" srcset="">
                                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                    *ngIf="product?.details_store_external?.image == ''"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                                    <path stroke="currentColor" stroke-linecap="round"
                                                        stroke-linejoin="round" stroke-width="2"
                                                        d="M10 12.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z" />
                                                    <path stroke="currentColor" stroke-linecap="round"
                                                        stroke-linejoin="round" stroke-width="2"
                                                        d="M17 3h-2l-.447-.894A2 2 0 0 0 12.764 1H7.236a2 2 0 0 0-1.789 1.106L5 3H3a2 2 0 0 0-2 2v11a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V5a2 2 0 0 0-2-2Z" />
                                                </svg>
                                                {{ product?.details_store_external?.name }}
                                            </h3>
                                        </div>

                                        <div class="product-quantity"
                                            *ngIf="((product?.store_amounts !== 0 && product?.price !== 0) || (product?.store_amounts !== 0 && product?.price!> 0) )&& product?.api_id_to > 0 ">
                                            <h5 class="text-danger" style="font-size: 10px;
                                                                                    font-weight: bold;
                                                                                    margin-right: 10px;
                                                                                    width: fit-content;">
                                                {{ ("This product ships from " | translate ) +
                                                product?.details_store_external?.name}} </h5>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                            <div class="my-3 tabs-layout-descriptions-mobile">
                                <div class="tabs-block">
                                    <div class="tab-single"
                                        *ngIf="settings?.full_description_web == '1' && product?.description"
                                        [ngClass]="tabId == 'general' ? 'tab-single-active' : ''"
                                        (click)="tabChange('general')">
                                        <h2 class="">
                                            {{"General-Description" | translate}}
                                        </h2>

                                    </div>
                                    <div class="tab-single" *ngIf="productFormShapesDetails.length > 0"
                                        [ngClass]="tabId == 'description' ? 'tab-single-active' : ''"
                                        (click)="tabChange('description')">
                                        <h2 class="">
                                            {{"Specifications" | translate}}
                                        </h2>
                                    </div>
                                    <div class="tab-single"
                                        *ngIf="settings?.promotional_description_web == '1' && product['promotional_description']"
                                        [ngClass]="tabId == 'promotional' ? 'tab-single-active' : ''"
                                        (click)="tabChange('promotional')">
                                        <h2 class="">
                                            {{"promotional description" | translate}}
                                        </h2>
                                    </div>
                                </div>

                                <div class="tab-general-content" *ngIf="tabId === 'general' && settings?.full_description_web == '1' &&
                                    product?.description" [style.text-align]="Common.isRtl ? 'right' : 'left'">

                                    <div class=" productDescription" *ngIf="product?.description"
                                        [innerHTML]="product?.description | sanitizeHtml: 'html'">
                                    </div>

                                    <!-- <div class="label-section">
                                                <span class="badge badge-grey-color" *ngIf="(product?.store_amounts> 0 &&
                                                product?.price > 0 )">In Stock</span>
                                                <span class="badge badge-grey-color" *ngIf="!(product?.store_amounts> 0 &&
                                                product?.price > 0 )">Out of
                                                    Stock</span>
                                            </div> -->
                                </div>


                                <div class="tab-description-content pe-2"
                                    *ngIf="tabId === 'description' && productFormShapesDetails.length != 0">

                                    <div class="no-content" *ngIf="productFormShapesDetails.length == 0">
                                        <img src="assets/images/empty-search1.png" class="img-fluid">
                                        <p>
                                            {{"No-Specifications" | translate}}
                                        </p>
                                    </div>

                                    <div class="description-content-block">
                                        <div *ngFor="let form of productFormShapesDetails; let index=index;"
                                            class="discription-content-single"
                                            [ngClass]="{'red-background-single': 0 === index % 2}">
                                            <div class="discription-title" style="font-weight: bold">
                                                <span class="">
                                                    {{form.form_name | translate}}
                                                    <i class="fa fa-exclamation-circle pointer-event "
                                                        *ngIf="!form.form_shape_id" (click)="getNoteDetails(3)"></i>
                                                </span>
                                            </div>
                                            <div class="discription-title"
                                                *ngIf="form.type != 'm_select' && form.type != 'text' &&form.type">
                                                <span class="">
                                                    <a class="form-shape"
                                                        [routerLink]="['/pages/formshapes/' + form.form_value]">{{form.form_value}}</a>
                                                </span>
                                            </div>
                                            <div class="discription-title" *ngIf="form.type == 'text'">
                                                <span class="">
                                                    <a>{{form.form_value}}</a>
                                                </span>
                                            </div>
                                            <div class="discription-title" *ngIf="!form.type">
                                                <span class="">
                                                    <a>{{form.form_value}}</a>
                                                </span>
                                            </div>
                                            <div class="flex-row flex-wrap discription-title"
                                                *ngIf="form.type == 'm_select'">
                                                <ng-container
                                                    *ngFor="let select of multi_select[index]; let s=index; last as isLast">
                                                    <a class="form-shape"
                                                        [routerLink]="['/pages/formshapes/' + select]">{{select}}</a>
                                                    <span class="mx-2 d-inline-block" *ngIf="!isLast"> - </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div class="tab-general-content" *ngIf="tabId === 'promotional' && settings?.promotional_description_web == '1' &&
                                    product['promotional_description']"
                                    [style.text-align]="Common.isRtl ? 'right' : 'left'">

                                    <div class="" *ngIf="product['promotional_description']"
                                        [innerHTML]="product['promotional_description'] | sanitizeHtml: 'html'">
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </div>
        <section class="mt-3 section-b-space related-products">
            <div class="container">
                <div class="row">
                    <div class="col-12 product-related">
                        <h2 class="font-title font-weight-bold">{{'RELATED PRODUCTS' | translate}}</h2>
                    </div>
                </div>

                <div class="row search-product">
                    <div class="col">
                        <!-- <owl-carousel-o class="product-m no-arrow" [options]="ProductSliderConfig">
                            <ng-container *ngFor="let product of relatedproducts | slice:0:4">
                                <ng-template carouselSlide>
                                    <app-product-box-one [product]="product" [currency]="productService?.Currency"
                                        [thumbnail]="false" [cartModal]="true">
                                    </app-product-box-one>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o> -->

                        <app-related-products-slider [productsList]="relatedproducts | slice:0:9">
                        </app-related-products-slider>


                        <!-- <swiper [config]="relatedProductsSliderConfig">
                            <ng-template class="related-products-slide" 
                                *ngFor="let product of relatedproducts | slice:0:4" swiperSlide>
                                <app-product-box-one [product]="product" [currency]="productService?.Currency"
                                    [thumbnail]="false" [cartModal]="true">
                                </app-product-box-one>
                            </ng-template>
                        </swiper> -->
                    </div>
                </div>


            </div>
        </section>
    </section>
    <app-size-modal #sizeChart [product]="product"></app-size-modal>
    <!-- Section ends -->

    <!-- FLOATING ADD TO CART BOTTOM BLOCK MOBILE SCREEN -->
    <div class="add-to-cart-mobile-block">
        <div class="share-via-whatsapp" *ngIf="customer_service?.product_page?.length > 0 ">
            <button (click)="shareViaWhatapp(customer_service?.product_page[0])" class="btn btn-animation">

                <img [lazyLoad]="labelWhatAppUrl" alt="label-icon" width="30" height="30"
                    [defaultImage]="'assets/images/whatsapp.png'">
                <span>
                    {{customer_service?.product_page[0]?.label}}
                </span>
            </button>
        </div>
        <div class="add-to-cart">
            <div class="qty-box">
                <div class="input-group">
                    <span class="input-group-prepend">
                        <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                            <i class="ti-minus"></i>
                        </button>
                    </span>
                    <input type="text" name="quantity" class="form-control input-number" [value]="counter" disabled />
                    <span class="input-group-prepend">
                        <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                            <i class="ti-plus"></i>
                        </button>
                    </span>
                </div>
            </div>

            <button class="add-to-cart-button" *ngIf="product?.active != 2"
                [ngClass]="(product?.store_amounts == 0 && product?.price == 0) ||(product?.store_amounts == 0 && product?.price> 0) ? 'product-buttons-out-of-stock' : 'product-buttons'">
                <a href="javascript:void(0)" *ngIf="(product?.store_amounts > 0 && product?.price > 0 )"
                    class="btn btn-animation" [class.disabled]="counter > product?.stock" (click)="addToCart(product)">
                    <!-- <i class="fa fa-shopping-cart me-1"
                        aria-hidden="true"></i> -->
                    {{'add-to-cart' | translate}}
                </a>
                <a *ngIf="(product?.store_amounts == 0 && product?.price == 0) || (product?.store_amounts == 0 && product?.price > 0) "
                    href="javascript:void(0)" class="btn btn-animation" [class.disabled]="counter > product?.stock">
                    {{'out of stock' | translate}}
                </a>
                <a *ngIf="(product?.store_amounts> 0 && product?.price == 0) " href="javascript:void(0)"
                    class="btn1 btn-animation" [class.disabled]="counter > product?.stock">
                    {{'Soon' | translate}}
                </a>
            </button>

            <button class="notify-me-button border-test" (click)="openNotifyMeModal(notifyModal, product?.id)"
                *ngIf="(product?.store_amounts == 0 && product?.price == 0) ||(product?.store_amounts == 0 && product?.price> 0) && product?.active != 2 ">
                <!-- <i class="fa fa-bell" aria-hidden="true"></i> -->
                {{'Notify me' | translate}}
            </button>

            <button class="buy-now-button"
                *ngIf="(product?.store_amounts !== 0 && product?.price > 0) && product?.active != 2">
                <a href="javascript:void(0)" class="btn btn-solid" [class.disabled]="counter> product?.stock"
                    (click)="buyNow(product)">
                    {{'buy now' | translate}}
                </a>
            </button>
            <button class="notify-me-button Inventory-progress-button" *ngIf="product?.active == 2"
                [attr.disabeld]="true">
                <a class="btn btn-animation" (click)="getNoteDetails(2)">
                    <i class="fa fa-bell" aria-hidden="true"></i>
                    {{'Inventory is in progress' | translate}}
                </a>
            </button>
        </div>

    </div>

    <div *ngIf="schema['name']">
        <ngx-json-ld [json]="schema"></ngx-json-ld>
    </div>
    <ngx-json-ld *ngIf="breadCrumbSchema && breadCrumbSchema['itemListElement'].length >0" [json]="breadCrumbSchema"></ngx-json-ld>


    <ng-template #notifyModal let-modal>
        <app-notify-me-modal [productIdToListenToAvailability]="product?.id"></app-notify-me-modal>
    </ng-template>


    <ng-template class="theme-modal" #privacylet let-modal>
        <div class="text-center loader-block" *ngIf="loaderSupport">
            <img src="assets/images/loader.gif">
        </div>
        <div class="privacy-modal" *ngIf="!loaderSupport">
            <div class=" d-flex justify-content-between align-items-center">
                <h6>
                    {{ originalName ? '': supportDetails?.title}}
                </h6>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="d-flex justify-content-center align-items-center">
                <h3 [class.original-header]="originalName">
                    {{ originalName}}
                </h3>
            </div>
            <div class="modal-content"
                [innerHTML]="!supportDetails?.description ? '':supportDetails?.description | sanitizeHtml:'html'">
            </div>
        </div>
    </ng-template>


    <ng-template class="theme-modal images-modal" #imagesModal let-modal>
        <div class="p-2 d-flex justify-content-between align-items-center">
            <button type="button" class="close" aria-label="Close"
                (click)="modal.dismiss('Cross click');imageZoomed = false;hideModalSlider()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <section>

            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-sm-12 col-xs-12">
                        <div class="container-fluid">
                            <div class="row align-items-center silder-row-images">
                                <div class="col-lg-2">
                                    <div class="d-flex justify-content-around silder-images">
                                        <div *ngFor="let image of productimages; index as i" class="p-2 silder-element"
                                            (click)="changeImage(i, image)">
                                            <img *ngIf="image.type === 'image'" class="img-fluid h-100"
                                                (error)="handleImageError($event.target)" [src]="urlimage+image.name"
                                                style="cursor:pointer;"
                                                [ngStyle]="{'border': i == selectedSildeIndex ? '2px solid var(--theme-default)' : 'none'}"
                                                alt="{{ product?.name }}" class="rounded img-fluid" [title]="">
                                            <video controls [autoplay]="image.isActive"
                                                *ngIf="image.type === 'video' && image.isActive" class="img-fluid">
                                                <source [src]="mainUrlimage+image.name">
                                            </video>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-10">
                                    <div class="modal-silder">
                                        <div class="p_slider">
                                            <!-- Main Swiper -->
                                            <swiper (slideChange)="onSlideChange()" [navigation]="true"
                                                class="mainProductSlider2" *ngIf="product?.images?.length > 0"
                                                #mainProductSwiper2>
                                                <ng-template class="h-100" *ngFor="let image of
                                            productimages; index as i" swiperSlide let-data>
                                                    <div *ngIf="image" class="h-100">
                                                        <img *ngIf="image.type === 'image'"
                                                            class="img-fluid h-100 zoomable-image"
                                                            (error)="handleImageError($event.target)"
                                                            (mousemove)="showDetailsImage(i, $event)"
                                                            (wheel)="onMouseScroll($event)"
                                                            [src]="mainUrlimage+image.name"
                                                            (click)="zoomImage($event , i)"
                                                            (mouseenter)="changeCursor('zoom-in')"
                                                            (mouseleave)="changeCursor('default')"
                                                            alt="{{ product?.name }}" class="img-fluid" [title]="">

                                                        <video controls [autoplay]="data.isActive"
                                                            *ngIf="image.type === 'video' && data.isActive"
                                                            class="img-fluid">
                                                            <source [src]="mainUrlimage+image.name">
                                                        </video>
                                                    </div>
                                                </ng-template>
                                            </swiper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </ng-template>

    <!-- Notes Prodcut Status -->
    <ng-template class="theme-modal" let-modal #templateModalDate>

        <!-- <div class="top-0 left-0 layer-box w-100 h-100 bg-danger" style="position: fixed  ;z-index: 99999 ; bottom: 100%;"></div> -->
        <div class="d-flex justify-content-between align-items-center note_title"
            style="border-bottom: 1px solid #EEE; background: #fff; margin-inline: 10px;">
            <div id="note_title" style="font-size: 20px;" *ngIf="note_id === 1">قريبا</div>
            <div id="note_title" style="font-size: 20px;" *ngIf="note_id === 2">ملاحظة جاري الجرد</div>
            <div id="note_title" style="font-size: 20px;" *ngIf="note_id === 3">ملاحظة تاريخ الصلاحية</div>

            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>


        <div id="note_description" class="p-2 text-center">

        </div>
        <!-- Loader -->
        <div class="pt-1 text-center loader" *ngIf="noteLoader">
            <img src="assets/images/loader.gif">
        </div>

    </ng-template>


    <ng-template let-bottomSheetRef="bottomSheetRef" #templateBottomSheet>
        <div class="cities-container">
            <div class="container">
                <div class="row">
                    <div class="pt-2 col-6 text-start">
                        {{'select your city' | translate}}
                    </div>
                    <div class="pt-2 col-6">
                        <button (click)="closeTemplateSheetMenu()" class="bg-transparent border-0 text-dark ">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <input type="text" class="form-control" [placeholder]="'direct search'|translate"
                        (input)="filterCities($event.target.value)">
                    <div class="col-12" *ngFor="let city of CitiesFiltered">
                        <div class="city-block" (click)="onChangeCity(city.id);closeTemplateSheetMenu()"
                            [class.city-active]="city.id == city_id">
                            {{city.pla_name}}
                        </div>
                    </div>
                    <div class="my-3 col-12" *ngIf="CitiesFiltered.length ==0">
                        <p class="text-center h6">{{'noItemsFoundCity'|translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <!-- [ngStyle]="Common.isRtl?{'left':'0','right':'auto'}:{'right':'0','left':'auto'}" -->
    <section #login class="full-sidebar" *ngIf="loginclicked">
        <div #loginFast class="shadow login-sidebar">
            <i class="ti-close login-icon" (click)="close()"></i>
            <app-fast-login (step5Reached)="onStep5Reached()"
                (stepSuccessReached)="onstepSuccessReached()"></app-fast-login>
        </div>
    </section>
</ng-container>

<ng-template #productLoad>
    <div class="text-center loader-block" style="margin: 100px 0;">
        <img src="assets/images/loader.gif">
    </div>
</ng-template>

<!-- shipping -->
<ng-template class="theme-modal" #templateModalShipping let-modal>
    <div class="overflow-visible modal-content position-relative">
        <div class="close-modal-btn">
            <div class="p-1 m-auto cursor-pointer modal-bg modal-close-payment d-block"
                (click)="modal.dismiss('Cross click');">
                <button type="button" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="modal-header">
            <div class="text-center w-100" *ngIf="product.product_group_data&&product.product_group_data.img">
                <img [src]="Common._ImageUrl + 'groups/' + product.product_group_data.img" alt="Shipping image"
                    width="auto" height="20" (error)="handleImageError($event)">
            </div>
        </div>
        <div class="modal-body modal7" style="background-image: unset;">
            <div class="p-0 container-fluid">
                <div id="shipping_description" class="p-2 text-center shipping_description">

                </div>
            </div>
        </div>
    </div>
</ng-template>